import React from 'react';
import {
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent
} from '@mui/material';
import { DialogModalTitle } from '../card/DialogModalTitle';
import { TableEntryPoints } from '../table/TableEntryPoints';

export const EntryPointModal = (props) => {
  const data = props.data;

  const closeModal = () => {
    props.setShowModal(false);
  }
  
  return (
    <Dialog open={true}>
    <DialogModalTitle title="Entry Points"/>
    <DialogContent>
      <TableEntryPoints rows={data}/>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeModal}>Close</Button>
    </DialogActions>
  </Dialog>
  )
}
