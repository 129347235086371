import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import { getAllNetworkCarrier } from '../../service/networkCarrier';
import StoreContext from '../../context';
import { entrySizeTypes, freeCapacity, isOver7mFromAnotherEntryPoint } from '../../models/enumTypeLists';
import { addNewEntryPoint, getEntryPoint, updateEntryPoint } from '../../service/asset';
import { NearbyNetworkSelectEntryPoint } from '../surveyForm.js/assets/NearbyNetworkSelectEntryPoint';
import { useSnackbar } from 'notistack';
import { RiserFloor } from './riser/RiserFloor';

export const AddEntryPoint = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const locationId = props.locationId;
  const surveyId = props.surveyId;
  const riserId = props.riserId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [carriers, setCarriers] = useState([]);
  const initState = {
    "entrySize": "",
    "freeCapacity": "",
    "is_over_7_meters_from_another_entry": "",
    "carrier": "",
    "carrier_id": "",
    "survey": surveyId,
    "location": locationId,
    "riser_floor": "",
    "riser": riserId
  };
  const [attributes, setAttributes] = useState(initState);
  const entryPointId = props.entryPointId;
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    props.closeDialog(false);
  };
  const handleSave = () => {
    if (entryPointId){
      editEntryPoint();
      props.closeDialog(false);
    }
    else{
      if((attributes.entrySize) && (attributes.is_over_7_meters_from_another_entry) && (attributes.entrySize)){
        addEntryPoint();
        props.closeDialog(false);
      }
      else {
        enqueueSnackbar(
          "Please fill in the required fields.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    }
  }
  const loadingCarriers = async () => {
    const response = await getAllNetworkCarrier(tokenValue);
    setCarriers(response.data)
  }

  const loadingEntryPoint = async () => {
    getEntryPoint(tokenValue, entryPointId)
    .then((response) => {
      if(response.data){
        const responseData = response.data.attributes;        
        setAttributes(attributes =>({...attributes, carrier_id: response.data.relationships.carrierId.data.id}));
        setAttributes(attributes =>({...attributes, carrier:  response.data.relationships.carrierId.data.id}));
        setAttributes(attributes =>({...attributes, entrySize: responseData.entrySize}));
        setAttributes(attributes =>({...attributes, freeCapacity: responseData.freeCapacity}));
        setAttributes(attributes =>({...attributes, riser_floor: responseData.riserFloor}));
        setAttributes(attributes =>({...attributes, is_over_7_meters_from_another_entry: responseData.isOver7MetersFromAnotherEntry}));
      }
    })
  }

  useEffect(() => {
    entryPointId &&
      loadingEntryPoint()
    loadingCarriers();
  }, [])

  const setCarrier = (value) => {
    setAttributes(attributes =>({...attributes, carrier_id: value}));
  }
  const setEntrySize = (value) => {
    setAttributes(attributes =>({...attributes, entrySize: value}));
  }
  const setFreeCapacity = (value) => {
    setAttributes(attributes =>({...attributes, freeCapacity: value}));
  }
  const setIsOver7mFromAnotherEntryPoint = (value) => {
    setAttributes(attributes =>({...attributes, is_over_7_meters_from_another_entry: value}));
  }
  const addEntryPoint = async() => {
    const body = {
      "data": {
        "type": "entryPoint",
        "id": null,
        "attributes": attributes
      }
    }
    addNewEntryPoint(tokenValue, body)
    .then((response) => {
      if(response.data){
        props.loadingTable();
        enqueueSnackbar(
          "Successfully added new EntryPoint.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingTable();
      }
      if(!response.data){
        enqueueSnackbar(
          response?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }
  const editEntryPoint = async() => {
    const body = {
      "data": {
        "type": "entryPoint",
        "id": entryPointId,
        "attributes": attributes
      }
    }
    updateEntryPoint(tokenValue, entryPointId, body)
    .then((response) => {
      if(response.data){
        props.loadingTable()
        enqueueSnackbar(
          "Successfully edited EntryPoint.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingTable();
      }
      if(!response.data){
        enqueueSnackbar(
          response?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }
  
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent> 
        {riserId && 
          <RiserFloor 
            attributes={attributes}
            setAttributes={setAttributes}
          />
        }
        <NearbyNetworkSelectEntryPoint
          dataList={carriers}
          defaultValue={attributes.carrier}
          setValue={setCarrier}
        />
        <DropDownBoxWithLabel 
          boxTitle={"Entry Size"} 
          dataList={entrySizeTypes}
          defaultValue={attributes.entrySize} 
          required={true}
          setValue={setEntrySize}/>
        <DropDownBoxWithLabel 
          boxTitle={"Free Capacity"} 
          dataList={freeCapacity}
          defaultValue={attributes.freeCapacity} 
          setValue={setFreeCapacity}
          required={true}/>
        <DropDownBoxWithLabel 
          boxTitle={"Is ove 7m from another entry point"} 
          dataList={isOver7mFromAnotherEntryPoint} 
          defaultValue={attributes.is_over_7_meters_from_another_entry}
          setValue={setIsOver7mFromAnotherEntryPoint}
          required={true}/>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
