import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import theme from '../../../theme';
import DoneIcon from '@mui/icons-material/Done';

export const SurveyList = (props) => {
  const data = props.surveyList;
  const [selected, setSelected] = useState(-1);

  // style
  const styleHeaderCell ={
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    color: theme.palette.title.main
  }

  const handleRowClick = (row, index) => {
    props.setSelectedSurvey(row);
    setSelected(index);
  }

  return (
    <TableContainer>
      <Table sx={{ minWidth: 450, marginTop: "20px" }}>
        <TableHead>
          <TableRow >
            <TableCell sx={styleHeaderCell}>Survey date</TableCell>
            <TableCell sx={styleHeaderCell}>Surveyed by</TableCell>
            <TableCell sx={styleHeaderCell}>Status</TableCell>
            <TableCell/>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              hover
              key={row.id}
              sx={{ 
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor:(selected === index && {backgroundColor: theme.palette.selectedRow.main})
              }}
              onClick={() => handleRowClick(row, index)}
            >
              <TableCell>{row.attributes.surveyedAt}</TableCell>
              <TableCell>{row.attributes.user.name}</TableCell>
              <TableCell >{row.attributes.status}</TableCell>
              <TableCell align='right' >
                {selected === index &&
                  <DoneIcon fontSize="small"/> 
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
