import React, {useContext} from 'react';
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import StoreContext from "../../context";
import { getIdDuplicateSurvey } from '../../service/survey';


export const DuplicateSurvey = (props) => {
  const history = useHistory();
  const { authStore } = useContext(StoreContext);
  const { surveyStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const survey = props.survey;
  const surveyId = props.surveyId;

  const handleDuplicateSurveyClick = () => {
    getIdDuplicateSurvey(tokenValue, surveyId)
    .then((response) => {
      if(response.data){
        history.push(`/survey/${response.data.id}`);
        surveyStore.getOne('survey/' + response.data.id)
        surveyStore.reset()
      }
    })
    
  };

  return (
    <MenuItem
      key={"duplicate"}
      disabled = {survey.status === "complete" ? false : true}
      onClick={handleDuplicateSurveyClick}>
      Duplicate
    </MenuItem>
  )
}
