import React, {Fragment} from "react";
import Box from '@mui/material/Box';
import {Section, Documents, checkBool, checkString, Photos} from './GridComponents';


export const Building = ( {data, handleOpenModal} ) => {  
  return(
    <Box sx={{ lineHeight: 1.5 }}>
      
      <Section
        subheading={"Survey Info"}
        keyVals={{
          "Building name": data.property,
          "Survey date": data.surveyedAt,
          "Surveyed by": data.user,
          "Desktop survey complete": checkBool(data.isDesktopSurveyComplete),
        }}
      />

      <Documents
        documents={data.surveybasefileSet}
      />

      <Section
        subheading={"Building details"}
        keyVals={{
          "Exterior survey observations": checkString(data.exteriorSurveyObservations),
          "Floor numbers": checkString(data.exteriorSurveyObservations),
          "Tenanted floors": checkString(data.numberOfTenantedFloors),
          "Has lifts": checkString(data.hasLifts),
          "Floors served by lifts": checkString(data.floorsServedByLifts),
        }}
      />

      <Photos
        photos={data.exteriorphotoSet || [] }
        handleOpenModal={handleOpenModal}
      />
            
    </Box>
  )
}
