import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Autocomplete  from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const AutocompleteTags = ({
  tagsList,
  setTagsList,
  selectedTagsList,
  setSelectedTagsList,
  defaultTagsList,
  createNewPhotoTag,
  setLoading,
}) => {
  
  const [value, setValue] = React.useState([]);
  const [error, setError] = React.useState(null);

  useEffect(() => {
    if (defaultTagsList) {
      // initialise value to the tags that should already be on this photo
      setValue(defaultTagsList)
      // initialise selectedTagsList to the ids of the tags that should already be on this photo
      setSelectedTagsList(defaultTagsList.map(item => item.id))
    }
  }, [])

  const handleSelectTags = (event, newValue) => {
    // newValue is an array that contains a mixture of  "tag" objects if the user has clicked an option, or strings if the user entered custom data
    // I think preventDefault stops strings getting automatically pushed to the end of the options array
    event.preventDefault();
    setLoading(true);
    // check if the user clicked on an option or entered a string
    if (typeof newValue[newValue.length -1] === 'string') {
      createNewPhotoTag(newValue[newValue.length -1]).then((response) => {
        if (response.errors) {
          setLoading(false);
          setError(response.errors[0]?.detail);
        } else {
          setError(null)
          if (response.data) {
            // update the tagsList and value arrays by using the spread operator
            setTagsList([...tagsList, response.data])
            setValue([...value, response.data])
            setSelectedTagsList([...selectedTagsList, response.data.id])
            setLoading(false)
          }
        }
      })
    } else {
      setError(null)
      // We assume that if the most recent entry wasn't a string, then all the entries in newValue are objects from the options list and we can safely override value with newValue.
      setValue(newValue);
      setSelectedTagsList(newValue.map(item => item.id))
      setLoading(false)
    }
  }
  
  return (
    <Stack sx={{ width: 269, marginTop: "40px" }}>
        <Typography variant="caption" display="block" gutterBottom style={{color: 'red'}}>
          {error && <>Error: {error}</>}
        </Typography>
        <Autocomplete
        value={value}
        multiple
        id="tags-filled"
        options={tagsList}
        onChange={(event, newValue) => handleSelectTags(event, newValue)}
        freeSolo
        getOptionLabel={(option) => option.attributes.tag}
        defaultValue={defaultTagsList}
        renderInput={(params) => (
          <TextField
            label={"Tags"}
            {...params}
          />
        )}
      />
    </Stack>
  )
}
