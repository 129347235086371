import React, { useState } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';
import { AssetsModal } from '../propertyCardModal/AssetsModal';

export const Assets = (props) => {
  const [showModal, setShowModal] = useState(false)
  const locationSet = props.property.lastSurvey.locationSet;
  
  function sumAssetsByLocation(total, location) {
    if (location.cabinet_set) {
      if (location.cabinet_set.rack_mounted_asset_set) {
        total += location.cabinet_set.rack_mounted_asset_set.length;
      }
    }
    if (location.unmounted_asset_set) {
      total += location.unmounted_asset_set.length;
    }
    return total;
  }

  const totalAssets = locationSet.reduce(sumAssetsByLocation, 0)
    

  const cardInfo =
  {
    id: "assets",
    imageSource: "/Assets.png",
    summaryNumber: totalAssets,
    title: "Assets",
    subtitle: ""
  }
  
  // Press the button to show form
  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <Grid item>
      {( totalAssets === 0) ?
        <></> :
        showModal && <AssetsModal setShowModal={setShowModal} locationSet={locationSet}/>
      }
      <Card sx={props.shadowStyle} onClick={handleClick}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
  )
}
