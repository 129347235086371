import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextFieldBox, NewTextFieldBox } from '../surveyForm.js/TextFieldBox';
import { DropDownBoxWithLabel, NewDropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import { elevationChoice } from '../../models/enumTypeLists';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { CommsRoomFields } from './commsRoomField/CommsRoomFields';
import { addNewLocation, getLocation, getLocationsNameId, updateLocation } from '../../service/asset';
import StoreContext from '../../context';
import { useSnackbar } from 'notistack';


export const AddNewLocation = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const locationId = props.locationId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const camelToSnakeCase = str => str.replace(/[A-Z0-9]+/g, letter => `_${letter.toLowerCase()}`);
  
  // initialise boolean values to empty strings, otherwise if they are not sent to the DB the db assumes false instead of null for some reason.
  const [attributes, setAttributes] = useState({
    cr_has_space_for_meetme: "",
    cr_would_cabinet_fit_from_entrance: "",
    cr_has_appropriate_power: "",
    cr_has_hvac: "",
    cr_is_ready_for_install: "",
    cr_has_diverse_routes_to_risers: "",
  });
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
    
  const updateAttributes = (array) => {
    let tempAttributes = {"survey": surveyId}
    for (const [key, value] of Object.entries(array)) {
        if (!Array.isArray(value)) {
          tempAttributes[camelToSnakeCase(key)] = value ?? "";
        }
    }
    setAttributes(tempAttributes);
  }
  
  // called when the add new location modal is opened. First it checks if the data is already fetched from the db when the location list loaded
  // if it exists, it updates the state with that information.
  // then it gets the modified date of the location, if it is different to the one already loaded, it fetches the latest one and updates the state.
  const getLocationData = () => {
    const currentLocation = props.locationList.find(location => location.id == locationId)
    if (currentLocation.attributes) {
      updateAttributes(currentLocation.attributes)
    }
    getLocationsNameId(tokenValue, locationId + "/").then( (response) => {
      if (response.data.attributes.modifiedAt != currentLocation.attributes.modifiedAt) {
        getLocation(tokenValue, locationId)
        .then((response) => {
          updateAttributes(response.data.attributes)
        })
      }
    })
  }
  
  useEffect(() => {
    locationId &&
      getLocationData();
  }, [])
  

  const handleClose = () => {
    props.closeDialog(false);
    props.setLocationId(null);
  };

  const handleSave = () => {
    if (error) {
      enqueueSnackbar(
        "Please enter valid data.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
    else {
      if (locationId) {
        editLocation();
      }
      else {
        addLocation();
      }
    }
  }

  const handleCommsRooms = (value) => {
    setAttributes(attributes =>({...attributes, cr_is_comms_room: value}));
  }

  const addLocation = async() => {
    const formData = new FormData();
    formData.append("survey", surveyId);
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    addNewLocation(tokenValue, formData)
    .then((response) => {
      if(response.ok){
        props.loadingLocations()
        props.loadingRisers()
        props.closeDialog(false);
      }
      if(!response.ok){
        enqueueSnackbar(
          response.json()?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  const editLocation = async() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    updateLocation(tokenValue, locationId, formData)
    .then((response) => {
      if(response.ok){
        enqueueSnackbar(
          "Successfully edited Location.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingLocations();
        props.closeDialog(false);
      }
      if(!response.ok){
        enqueueSnackbar(
          response.json()?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title"
    >
      <DialogTitle 
        id="upload-dialog-title" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <NewTextFieldBox 
          boxTitle={"Floor"}
          setAttributes={setAttributes}
          attributes={attributes}
          attributeKey="floor"
          formData={true}
        />
        <NewTextFieldBox 
          boxTitle={"Room"}
          setAttributes={setAttributes}
          attributes={attributes}
          attributeKey="room"
          formData={true}
        />
        <NewDropDownBoxWithLabel 
          boxTitle={"Elevation"} 
          dataList={elevationChoice} 
          setAttributes={setAttributes}
          attributes={attributes}
          attributeKey="elevation"
          formData={true}
        />
        <NewTextFieldBox 
          boxTitle={"Description"}
          setAttributes={setAttributes}
          attributes={attributes}
          attributeKey="description"
          formData={true}
        />
        <FormGroup>
          <FormControlLabel control={
            <Checkbox checked={
              attributes.cr_is_comms_room ?? false} onChange={(e) => setAttributes(attributes =>({...attributes, cr_is_comms_room: e.target.checked}))
            }/>
          } label="Is a comms room" 
          />
        </FormGroup>
        {attributes.cr_is_comms_room && 
          <CommsRoomFields
            setAttributes={setAttributes}
            attributes={attributes} 
            error={error}
            setError={setError}/>
        }
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
