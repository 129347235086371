import React, { Fragment, useState } from 'react';
import { 
  Collapse, 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons, cellStyleLocationTableDropdownButton, rowBackground } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AssetTable } from './AssetTable';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddCabinet } from '../../../dialogs/AddCabinet';

export const RowCabinets = (props) => {
    const [open, setOpen] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const row = props.row;
    const locationId = props.locationId;
    const cabinetId = props.cabinetId;
    const riserId = props.riserId;

    return (
      <Fragment>
        <TableRow>
          <TableCell sx={cellStyleLocationTableDropdownButton}>
            <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
            </IconButton>
            </TableCell>
            <TableCell sx={cellStyleLocationTableBorder}>{row.cab_description}</TableCell>
            <TableCell sx={cellStyleLocationTableBorder}>{row.owner}</TableCell>
            <TableCell sx={cellStyleLocationTableBorder}>{row.cab_height}</TableCell>
            <TableCell sx={cellStyleLocationTableBorder}>{row.cab_width}</TableCell>
            <TableCell sx={cellStyleLocationTableButtons}>
              <IconButton onClick={() => setOpenEditDialog(true)} >
                <EditIcon></EditIcon>
              </IconButton>
              <IconButton onClick={() => setOpenDeleteDialog(true)} >
                <CancelIcon></CancelIcon>
              </IconButton>
            </TableCell>
        </TableRow>
        <TableRow sx={rowBackground}>
          {/* Show Subtable */}
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <AssetTable 
                assetList={row.rack_mounted_asset_set}
                deleteAsset={props.deleteAsset}
                locationId={locationId}
                cabinetId={cabinetId}
                loadingTable={props.loadingTable}
                />
            </Collapse>
          </TableCell>
        </TableRow>
        {openDeleteDialog && 
          <ConformDialog 
            setOpenDialog={setOpenDeleteDialog} 
            title={"Cabinet"} 
            deleteFunction={() => props.deleteCabinet(row.id)}/>
        }
        {openEditDialog && 
          <AddCabinet 
            dialogTitle={"Edit Cabinet"}
            locationId={locationId}
            riserId={riserId}
            cabinetId={cabinetId}
            closeDialog={setOpenEditDialog}
            loadingTable={props.loadingTable}
          />
        }
      </Fragment>
    )
}
