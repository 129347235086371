import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Box } from '@mui/system';
import { Button } from '@mui/material';

export const NavLinks = (props) => {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
    {props.navLinks.map((navLink) => (
        <Button
        key={navLink.path}
        component={RouterLink}
        to={navLink.path}
        sx={{ my: 2, color: "white", display: "block" }}
        >
        {navLink.text}
        </Button>
    ))}
    </Box>
  )
}
