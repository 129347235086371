import config from "../config";


export const fetchPropertyByName = async (nameForSearch, tokenValue) => {
  const requestUrl = `${config.apiBaseUrl}/api/propertyList/?filter%5Bsearch%5D=` + nameForSearch;
  try {
  const response = await fetch (requestUrl, {
    method: 'GET',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
    return response.json()
  }
  catch (error) {
    return []
  }
}

export const fetchUPSListByPropertyId = async (propertyId, tokenValue) => {
  const requestUrl = `${config.apiBaseUrl}/api/ups/?filter%5Bsearch%5D=` + propertyId;
  try {
  const response = await fetch (requestUrl, {
    method: 'GET',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
    return response.json()
  }
  catch (error) {
    return []
  }
}


export const fetchPropertyList = async (pageString, tokenValue)=> {
  const requestUrl = `${config.apiBaseUrl}/api/propertyList/?page%5Bnumber%5D=` + pageString;
  try {
  const response = await fetch (requestUrl, {
    method: 'GET',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
    return response.json()
  }
  catch (error) {
    return []
  }
}
