import React from 'react';
import {  useHistory } from "react-router-dom";
import { 
  IconButton, 
  TableBody
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { OpenInNew } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import { EnhancedEmptyRow } from './EnhancedEmptyRow';

export const EnhancedTable = (props) => {
  const history = useHistory();
  const rows = props.rows;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;

  const handleRowClick = (param, event) => {
    history.push(`/property/${param.id}`);
  };

  // style
  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.primary.main,
      fontSize: "20px",
    },  
  }));

  return (
      <TableBody sx={{ width: '100%' }} >
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row)=>{
          return(
            <TableRow hover={true} key={row.meta.id} onClick={() => handleRowClick(row.meta)}>
              <StyledTableCell >{row.buildingName}</StyledTableCell>
              <StyledTableCell align="right" width={40}> 
                <IconButton onClick={() => handleRowClick(row.meta)}>
                  <OpenInNew/>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          )
        })}
        <EnhancedEmptyRow page={page} rowsPerPage={rowsPerPage} rows={rows}/>
      </TableBody>
  )
}
