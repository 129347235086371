import React from 'react';
import { 
  Box, 
  Card, 
} from '@mui/material';
import { CaptionWithDivider } from '../CaptionWithDivider';
import { cardStyleNoBorderNoShadow } from '../../../style';
import { NumberFieldBox } from '../NumberFieldBox';
import { TextFieldBox } from '../TextFieldBox';

export const Floors = ( { attributes, setAttributes, setEnableSaveButton, error, setError, submitError } ) => {

  const setNumberOfTenantedFloors = (value) => {
      setAttributes(attributes =>({...attributes, numberOfTenantedFloors: value}));
      setEnableSaveButton(true);
  }
    
  const setfloorNumbers = (value) => {
      setAttributes(attributes =>({...attributes, floorNumbers: value}));
      setEnableSaveButton(true);
  }
  
  return (
    <Card style={cardStyleNoBorderNoShadow}>
      <CaptionWithDivider caption={"Floors"} />
      <Box sx={{marginLeft: "100px"}}>
        <TextFieldBox
          id="outlined-select-floor-numbers"
          boxTitle={"Floor Numbers"}
          helperText="e.g. B, G, 1-4"
          setValue={setfloorNumbers}
          defaultValue={attributes.floorNumbers}
          />
        <TextFieldBox 
          boxTitle={"Tenanted floors"} 
          setValue={setNumberOfTenantedFloors}
          defaultValue={attributes.numberOfTenantedFloors}
          error={error}
          setError={setError}
          submitError={submitError}
          />
      </Box>
    </Card>
  )
}
