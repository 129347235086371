import React from 'react';
import {
  TableCell, 
} from '@material-ui/core';
import theme from '../../theme';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export const SubtableDropdownEntryPoint = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  // style
  const styleTable={
    backgroundColor: "rgba(33, 33, 33, 0.08)",
    fontSize: "16px",
    color: theme.palette.title.main
  }
  const styleMainCell ={
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.black.main,
    letterSpacing: "0.5px",
    '& > *': { borderBottom: 'unset' }
  }
  const styleCell ={
    backgroundColor: "rgba(33, 33, 33, 0.08)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.black.main,
  }

  return (
    <React.Fragment>
      <TableRow sx={styleMainCell} >
        <TableCell component="th" scope="row">{row.entry_size}</TableCell>
        <TableCell>{row.free_capacity}</TableCell>
        <TableCell>{row.carrier}</TableCell>
        {(row.is_over_7_meters_from_another_entry === "Yes") ?
        <TableCell><CheckIcon/></TableCell>
        :<TableCell><CloseIcon/></TableCell>
        }
        
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={styleCell}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
            { row.location ? 
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={styleTable}>
                    <TableCell>Floor</TableCell>
                    <TableCell>Room</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Elevation</TableCell>
                    <TableCell>Is Comms Room</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.location.id} sx={styleCell}>
                    <TableCell>{row.location.floor}</TableCell>
                    <TableCell>{row.location.room}</TableCell>
                    <TableCell>{row.location.description}</TableCell>
                    <TableCell>{row.location.elevation}</TableCell>
                    {(row.location.is_comms_room) ? 
                    <TableCell >Yes</TableCell>
                    : <TableCell>No</TableCell>
                    }
                  </TableRow>
                </TableBody>
              </Table>
            : 
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={styleTable}>
                    <TableCell>Riser</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Floor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={row.riser.id} sx={styleCell}>
                    <TableCell>{row.riser.name}</TableCell>
                    <TableCell>{row.riser.description}</TableCell>
                    <TableCell>{row.riser_floor}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
