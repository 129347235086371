import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent,
  TablePagination,  
  Table, 
  TableFooter,
  TableRow 
} from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DialogModalTitle } from '../card/DialogModalTitle';
import theme from '../../theme';

export const PhotoDialog = (props) => {
  const title = props.title;
  const photoSet = props.photoSet 
  const [page, setPage] = useState(0);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeModal = () => {
    props.setShowState(false);
  }

  // style
  const photoStyle ={ 
    objectFit: "contain",
  }

  return (
    <Dialog open={true} >
      <DialogModalTitle title={title} /> 
      <DialogContent>
      {(photoSet.length === 0) ? 
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h4" component="div" align='center'>
              No photo
            </Typography>
          </CardContent>
          </Card>
         :
        <Card sx={{ width: 345, high: 400 }}>
        <CardMedia 
          sx = {photoStyle}
          component="img"
          height="300"
          image={photoSet[page].photo}
          alt={photoSet[page].description}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div" align='center'>
            {photoSet[page].description}
          </Typography>
        </CardContent>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx ={{
                  color: theme.palette.secondary.main
                }}
                rowsPerPageOptions={[]}
                count={photoSet.length}
                rowsPerPage={1}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Card>
      }
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
