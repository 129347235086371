import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Grid,
} from "@mui/material";
import StoreContext from "../context";

import Map from "../components/Map";
import { GridItem } from "../components/card/GridItem";
import { WiredScoreRatingsCard } from "../components/dashboard/WiredScoreRatingsCard";
import CountDashboardData from "../models/countDashboardData";
import { createPropertiesListForMap } from "../service/createListProperty";


export default observer(() => {
  const { dataStore } = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [allProperties, setAllProperties] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isLoadingDataForMaps, setIsLoadingDataForMaps] = useState(true);

  const tokenValue = authStore.apiToken;

  const loadingProperties = async () => {
    const response = await createPropertiesListForMap(tokenValue);
    setAllProperties(response);
    setIsLoadingDataForMaps(false)
  }

  useEffect(() => {
      dataStore.getOne(CountDashboardData).then(() => {
        setIsLoadingData(false)
      })
      loadingProperties()
  }, [dataStore]);

  const row = dataStore.findAll(CountDashboardData).toJSON()[0];
  

  const gridItems = [
    {
      id: "building-surveyed",
      imageSource: "/Buildings surveyed.png",
      summaryNumber: (isLoadingData ? "" : row.count_data.building_surveyed),
      title: "Buildings Surveyed",
      subtitle: ""
    },
    {
      id: "zetta-fibre-backbone",
      imageSource: "/Fibre backbone.png",
      summaryNumber: (isLoadingData ? "" : row.count_data.fibre_tube_backbone),
      title: "Zetta Fibre Backbone",
      subtitle: ""
    },
    {
      id: "diverse-entry",
      imageSource: "/Diverse Entry.png",
      summaryNumber: (isLoadingData ? "" : row.count_data.diverse_entry),
      title: "Diverse Entry",
      subtitle: ""
    },
  ]


  return (
    <>
      <Box sx={{m:2}} >
        <Grid container spacing={4}>
          <GridItem isLoadingData={isLoadingData} gridItems={gridItems} ></GridItem>
          <WiredScoreRatingsCard isLoadingData={isLoadingData} dashboardData={row} />
        </Grid>
      </Box>
        <Map properties={allProperties} isLoadingDataForMaps={isLoadingDataForMaps} />   
    </>
  );
});
