import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';

import { headStyleTable, headCellPhotoTable } from '../../../../style';

export const Header = () => {
  return (
    <TableHead sx={headStyleTable}>
        <TableRow>
          <TableCell sx={headCellPhotoTable} align="left">Photo</TableCell>
          <TableCell sx={headCellPhotoTable} align="left">File</TableCell>
          <TableCell sx={headCellPhotoTable} align="left">Tags</TableCell>
          <TableCell/>
        </TableRow>
    </TableHead>
  )
}
