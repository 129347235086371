import React, { useState } from 'react';
import { 
  Button, 
  CardContent, 
  Typography 
} from '@mui/material';
import { dedicatedSpaceTypesList, doorLockTypesList, yseNoNADataList, fireProtectionTypes } from '../../../models/enumTypeLists';
import { NewDropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel'
import { NewTextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { NewNumberFieldBox } from '../../surveyForm.js/NumberFieldBox';
import { NewBoolRadioBox } from '../../surveyForm.js/BoolRadioBox';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

export const CommsRoomFields = ( {attributes, setAttributes, error, setError, } ) => {
  const [boardPhoto, setBoardPhoto] = useState();
  const [diversePowerPhoto, setDiversePowerPhoto] = useState();

  return (
    <>
      <NewDropDownBoxWithLabel 
        boxTitle={"Dedicated space"} 
        dataList={dedicatedSpaceTypesList}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_dedicated_space"
        formData={true}
      />
      <NewDropDownBoxWithLabel 
        boxTitle={"Door lock type"} 
        dataList={doorLockTypesList}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_door_lock_type"
        formData={true}
      />
      <NewBoolRadioBox
        boxTitle={"Has space for meetme cabinet"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_has_space_for_meetme"
        setError={setError}
        formData={true}
      />
      <NewTextFieldBox
        boxTitle={"Max Cabinet Size (eg 600x600x1080)"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_max_cabinet_size"
        setError={setError}
        formData={true}
      />
      <NewBoolRadioBox
        boxTitle={"Would a cabinet fit from the entrance"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_would_cabinet_fit_from_entrance"
        setError={setError}
        formData={true}
      />
      <NewBoolRadioBox
        boxTitle={"Has appropriate power"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_has_appropriate_power"
        setError={setError}
        formData={true}
      />
      <NewDropDownBoxWithLabel 
        boxTitle={"Has temporary/240v power"} 
        dataList={yseNoNADataList}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_has_temporary_power"
      />
      <NewNumberFieldBox
        boxTitle={"16A Commandos required"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_16a_commandos_required"
        error={error}
        setError={setError}
        formData={true}
      />
      <NewTextFieldBox 
        boxTitle={"Flood Protection Details"} 
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_flood_protection_details"
        formData={true}
      />
      <NewDropDownBoxWithLabel 
        boxTitle={"Fire Protection"} 
        dataList={fireProtectionTypes} 
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_fire_protection"
      />
      <NewBoolRadioBox
        boxTitle={"Has HVAC"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_has_hvac"
        setError={setError}
        formData={true}
      />
      <NewBoolRadioBox
        boxTitle={"Is ready for install"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_is_ready_for_install"
        setError={setError}
        formData={true}
      />
      <NewBoolRadioBox
        boxTitle={"Has diverse routes to risers"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_has_diverse_routes_to_risers"
        setError={setError}
        formData={true}
      />
      <NewTextFieldBox 
        boxTitle={"Additional notes"}
        setAttributes={setAttributes}
        attributes={attributes}
        attributeKey="cr_other_notes"
        formData={true}
      /> 
    </>
  )
}
