import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { onClickAddLocationTable } from '../../../style';
import { HeadConnectionTable } from './HeadConnectionTable';
import { ConnectionRowWithSubtable } from './ConnectionRowWithSubtable';
import { AddFibreTubeBundle } from '../../dialogs/AddFibreTubeBundle';

export const ConnectionTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const fibreTubeBundleList = props.fibreTubeBundleList;
  const surveyId = props.surveyId;
  
  return (
    <Box>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add new Fibre Tube Bundle
          </Typography>
        </caption>
        <HeadConnectionTable />
        <TableBody>
          {fibreTubeBundleList.map((fibreTubeBundle) => {
            return fibreTubeBundle.is_deleted === true ?
            <ConnectionRowWithSubtable
              key={fibreTubeBundle.id} />
            :
            <ConnectionRowWithSubtable
              key={fibreTubeBundle.id}
              row={fibreTubeBundle.attributes} 
              deleteFibreTubeBundle={props.deleteFibreTubeBundle}
              deleteFibreTube={props.deleteFibreTube}
              deleteFibreTubeCore={props.deleteFibreTubeCore}
              fibreTubeBundleId={fibreTubeBundle.id}
              loadingFibreTubeBundle={props.loadingFibreTubeBundle}
              surveyId={surveyId}
            />
          } 
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddFibreTubeBundle 
        dialogTitle={"Add New Fibre Tube Bundle"}
        closeDialog={setOpenDialog}
        surveyId={surveyId}
        loadingFibreTubeBundle={props.loadingFibreTubeBundle}/>
    }
    </Box>
  )
}
