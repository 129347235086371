import React from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    Typography 
} from '@mui/material';
import { boxCardStyle } from '../../../../style';

export const Risers = () => {
    
  return (
    <Box  sx={{ marginTop:"20px"}}>
      <Card align="left" style={boxCardStyle}>
        <CardContent>
          <Typography variant="h5">Risers</Typography>
          <Typography variant="body1">Add a riser, then fill in the assets for that riser.</Typography>
        </CardContent>
      </Card>
    </Box>
  )
  
}
