import React from 'react';
import { 
  TableBody,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { EnhancedEmptyRow } from '../table/EnhancedEmptyRow';

export const EnhancedTableClient = (props) => {
  const rows = props.rows;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;

  // style, define new element
  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.primary.main,
      fontSize: "20px",
    },  
  }));

  return (
    <TableBody sx={{ width: '100%' }} >
      {(rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
      ).map((row)=>{
        return(
          <TableRow hover={true} key={row.meta.id}>
            <StyledTableCell >{row.property}</StyledTableCell>
            <StyledTableCell >{row.user}</StyledTableCell>
            <StyledTableCell >{row.surveyedAt}</StyledTableCell>
            <StyledTableCell width={90}></StyledTableCell>
            <StyledTableCell width={40}></StyledTableCell>
          </TableRow>
        )
      })}
      <EnhancedEmptyRow page={page} rowsPerPage={rowsPerPage} rows={rows}/>
    </TableBody>
  )
}
