import React from 'react';
import { 
  Typography 
} from '@mui/material';

export const BuildingInformation = (props) => {
  const buildingName = props.buildingName;
  const buildingAddress = props.buildingAddress;

  return (
    <>
        <Typography variant="h4" align="center">{buildingName}</Typography>
        <Typography align="center" sx={{ marginBottom: 2 }}>{buildingAddress}</Typography>
    </>
  )
}
