import React, { useState } from 'react';
import { 
  Button,
  Dialog, 
  DialogActions, 
  DialogContent,
  Grid,
  Table,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { DialogModalTitle } from '../card/DialogModalTitle';
import { TableLocations } from '../table/TableLocations';
import theme from '../../theme';

export const LocationConnectedModal = (props) => {
  const fibreTubeBundleSet = props.fibreTubeBundleSet;
  const [page, setPage] = useState(0);
  const rowsPerPage  =(1);

  const closeModal = () => {
    props.setShowModal(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Dialog open={true}>
      <DialogModalTitle title="Fibre Backbone"/>
      
      <DialogContent sx={{ minWidth: 580 }}>
      {fibreTubeBundleSet[page].is_deleted === false ?
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{marginBottom: 2}}>Start Location</Typography>
              <Typography>{fibreTubeBundleSet[page].cabinet?.description}</Typography>
              <Typography>{fibreTubeBundleSet[page].cabinet?.make} {fibreTubeBundleSet[page].cabinet.model}</Typography>
              <Typography>{fibreTubeBundleSet[page].cabinet?.height} {fibreTubeBundleSet[page].cabinet.width}</Typography>
              <Typography>{fibreTubeBundleSet[page].cabinet?.mount}</Typography>
              <Typography>{fibreTubeBundleSet[page].cabinet.location?.name} {fibreTubeBundleSet[page].cabinet.location?.description} {fibreTubeBundleSet[page].cabinet.location?.cr_is_comms_room ? '(comms room)' : '' }</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" sx={{marginBottom: 2}}>End Location</Typography>
              <Typography>{fibreTubeBundleSet[page].location?.floor}, {fibreTubeBundleSet[page].location?.room}, {fibreTubeBundleSet[page].location.elevation}</Typography>
            </Grid>
          </Grid>
          <TableLocations rows={fibreTubeBundleSet[page].fibre_tube_set}/>
        </>
        :
          <>
            <Grid container  sx={{ minWidth: 450, height: "561px"}}>
              <Typography variant="h6" sx={{marginBottom: 2}}>This Fibre Fibre Tube Bundle has status deleted.</Typography>    
            </Grid>
          </>
        }
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                sx ={{
                  color: theme.palette.primary.main
                }}
                rowsPerPageOptions={[]}
                count={fibreTubeBundleSet.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
