import React, { useState, useContext, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';
import StoreContext from "../../../context";
import { getAllNetworkCarrier } from '../../../service/networkCarrier';

export const NetworkCarrierSelect = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const defaultValue = props.defaultValue;
  const label = props.label;
  const [selectState, setSelectState] = useState();  
  const [dataList, setDataList] = useState([]);  

  const loadingNetworkSwitch = () => {
    getAllNetworkCarrier(tokenValue)
    .then((response) =>{
      if(response.data){
        setDataList(response.data)
      }
    })
  }
  
  useEffect(() => {
    loadingNetworkSwitch()
  }, [])
  

  const handleChange = (event) => {
    props.setValue(event.target.value);
    setSelectState(event.target.value);
  }

  return (
    <CardContent>
      <TextField
        id="outlined-select-nearby-network"
        select
        label={label}
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || defaultValue}
        sx={{minWidth:400}}
        SelectProps={{
            MenuProps: {
              sx: { maxHeight: '50%', marginLeft: "20px"}
            }
          }}
      >
        {dataList?.map((d) => (
          <MenuItem
            key={d.id}
            value={d.id}
          >
            {d.attributes.name}
          </MenuItem>
        ))}
      </TextField>
    </CardContent>
  )
}
