import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleTable, primarySubtableHeader } from '../../../../style';


export const HeadTable = () => {
      
  return (
    <TableHead sx={primarySubtableHeader}>
        <TableRow>
          <TableCell/>
          <TableCell align="left" sx={headCellStyleTable}>Tube</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>Start Asset</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>End Asset</TableCell>
          <TableCell sx={headCellStyleTable}/>
        </TableRow>
    </TableHead>
  )
}
