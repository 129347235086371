import React from 'react';
import { Typography } from '@mui/material';

export const SummaryNumber = (props) => {
  const summaryNumber = props.summaryNumber;

  return (
    <Typography 
      variant="h3" 
      component="div"
      align="center"
      >
      {summaryNumber}
    </Typography>
  )
}
