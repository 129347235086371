import React from 'react';
import { 
  CircularProgress,
  TableBody,
  TableFooter, 
  TablePagination, 
  TableRow 
} from '@mui/material';
import theme from '../../theme';
import TableCell from '@mui/material/TableCell';
import { EnhancedTableHead } from '../table/EnhancedTableHead';
import { EnhancedTableClient } from './EnhancedTableClient';

export const ClientTable = (props) => {
  const surveyList = props.surveyList;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const isLoadingData = props.isLoadingData;

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  // head data if client is login
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Building Name",
    },
    {
      id: "surveyor",
      numeric: false,
      disablePadding: true,
      label: "Surveyor",
    },
    {
      id: "updated",
      numeric: false,
      disablePadding: true,
      label: "Updated"
    },
    {
        id: "button1",
        numeric: false,
        disablePadding: true,
        label: ""
    },
    {
    id: "button2",
    numeric: false,
    disablePadding: true,
    label: ""
    },
  ];

  return (
    <>
    <EnhancedTableHead headCells={headCells}></EnhancedTableHead>
    {isLoadingData ?
      <TableBody><TableRow><TableCell/><TableCell align="center"><CircularProgress/></TableCell></TableRow></TableBody> :
      <>
        <EnhancedTableClient rows={surveyList} page={page} rowsPerPage={rowsPerPage}></EnhancedTableClient>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx ={{
                color: theme.palette.primary.main
              }}
              rowsPerPageOptions={[ 10]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </>
    }
    </>
  )
}
