import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import theme from '../../theme';
export const WiredScore = (props) => {
  const wiredScoreRating = props.property.lastSurvey.mostRecentValidWiredscoreRating;
  const cardInfo =
  {
    id: (wiredScoreRating.id)!= null ? wiredScoreRating.id : "0",
    imageSource: (wiredScoreRating.wiredscoreRating)!= null ? ("/WS_" + wiredScoreRating.wiredscoreRating +".png") : "",
    title: (wiredScoreRating.id)!= null ? ("Wired Score " + wiredScoreRating.wiredscoreRating) : ("No Wired Score"),
    subtitle: (wiredScoreRating.id)!= null ? ("Valid until " + wiredScoreRating.validUntil) : "",
  }

  // style
  const styleImage = {
    height: "95px",
    width: "95px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: "60px"
  }
  const styleTitle = {
    fontSize: "30px",
    lineHeight: "44px",
    color: theme.palette.primary.main,
  }

  return (
    <Grid item>
      <Card sx={props.shadowStyle}>
        <CardContent>
          {(wiredScoreRating.id)!= null ?
            <img src={cardInfo.imageSource} style={styleImage} alt={cardInfo.imageSource + "-photo"}></img>
          : <></>}
          <Typography sx={styleTitle} align="center">{cardInfo.title}</Typography>
          <Typography variant="subtitle2" align="center">{cardInfo.subtitle}</Typography>
        </CardContent>  
      </Card>
    </Grid>
  )
}
