import React from 'react';
import { Typography } from '@mui/material';

export const CardSubtitle = (props) => {
  const subtitle = props.subtitle

  return (
    <Typography 
      variant="subtitle2"
      display="block"
      align="center">
      {subtitle}
    </Typography>
  )
}
