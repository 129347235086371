import React, { useEffect, useState } from "react";
import GoogleMapReact, { fitBounds } from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  IconButton,
  Popover,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Stack,
  LinearProgress,
} from "@mui/material";
import config from "../config";

function PropertyDetails({ property }) {
  
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <ListItem alignItems="flex-start">
        <ListItemText primary={property.buildingName} />
      </ListItem>
      {property.buildingManager?.name ? (
        <ListItem alignItems="flex-start">
          <ListItemText
            primary={property.buildingManager.name}
            secondary={
              <>
                <Typography mr={2}>
                  {property.buildingManager.jobTitle}
                </Typography>
                <Typography mr={2}>
                  {property.buildingManager.phoneNumber}
                </Typography>
                <Typography mr={2}>
                  {property.buildingManager.emailAddress}
                </Typography>
              </>
            }
          />
        </ListItem>
      ) : (
        ""
      )}
    </List>
  );
}

function MapPin({ property }) {
  const K_WIDTH = 40;
  const K_HEIGHT = 40;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "primary",
        width: K_WIDTH,
        height: K_HEIGHT,
        left: -K_WIDTH / 2,
        top: -K_HEIGHT,
      }}
    >
      <IconButton onClick={handleClick}>
        <LocationOnIcon color="primary" fontSize="large" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <PropertyDetails property={property} />
      </Popover>
    </Box>
  );
}

function getBounds(data) {
  return {
    nw: {
      lat: Math.max(...data.map((p) => {if (p.address) {return p.address.latitude}}).filter(Number)),
      lng: Math.min(...data.map((p) => {if (p.address) {return p.address.longitude}}).filter(Number)),
    },
    se: {
      lat: Math.min(...data.map((p) => {if (p.address) {return p.address.latitude}}).filter(Number)),
      lng: Math.max(...data.map((p) => {if (p.address) {return p.address.longitude}}).filter(Number)),
    },
  };
}

export default function Map({ properties, center, zoom, isLoadingDataForMaps }) {
  const [averagePosition, setAveragePosition] = useState({
    center: {
      lat: 51.507222,
      lng: -0.1275,
    },
    zoom: 11,
  });

  const size = {
    width: 1120, // Map width in pixels
    height: 400, // Map height in pixels
  };

  useEffect(() => {
    if (properties[0]) {
      const bounds = getBounds(properties)
      setAveragePosition(fitBounds(bounds, size));
    }
  }, [properties]);

  return (
    // Important! Always set the container height explicitly
    <Box sx={{ m: 2, width: 1, height: 400 }}>
      { isLoadingDataForMaps ? 
      <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        <LinearProgress color="secondary" />
      </Stack> : 
      <></> 
      }
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.googleMapsApiKey }}
        defaultCenter={center}
        defaultZoom={zoom}
        center={averagePosition.center}
        zoom={averagePosition.zoom}
      > 
      
        {properties.map((property) => { 
          
          if (property.address) {
            if (property.address.latitude && property.address.longitude) {
              return (
              <MapPin
                key={property.buildingName}
                lat={property.address.latitude}
                lng={property.address.longitude}
                property={property}
              />
              )
            }
          }
        })}
      </GoogleMapReact>
    </Box>
  );
}

Map.defaultProps = {
  center: {
    lat: 51.507222,
    lng: -0.1275,
  },
  zoom: 11,
};
