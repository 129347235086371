import React from "react";
import {
  AppBar,
  Container,
  Toolbar,
} from "@mui/material";
import { Logo } from "./navbar/Logo";
import { NavLinks } from "./navbar/NavLinks";
import { EnhancedMenu } from "./navbar/EnhancedMenu";

const baseNavLinks = [
  // {
  //   id: 1,
  //   text: "Dashboard",
  //   path: "/",
  // },
  {
    id: 2,
    text: "My Properties",
    path: "/properties",
  },
  {
     id: 3,
     text: "Surveys",
     path: "/surveys",
   },
  // {
  //   id: 4,
  //   text: "Cross Connects",
  //   path: "/crossconnects",
  // },
];

const adminNavLinks = [
  {
    id: 5,
    text: "Users",
    path: "/users",
  },
];

export default function Navbar() {
  
  
  const getNavLinks = () => {
    if (localStorage.role === "staff") {
      return baseNavLinks.concat(adminNavLinks)
    }
    return baseNavLinks
  }

  const navLinks = getNavLinks()

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Logo/>
          <NavLinks navLinks={navLinks}/>
          <EnhancedMenu />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
