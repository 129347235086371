import React, {Fragment, useState} from 'react';
import { 
  IconButton,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Card, CardContent, Typography } from '@mui/material'
import { cardStyleBuildingDocTable, cardSxBuildingDocTable } from '../../../style';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ConformDialog from '../../dialogs/ConformDelete';
import theme from '../../../theme';
import { getFileNameFromUrl } from '../../utils';

export const BuildingDocumentsTableCard = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [id, setId] = useState("")
  const documentList = props.documentList;
    
  const handleClickOpen = (documentId) => {
    setOpenDialog(true);
    setId(documentId);
  };

  const notDeletedDocuments = documentList.filter((document) => document.attributes.isDeleted === false).length

  return (
    <Card style={cardStyleBuildingDocTable} sx={cardSxBuildingDocTable}>
      {notDeletedDocuments === 0 ?
        <CardContent>
          <Typography variant="h6" align="center"
            sx={{marginTop:"20px", marginBottom:"20px"}}
            >No documents uploaded</Typography> 
        </CardContent>
        :
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{border: "none"}}>
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <TableCell align="center"><Typography variant='h6'>Type</Typography></TableCell>
                <TableCell align="center"><Typography variant='h6'>File name</Typography></TableCell>
                <TableCell align="center"><Typography variant='h6'>Date uploaded</Typography></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentList.map((document) => (
                document.attributes.isDeleted === false &&
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={document.id}>
                    <TableCell component="th" scope="row">{document.attributes.type}</TableCell>
                    <TableCell align="right">{getFileNameFromUrl(document.attributes.file)}
                    </TableCell>
                    <TableCell align="right">{document.attributes.createdAt}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleClickOpen(document.id)} sx={{marginTop: "10px"}}>
                        <CancelRoundedIcon sx={{color: theme.palette.primary.main}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    <Fragment>
      {openDialog &&  
        <ConformDialog setOpenDialog={setOpenDialog} title="Rating" deleteFunction={() => props.removeDocument(id)}/>
      }
    </Fragment>
      
    </Card>
  )
}
