import React, {Fragment} from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const checkBool = (value) => {
  if (value === true) {
    return <CheckIcon color="primary" />
  }
  if (value === false) {
    return <ClearIcon color="primary" />
  }
  return "Unknown"
}

const checkString = (value) => {
  if (value) {
    return value
  }
  return "-"
}

const SubHeading = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.gray.light,
  padding: theme.spacing(1),
  textAlign: 'left',
  borderRadius: '10px',
  p: '5px',
}));

const Item = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  padding: theme.spacing(1),
}));

const Documents = ( {documents} ) => {
  if (!documents.length) {
    return("");
  }
  return(
    <Grid container spacing={2} sx={{ marginBottom: '16px' }}>
      <Grid item xs={12}><SubHeading>Documents</SubHeading></Grid>
      {
        documents.map((document, index, array) =>
        <Fragment key={document.id}>
          <Grid item xs={4}>
            <Item>{document.type}</Item>
          </Grid>
            <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{overflow: 'scroll', whiteSpace: 'nowrap'}}>{document.filename}</Box>
              <Link href={document.file} sx={{ px: 2 }}><DownloadIcon color="primary" fontSize="small" /></Link>
            </Grid>
          {index + 1 !== array.length && <Grid item xs={12}><Divider /></Grid>}
        </Fragment>
        )
      }
    </Grid>
  )
}

const Section = ( {subheading, keyVals} ) => {
  return(
    <Grid container spacing={2} sx={{ marginBottom: '16px' }} >
      <Grid item xs={12}><SubHeading>{subheading}</SubHeading></Grid>
      {Object.entries(keyVals).map((entry, index, array) =>
        <Fragment key={entry[0] + index}>
          <Grid item xs={4}>
            <Item>{entry[0]}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{entry[1]}</Item>
          </Grid>
          {index + 1 !== array.length && <Grid item xs={12}><Divider /></Grid>}
        </Fragment>
      )}
    </Grid>
  )
}

const Photos = ( {photos, handleOpenModal} ) => {
  if (!photos.length) {
    return("");
  }
  return(
    <Grid container spacing={2} sx={{ marginBottom: '16px' }} >
      <Grid item xs={12}><SubHeading>Photos</SubHeading></Grid>
      {
        photos.map((photo, index, array) => 
          <Fragment key={photo.id}>
            <Grid item xs={4}>
              <img src={(photo.thumb)} onClick={()=>handleOpenModal(photo.photo)}></img>
            </Grid>
            <Grid item xs={8}>
              <Item>
                {photo.tags.length ? photo.tags.map( (tag, index, array) => 
                  <Fragment key={tag.id}>{tag.tag}{index + 1 !== array.length && ", "}</Fragment>
                ) : "[No tags]"}
              </Item>
            </Grid>
          {index + 1 !== array.length && <Grid item xs={12}><Divider /></Grid>}
          </Fragment>
        )
      }
    </Grid>
  )
}

export {Section, Documents, checkBool, checkString, Photos};
