import React, {Fragment} from 'react';
import {
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography
} from '@mui/material';
import { cellStyleLocationSubtable, locationSubtableTableTitle, primarySubtableHeader } from '../../../style';
import { RowUnmountedAsset } from './unmountedAsset/RowUnmountedAsset';

export const RowWithUnmountedAssetTable = (props) => {
    const unmountedAssetList = props.unmountedAssetList;
    const locationId = props.locationId;
    const riserId = props.riserId;
    
    return (
      <Box>
        <Typography variant="h6"  style={locationSubtableTableTitle}>Non Mounted Assets</Typography>
        <TableContainer component={Paper}>
          <Table >
            <TableHead sx={primarySubtableHeader}>
              <TableRow sx={{ borderRadius: "10px"}}>
                <TableCell align="left" sx={cellStyleLocationSubtable} >Type</TableCell>
                <TableCell align="left" sx={cellStyleLocationSubtable}>Owner</TableCell>
                <TableCell align="left" sx={cellStyleLocationSubtable}>Status</TableCell>            
                <TableCell />
              </TableRow>
          </TableHead>
          <TableBody>
            {unmountedAssetList.map((asset)=> 
             {
              return asset.is_deleted === true ?
              <Fragment 
                key={asset.id}/>
              :
              <RowUnmountedAsset 
                key={asset.id}
                row={asset}
                deleteUnmountedAsset={props.deleteUnmountedAsset}
                locationId={locationId}
                riserId={riserId}
                unmountedAssetId={asset.id}
                loadingTable={props.loadingTable}
                />
             }
            )}
          </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
  