import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';
import { Exterior } from '../surveyForm.js/siteDetails/Exterior';
import { Floors } from '../surveyForm.js/siteDetails/Floors';
import { SiteDetails } from '../surveyForm.js/siteDetails/SiteDetails';
import { Access } from '../surveyForm.js/siteDetails/Access';
import StoreContext from '../../context';
import { addNewSiteDetails, getSiteDetails } from '../../service/siteDetails';
import { useSnackbar } from 'notistack';
import { ExteriorPhotos } from '../surveyForm.js/siteDetails/ExteriorPhotos';

export const ThirdStep = forwardRef((props, ref) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  // for form
  const initState = {
    "nearby_networks": [],
    "exteriorSurveyObservations": "",
    "floorNumbers": "",
    "numberOfTenantedFloors": null,
    "hasLifts": false,
    "floorsServedByLifts": "",
    "liftsDetails": "",
    "hasLoadingBay": false,
    "loadingBayDetails": ""
  };
  const [attributes, setAttributes] = useState(initState);
  // handle error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [error, setError] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  useImperativeHandle(ref, () => ({
    saveDataFunction() {
      if (error) {
        enqueueSnackbar(
          "Please enter valid data.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
      else {
        addSiteDetails();
      }
    }
  }));

  const loadingSiteDetails = async () => {
    getSiteDetails(tokenValue, surveyId)
    .then((response) => {
      if(response.data) {
        const responseData = response.data.attributes;
        setAttributes(attributes =>({...attributes, nearby_networks: (response.data.relationships?.nearbyNetworks?.data).map((item)=> item.id)}));
        setAttributes(attributes =>({...attributes, exteriorSurveyObservations: responseData?.exteriorSurveyObservations || ""}));
        setAttributes(attributes =>({...attributes, floorNumbers: responseData?.floorNumbers || ""}));
        setAttributes(attributes =>({...attributes, numberOfTenantedFloors: responseData?.numberOfTenantedFloors}));
        setAttributes(attributes =>({...attributes, hasLifts: responseData?.hasLifts}));
        setAttributes(attributes =>({...attributes, floorsServedByLifts: responseData?.floorsServedByLifts || ""}));
        setAttributes(attributes =>({...attributes, liftsDetails: responseData?.liftsDetails || ""}));
        setAttributes(attributes =>({...attributes, hasLoadingBay: responseData?.hasLoadingBay}));
        setAttributes(attributes =>({...attributes, loadingBayDetails: responseData?.loadingBayDetails || ""}));

      }
    })
  }
  
  const addSiteDetails = async () => {
    const body = {
      "data": {
        "type": "survey",
        "id": surveyId,
        "attributes": attributes
      }
    }
    addNewSiteDetails(tokenValue, body, surveyId)
    .then((response) => {
      if(response.data){
        props.setIsSaved(true);
        props.setEnableSaveButton(false);
        closeSnackbar();
        enqueueSnackbar(
          "Successfully Saved.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
      } else if (response.errors) {
        response.errors.forEach((error) => {
          enqueueSnackbar(
            error.detail,
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "center" },
              }
          ); 
        })
      }
    })
  }

  useEffect(() => {
    props.setShowSaveButton(true);
    props.setEnableSaveButton(false);
    props.setIsSaved(true);
    props.setIsSavedThirdStep(false)
    loadingSiteDetails();
  }, [])

  //style
  const cardStyle = {
    border: "none",
    boxShadow: "none", 
    minWidth: 804, 
    maxWidth: 1104,
    background: theme.palette.neutral.main
  }

  return (
    <Box align="center" sx={{ marginTop:"40px", marginLeft: "10px"  }} style={cardStyle}>
      <SiteDetails />
      <Exterior 
        surveyId={surveyId}
        attributes={attributes}
        setAttributes={setAttributes}
        setEnableSaveButton={props.setEnableSaveButton}
        />
      <Floors 
        attributes={attributes}
        setAttributes={setAttributes}
        error={error}
        setError={setError}
        setIsSaved={props.setIsSaved}
        setEnableSaveButton={props.setEnableSaveButton}
      />
      <Access 
        attributes={attributes}
        setAttributes={setAttributes}
        setEnableSaveButton={props.setEnableSaveButton}
        />
      <ExteriorPhotos 
        surveyId={surveyId}/>
    </Box>
  )
})
