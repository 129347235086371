import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  Grid,
  LinearProgress,
  Box,
} from "@mui/material";
import { Redirect } from "react-router-dom";
import Property from "../models/property";
import StoreContext from "../context";
import { HeadInformation } from "../components/property/HeadInformation";
import { CarrierList } from "../components/propertyCard/CarrierList";
import { LocationConnected } from "../components/propertyCard/LocationConnected";
import { CommsRooms } from "../components/propertyCard/CommsRooms";
import { EntryPoints } from "../components/propertyCard/EntryPoints";
import { WiredScore } from "../components/propertyCard/WiredScore";
import { Ups } from "../components/propertyCard/Ups";
import { Risers } from "../components/propertyCard/Risers";
import { Assets } from "../components/propertyCard/Assets";


export default observer(({ match }) => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { id } = match.params;
  const { dataStore } = useContext(StoreContext);
  const property = dataStore.findOne(Property, id);

  useEffect(() => {
    setLoading(true);
    dataStore
      .getOne(Property, id)
      .then(() => {
        setLoading(false);
        setLoaded(true);
      })
      .catch(() => {
        // todo when using mobx-react-router - catch 404 and redirect to properties
        setLoading(false);
        setLoaded(true);
      });
  }, [])
  
  if (loading) {
    return <LinearProgress />;
  }

  // bug
  if (property === null) {
    if (loaded) {
      return <Redirect to="/properties" />;
    }
    return null; // will instantly rerender because loading===true
  }
  
  if (property.lastSurvey === null || property.lastSurvey.status === "deleted") {
    return (
      <Box sx={{m:2}} >
      <Grid container spacing={5}>
        <HeadInformation property={property} />
      </Grid>
    </Box>
    )
  }

  // style
  const shadow = "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)";
  const shadowStyleHover={
    width: 235, 
    height: 235,
    boxShadow: shadow,
    borderRadius: "10px",
    ':hover': {
      boxShadow: 12,
      cursor: "pointer",
    }
  };
  const shadowStyle={
    width: 235, 
    height: 235,
    boxShadow: shadow,
    borderRadius: "10px",
  };

  return (
    <Box sx={{m:2}} >
      <Grid container spacing={5}>
        <HeadInformation property={property} />
        <CarrierList shadowStyle={shadowStyleHover} entrypointCarrier={property.lastSurvey.entrypointSet.map((entrypoint) => entrypoint.carrier)} property={property}/>
        <LocationConnected property={property} shadowStyle={shadowStyleHover} /> {/*  Fibre Backbone Info ----- need update backend */}
        <CommsRooms property={property} shadowStyle={shadowStyleHover} />
        <EntryPoints property={property} shadowStyle={shadowStyleHover} />
        <WiredScore property={property} shadowStyle={shadowStyle} />
        <Ups property={property} shadowStyle={shadowStyle}/>
        <Risers property={property} shadowStyle={shadowStyleHover} />
        <Assets property={property} shadowStyle={shadowStyleHover} />
      </Grid>
    </Box>
  );
});
