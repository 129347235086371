import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleSecondTable, secondarySubtableHeader } from '../../../../style';

export const HeadTable = () => {
       
  return (
  <TableHead >
      <TableRow sx={secondarySubtableHeader}>
        <TableCell align="left" sx={headCellStyleSecondTable}>Type</TableCell>
        <TableCell align="left" sx={headCellStyleSecondTable}>Make</TableCell>
        <TableCell align="left" sx={headCellStyleSecondTable}>Model</TableCell>
        <TableCell align="left" sx={headCellStyleSecondTable}>Owner</TableCell>
        <TableCell align="left" sx={headCellStyleSecondTable}>Status</TableCell>
        <TableCell />
      </TableRow>
  </TableHead>
)
}
