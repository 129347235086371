import React, { useState } from 'react';
import { 
    Box, 
    Button, 
    Table, 
    TableBody, 
    TableCell, 
    TableRow
} from '@mui/material'
import { lightColorButton } from '../../../style';
import { AddEntryPoint } from '../../dialogs/AddEntryPoint';
import { AddCabinet } from '../../dialogs/AddCabinet';
import { AddUnmountedAsset } from '../../dialogs/AddUnmountedAsset';

export const RowWithButtons = (props) => {
  const [entryPointDialog, setEntryPointDialog] = useState(false);
  const [cabinetDialog, setCabinetDialog] = useState(false);
  const [accessPointDialog, setAccessPointDialog] = useState(false);
  const locationId = props.locationId;
  const surveyId = props.surveyId;
  const riserId = props.riserId;
  
  return (
    <Box marginTop={"20px"}>
    <Table size="small" aria-label="purchases">
      <TableBody >
        <TableRow>
          <TableCell align="center">
            <Button variant="contained" size="large"
              sx={lightColorButton}
              onClick={() => setEntryPointDialog(true)} >
              + Entry Point
            </Button>
            <Button variant="contained" size="large" 
              sx={lightColorButton}
              onClick={() => setCabinetDialog(true)}>
              + Cabinet
            </Button> 
            <Button variant="contained" size="large" 
              sx={lightColorButton}
              onClick={() => setAccessPointDialog(true)}>
              + Non mounted asset
            </Button>
          </TableCell>             
        </TableRow>
      </TableBody>
    </Table>
    {entryPointDialog &&
      <AddEntryPoint 
        dialogTitle = {"Add New Entry Point"}
        closeDialog={setEntryPointDialog}
        locationId={locationId}
        loadingTable={props.loadingTable}
        surveyId={surveyId}
        riserId={riserId}
        />
    }
    {cabinetDialog &&
      <AddCabinet 
        dialogTitle={"Add New Cabinet"}
        closeDialog={setCabinetDialog}
        locationId={locationId}
        loadingTable={props.loadingTable}
        riserId={riserId}
        />
    }
    {accessPointDialog &&
      <AddUnmountedAsset 
        dialogTitle={"Add New Non Mounted Asset"}
        closeDialog={setAccessPointDialog}
        locationId={locationId}
        loadingTable={props.loadingTable}
        riserId={riserId}
      />
    }
  </Box>
  )
}
