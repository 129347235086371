import React, { Fragment, useState } from 'react';
import { 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddUnmountedAsset } from '../../../dialogs/AddUnmountedAsset';

export const RowUnmountedAsset = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const row = props.row;
  const unmountedAssetId = props.unmountedAssetId;
  const locationId = props.locationId;
  const riserId = props.riserId;

  return (
    <Fragment>
      <TableRow>
          <TableCell>{row?.asset_type}</TableCell>
          {row?.asset_type === "BFP" ?
          <TableCell sx={cellStyleLocationTableBorder}>{row?.network_carrier}</TableCell> :
          <TableCell sx={cellStyleLocationTableBorder}>{row?.owner}</TableCell>
          }
          <TableCell sx={cellStyleLocationTableBorder}>{row?.status}</TableCell>
          <TableCell sx={cellStyleLocationTableButtons}>
            <IconButton  onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Unmounted Asset"} 
          deleteFunction={() => props.deleteUnmountedAsset(row.id)}
        />
      }
      {openEditDialog &&
        <AddUnmountedAsset 
          dialogTitle={"Edit Non Mounted Asset"}
          unmountedAssetId={unmountedAssetId}
          locationId={locationId}
          riserId={riserId}
          closeDialog={setOpenEditDialog}
          loadingTable={props.loadingTable}
      />
      }
    </Fragment>
  )
}
