import React from 'react';
import theme from '../../theme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SubtableDropdownLocations } from './SubtableDropdownLocations';


export const TableLocations = (props) => {
  const rows = props.rows;

  // style
  const styleHeaderCell ={
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    color: theme.palette.title.main
  }

  return (
    <TableContainer sx={{ minWidth: 450, height: "561px"}} >
      <Table id="entry-points-table">
        <TableHead>
          <TableRow sx={styleHeaderCell}>
            <TableCell>Start</TableCell>
            <TableCell>End</TableCell>
            <TableCell align="right">Cores</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            return row.is_deleted === false ?
            <SubtableDropdownLocations key={row.id} row={row} />
            :
            <TableRow key={row.id}></TableRow>
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
