import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';

export const NearbyNetworkSelectEntryPoint = (props) => {
  const defaultValue = props.defaultValue;
  const dataList = props.dataList;
  const [selectState, setSelectState] = useState();

  const handleChange = async (event) => {
    setSelectState(event.target.value);
    props.setValue(event.target.value);
  }
  

  return (
    <CardContent>
      <TextField
        id="outlined-select-property"
        select
        label="Carrier"
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || defaultValue}
        sx={{minWidth:400}}
        SelectProps={{
            MenuProps: {
              sx: { maxHeight: '50%' }
            },
          }
        }
        
      >
        {dataList?.map((d) => (
          <MenuItem
            key={d.id}
            value={d.id}
          >
            {d?.attributes?.name}
          </MenuItem>
        ))}
      </TextField>
      
    </CardContent>
  )
}
