import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import { unmountedAssetType } from '../../models/enumTypeLists';
import StoreContext from '../../context';
import { useSnackbar } from 'notistack';
import { UPS } from './unmountedAsset/UPS';
import { WirelessAccessPoint } from './unmountedAsset/WirelessAccessPoint';
import { 
  CardContent
} 
from '@mui/material';
import { TerminationEnclosure } from './unmountedAsset/TerminationEnclosure';
import { BFP } from './unmountedAsset/BFP';
import { addNewUnmountedAsset, getAssetTypeByName, getUnmountedAsset, updateUnmountedAsset } from '../../service/asset';

export const AddUnmountedAsset = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedType, setSelectedType] = useState("");
  const locationId = props.locationId;
  const riserId = props.riserId;
  const assetId = props.unmountedAssetId;
  const initAttributes = {
    "make": "",
    "model": "",
    "net_mac_address": "",
    "serial": "",
    "status": "",
    "owner": "",
    "qr_code": "",
    "ap_connected_switch": "",
    "ap_speed_test_speed": "",
    "ap_speed_test_screenshot": "",
    "ups_battery_capacity": "",
    "is_installed_by_zetta": true,
    "network_carrier": "",
    "location": locationId,
    "asset_type_id": selectedType,
    "image_asset_photo": [],
    "riser_floor": "",
    "riser": riserId,
  };
  const [attributes, setAttributes] = useState(initAttributes);
  // for handling error
  const [error, setError] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if (error) {
      enqueueSnackbar(
        "Please enter valid Mac Address.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
    else {
      if (assetId) {
        editUnmountedAsset();
        props.closeDialog(false);
      }
      else {
        if(selectedType){
          addUnmountedAsset();
          props.closeDialog(false);
        }
        else {
          enqueueSnackbar(
            "Please select Asset Type.",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          ); 
        }
      } 
    }
  }

  useEffect(() => {
    if (locationId === undefined){
      setAttributes(attributes =>({...attributes, location: ""}));
    }
    if (riserId === undefined){
      setAttributes(attributes =>({...attributes, riser: ""}));
    }
    assetId &&
      loadingUnmountedAsset()
  }, [])

  const addUnmountedAsset = () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    addNewUnmountedAsset(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
        );
        props.loadingTable();
      }
    if(!response.data){
      enqueueSnackbar(
        response.errors[0].detail,
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  })
  .catch(() => {
    enqueueSnackbar(
      "There was an error contacting the server. Please try again later.",
      {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }
      );
    });
  }

  const loadingAssetType = () => {
    getAssetTypeByName(tokenValue, selectedType)
    .then((response) => {
      setAttributes(attributes =>({...attributes, asset_type_id: response.data[0].id}));
    })
  }

  const loadingUnmountedAsset = () => {
    getUnmountedAsset(tokenValue, assetId)
    .then((response) => {
      const respData = response.data.attributes;      
      setSelectedType(respData.assetType.name)
      setAttributes(attributes =>({...attributes, make: respData?.make}));
      setAttributes(attributes =>({...attributes, model: respData?.model}));
      setAttributes(attributes =>({...attributes, net_mac_address: respData?.netMacAddress || ""}));
      setAttributes(attributes =>({...attributes, serial: respData?.serial || ""}));
      setAttributes(attributes =>({...attributes, status: respData?.status}));
      setAttributes(attributes =>({...attributes, owner: respData?.owner}));
      setAttributes(attributes =>({...attributes, qr_code: respData?.qrCode}));
      setAttributes(attributes =>({...attributes, ap_connected_switch: response.data.relationships.apConnectedSwitch?.data?.id || ""}));
      setAttributes(attributes =>({...attributes, ap_speed_test_speed: respData?.apSpeedTestSpeed || ""}));
      setAttributes(attributes =>({...attributes, ap_speed_test_screenshot: respData?.apSpeedTestScreenshot || ""}));
      setAttributes(attributes =>({...attributes, ups_battery_capacity: respData?.upsBatteryCapacity || ""}));
      setAttributes(attributes =>({...attributes, is_installed_by_zetta: respData?.isInstalledByZetta}));
      setAttributes(attributes =>({...attributes, network_carrier: response.data.relationships.networkCarrier?.data?.id || ""}));
      setAttributes(attributes =>({...attributes, image_asset_photo: respData?.imageAssetPhoto || []}));
      setAttributes(attributes =>({...attributes, riser_floor: respData.riserFloor}));
    })
  }

  const editUnmountedAsset = () => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    formData.append("id", assetId);
    updateUnmountedAsset(tokenValue, assetId,formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
        );
        props.loadingTable();
      }
    if(!response.data){
      enqueueSnackbar(
        response.errors[0].detail,
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  })
  .catch(() => {
    enqueueSnackbar(
      "There was an error contacting the server. Please try again later.",
      {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }
      );
    });
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <CardContent>
        <DropDownBoxWithLabel 
          boxTitle={"Type"} 
          dataList={unmountedAssetType}
          defaultValue={selectedType}
          setValue={setSelectedType} 
          />
      </CardContent>
      { selectedType === "UPS" &&
        <UPS 
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
          riserId={riserId}
        />
      }
      { selectedType === "Wireless Access Point" &&
        <WirelessAccessPoint 
          setAttributes={setAttributes}
          attributes={attributes}
          error={error}
          setError={setError}
          loadingAssetType={loadingAssetType}
          riserId={riserId}
        /> 
      }
      { selectedType === "Termination Enclosure" &&
        <TerminationEnclosure
          setAttributes={setAttributes}
          attributes={attributes}          
          loadingAssetType={loadingAssetType}
          riserId={riserId}
        /> 
      }
      { selectedType === "BFP" &&
        <BFP 
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
          tokenValue={tokenValue}
          riserId={riserId}
        />
      }
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
