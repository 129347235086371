import React from 'react';
import { Box } from '@mui/system';
import { Link } from "react-router-dom";

export const Logo = () => {
  return (
    <Link to ="/">
      <Box
      component="img"
      sx={{
        height: 48,
        marginRight: 4,
      }}
      alt="Zetta Connect"
      src="/ZettaConnect_logo_rgb_300-09.png"
    />
  </Link>
  )
}
