import React, { useState, Fragment } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { onClickAddLocationTable } from '../../../../style';
import { HeadTable } from './HeadTable';
import { AddNewAsset } from '../../../dialogs/AddNewAsset';
import { AssetRow } from './AssetRow';

export const AssetTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const assetList = props.assetList;
  const locationId = props.locationId;
  const cabinetId = props.cabinetId;
  
  return (
    <Box sx={{marginLeft: "50px", marginRight: "-127px", marginBottom: "1px"}}>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add new Asset
          </Typography>
        </caption>
        <HeadTable />
        <TableBody>
          {assetList.map((asset)=>{
            return asset.is_deleted === true ?
              <Fragment  
                key={asset.id}/>
              :
              <AssetRow 
                key={asset.id}
                row={asset}
                deleteAsset={props.deleteAsset}
                locationId={locationId}
                cabinetId={cabinetId}
                loadingTable={props.loadingTable}
                assetId={asset.id}
              />
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddNewAsset 
        dialogTitle={"Add New Rack Mounted Asset"}
        locationId={locationId}
        cabinetId={cabinetId}
        closeDialog={setOpenDialog}
        loadingTable={props.loadingTable}/>
    }
    </Box>
  )
}
  

