import React from 'react';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { LockSurvey } from './LockSurvey';
import { DuplicateSurvey } from './DuplicateSurvey';

export const MenuForSurveyActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const survey = props.survey;
  const surveyId = props.surveyId;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MenuIcon />
      </IconButton >
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch"
          }
        }}>
        <MenuItem
          key={"edit"}
          disabled = {survey.status === "complete" ? true : false}
          onClick={() => props.editSurvey(survey.meta)}>
          Edit
        </MenuItem>
        <LockSurvey 
          survey={survey}
          changeStatusSurvey={props.changeStatusSurvey}
          surveyId={surveyId}
        />
        <MenuItem 
          key={"view"}
          onClick={() => props.viewSurvey(survey.meta)}>
          View Report
        </MenuItem>
        <DuplicateSurvey 
          survey={survey}
          surveyId={surveyId}
        />
        <MenuItem 
          key={"delete"}
          onClick={() => props.deleteSurvey(survey.meta.id)}>
          Delete
        </MenuItem>
      </Menu>
    </>
  )
}
