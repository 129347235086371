import React, {useEffect} from 'react';
import { CardContent, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { ownerType, status } from '../../../models/enumTypeLists';
import { RiserFloor } from '../riser/RiserFloor';

export const TerminationEnclosure = (props) => {
  const attributes = props.attributes;
  const riserId = props.riserId;

  useEffect(() => {
    props.loadingAssetType();
  }, [])

  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setOwner = (value) => {
    props.setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQRCode = (value) => {
    props.setAttributes(attributes =>({...attributes, qr_code: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, is_installed_by_zetta: value}));
  }

  return (
    <>
      <CardContent>
        {riserId && 
          <RiserFloor 
            attributes={attributes}
            setAttributes={props.setAttributes}
          />
        }
        <DropDownBoxWithLabel 
            boxTitle={"Status"} 
            dataList={status}
            defaultValue={attributes.status}
            setValue={setStatus} />
        <DropDownBoxWithLabel 
            boxTitle={"Owner"} 
            dataList={ownerType}
            defaultValue={attributes.owner}
            setValue={setOwner} />
        <TextFieldBox 
          boxTitle={"QR Code"}
          setValue={setQRCode}
          defaultValue={attributes.qr_code} />
        <CardContent>
          <FormGroup>
            <FormControlLabel 
              control={
              <Checkbox 
                checked={attributes.is_installed_by_zetta}
                onChange={(e) => setInstalledByZetta(e.target.checked)}
              />
              } 
              label="Installed by Zetta as part of these works" 
            />
          </FormGroup>
        </CardContent>
      </CardContent>
    </>
  )
}
  