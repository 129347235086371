import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleTable, headStyleTable } from '../../../style';

export const HeadConnectionTable = () => {
    
  return (
    <TableHead sx={headStyleTable}>
        <TableRow>
          <TableCell/>
          <TableCell align="left" sx={headCellStyleTable}>Start Cabinet</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>End Location</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>Description</TableCell>
          <TableCell sx={headCellStyleTable}/>
        </TableRow>
    </TableHead>
  )
}
