import React from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    Typography 
} from '@mui/material';
import { cardStyleSiteDetails } from '../../../style';

export const SiteDetails = () => {

  return (
    <Box >
      <Card align="left" style={cardStyleSiteDetails}>
        <CardContent>
          <Typography variant="h5">Site Details</Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
