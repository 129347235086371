import React, {  useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';
import StoreContext from '../../context';
import { AddBuildingDocument } from '../surveyForm.js/documents/AddBuildingDocument';
import { BuildingDocuments } from '../surveyForm.js/documents/BuildingDocuments';
import { BuildingDocumentsTableCard } from '../surveyForm.js/documents/BuildingDocumentsTableCard';
import { addNewBuildingDocument, deleteBuildingDocument, getBuildingDocumentsBySurveyId } from '../../service/buildingDocument';
import { WiredScoreRatings } from '../surveyForm.js/building/WiredScoreRatings';
import { AddWiredScoreRatings } from '../surveyForm.js/building/AddWiredScoreRatings';
import { addNewWiredScoreRating, deleteWiredScoreRating, getWiredScoreRatingBySurveyId } from '../../service/wiredScoreRating';
import { useSnackbar } from 'notistack';

export const SecondStep = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const [documentList, setDocumentList] = useState([]);
  const [wiredScoreRatingList, setWiredScoreRatingList] = useState([]);
  // for handling error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadingDocuments = async() => {
    const response = await getBuildingDocumentsBySurveyId(tokenValue, surveyId);
    setDocumentList(response.data);
  }

  const loadingRatings = async () => {
    const response = await getWiredScoreRatingBySurveyId(tokenValue, surveyId);
    setWiredScoreRatingList(response.data);
  }

  useEffect(() => {
    props.setIsSaved(true)
    props.setShowSaveButton(false)
    surveyId &&
      loadingRatings();
      loadingDocuments();
  }, [])

  const removeRating = async(id) =>{
    deleteWiredScoreRating(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Wired Score Rating.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingRatings();
        closeSnackbar();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const removeDocument = async(id) => {
    deleteBuildingDocument(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Building Document.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingDocuments();
        closeSnackbar();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const addRating = async(validUntil, wiredScoreRating) => {
    const body = {
      "data": {
        "type": "wiredScoreRating",
        "id": null,
        "attributes": {
          "wiredscoreRating":  wiredScoreRating,
          "validUntil": validUntil,
          "survey": surveyId
        }
      }
    }
    addNewWiredScoreRating(tokenValue, body)
    .then((response)=>{
      if(response.data){
        loadingRatings();
        closeSnackbar();
      }
      if(response.error){
        enqueueSnackbar(
         "Some data is wrong.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
      })
      .catch(() => {
        enqueueSnackbar(
          "There was an error contacting the server. Please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      });
    }

  const addDocument = async(file, documentType) =>{
    const requestData = new FormData();
    requestData.append('file', file);
    requestData.append('survey', surveyId);
    requestData.append('type', documentType);

    addNewBuildingDocument(tokenValue, requestData)
    .then((response)=>{
      if(response.data){
        loadingDocuments();
      }
      if(!response.data){
        enqueueSnackbar(
          "Please select image.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
      })
      .catch(() => {
        enqueueSnackbar(
          "There was an error contacting the server. Please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
    })
  }

  //style
  const cardStyle = {
    border: "none",
    boxShadow: "none", 
    minWidth: 704, 
    maxWidth: 704,
    background: theme.palette.neutral.main
  }

  return (
    <Box align="center" sx={{ marginTop:"40px", marginLeft: "250px"  }} style={cardStyle}>
      <BuildingDocuments/>
      <BuildingDocumentsTableCard 
        documentList={documentList}
        removeDocument={removeDocument}/>
      <AddBuildingDocument 
        addDocument={addDocument}/>
      <WiredScoreRatings 
        dataList={wiredScoreRatingList} 
        removeRating={removeRating} />
      <AddWiredScoreRatings 
        addRating={addRating}/>
    </Box>
  )
}
