import React, { useState, useEffect } from 'react';
import CardContent from '@mui/material/CardContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const BoolRadioBox = (props) => {
  const [error, setError] = useState(false);


  // watch "submitError" prop, if is true, force a validation of the data
  // this causes setError(true) if the field was empty and also required
  // props.setError sets form level error that can prevent the form being sent with non validated data
  // props.formData is a bool that is true when the value from this component is sent to the backend as formData rather than JSON"
  // formData will accept empty strings as "null" for integer fields, but JSON will not, it expects null.
  useEffect(() => {
    if (props.submitError) {
      validate(props.defaultValue);
    }
  }, [props.submitError])
  
  const validate = (value) => {
    if ( value === "" && !props.formData ) {
      if (props.required) {
        props.setError(true)
        setError(true)
      }
      setError(false)
      props.setError(false)
      props.setValue(null)
    } else {
      setError(false)
      props.setError(false)
      props.setValue(stringToBool(value))
    }
  }

  // even though "value" is bool in "FormControlLabel", it gets cast to a string when sent to "handleChange" function
  const stringToBool = (value) => {
    if (value === "true") return true;
    if (value === "false") return false;
    return "";
  }

  const handleChange = (event) => {
    validate(event.target.value);
  };
    
  return (
    <CardContent>
      <FormControl error={error}>
      <p>default value: {props.defaultValue}, type: {typeof props.defaultValue}, bool: {props.defaultValue ? "truthy" : "falsy"}, null: {props.defaultValue ?? "not null"}</p>
        <FormLabel id={props.id}>{props.boxTitle}</FormLabel>
        <RadioGroup
          row
          value={props.defaultValue ?? ""}  
          onChange={handleChange}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel value="" control={<Radio />} label="Unknown" />
        </RadioGroup>
      </FormControl>
    </CardContent>
  )
}

export const NewBoolRadioBox = ( {
  id,
  boxTitle,
  formData,
  attributes,
  setAttributes,
  attributeKey,
  error,
  setError,
  required,
  submitError,
} ) => {
  const [componentError, setcomponentError] = useState(false);

  useEffect(() => {
    if (submitError) {
      validate(attributes[attributeKey]);
    }
  }, [submitError])
  
  const validate = (value) => {
    if ( value === "" && !formData ) {
      if (required) {
        setError(true)
        setcomponentError(true)
      }
      setError(false)
      setcomponentError(false)
      setAttributes({...attributes, [attributeKey]: null})
    } else {
      setError(false)
      setcomponentError(false)
      setAttributes({...attributes, [attributeKey]: stringToBool(value)})
    }
  }

  // even though "value" is bool in "FormControlLabel", it gets cast to a string when sent to "handleChange" function
  const stringToBool = (value) => {
    if (value === "true") return true;
    if (value === "false") return false;
    return "";
  }

  const handleChange = (event) => {
    validate(event.target.value);
  };
    
  return (
    <CardContent>
      <FormControl error={error}>
        <FormLabel id={id || attributeKey}>{boxTitle}</FormLabel>
        <RadioGroup
          row
          value={attributes[attributeKey] ?? ""}
          onChange={handleChange}
        >
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel value="" control={<Radio />} label="Unknown" />
        </RadioGroup>
      </FormControl>
    </CardContent>
  )
}
