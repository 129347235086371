import Property from "../models/property";
import { fetchPropertyByName } from "./fetch";
import { getAllPropertyForMap } from "./property";

export const propertiesListSearched = async (nameForSearch, tokenValue) => {
  let searchedProperties = []
  try{
    const responseJSON = await fetchPropertyByName(nameForSearch, tokenValue)
      for (let i in responseJSON.data){
        var property = new Property(responseJSON.data[i]);
        searchedProperties.push(property);
      }
  }
  catch (error){
    return []
  }
  return searchedProperties
}

export const createPropertiesListForMap = async (tokenValue) => {
  let allProperties = []
  try{
    const responseJSON = await getAllPropertyForMap(tokenValue)
      for (let i in responseJSON.data){
        var property = new Property(responseJSON.data[i]);
        allProperties.push(property);
      }
  }
  catch (error){
    return []
  }
  return allProperties
}
