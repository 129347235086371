import React, { useEffect, useState } from 'react';
import { 
  CardContent, 
  TextField 
} from '@mui/material';

export const TextFieldBox = ({
  setValue, 
  boxTitle, 
  defaultValue,
  submitError,
  required,
  id,
  helperText,
  formData,
}) => {
  
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if (submitError) {
      validate(defaultValue);
    }
  }, [submitError])

  const validate = (value) => {
    if ( value === "" && !formData ) {
      if (required) {
        setError(true)
      }
      setValue(null)
    } else {
      setError(false)
      setValue(value)
    }
  }
  
  const handleChange = (event) => {
    validate(event.target.value);
  };

  return (
    <CardContent>
      <TextField 
        id={id || "text-field-box"}
        helperText={helperText}
        label={boxTitle} 
        value={defaultValue || ""}
        InputLabelProps={{
          shrink: true
        }}
        style = {{width: 400}}
        required={required}
        onChange={handleChange}
        error={error}
      />
    </CardContent>
  )
}

// props should include the list of the attributes for that form,
// the setAttributes hook, and the key of the specific attribute to be updated by this component.
export const NewTextFieldBox = ({
  attributes, 
  setAttributes,
  boxTitle, 
  attributeKey,
  submitError,
  required,
  id,
  helperText,
  formData,
}) => {
  
  const [error, setError] = useState(false);
  
  useEffect(() => {
    if (submitError) {
      validate(attributes[attributeKey]);
    }
  }, [submitError])

  const validate = (value) => {
    if ( value === "" && !formData ) {
      if (required) {
        setError(true)
      }
      setAttributes({...attributes, [attributeKey]: null})
    } else {
      setError(false)
      setAttributes({...attributes, [attributeKey]: value})
    }
  }
  
  const handleChange = (event) => {
    validate(event.target.value);
  };

  return (
    <CardContent>
      <TextField 
        id={id || "text-field-box"}
        helperText={helperText}
        label={boxTitle} 
        value={attributes[attributeKey] || ""}
        InputLabelProps={{
          shrink: true
        }}
        style = {{width: 400}}
        required={required}
        onChange={handleChange}
        error={error}
      />
    </CardContent>
)
}
