import React from 'react';
import { 
    Checkbox,
    FormControlLabel, 
    FormGroup, 
    CardContent,
  } from '@mui/material';
export const CheckboxForm = (props) => {
  
  return (
    <CardContent>
        <FormGroup>
          <FormControlLabel 
          control={
          <Checkbox  
            checked={props.defaultValue}
            onChange={(e) => props.setValue(e.target.checked)}
          />} 
          label={props.title} />
        </FormGroup>
    </CardContent>
  )
}
