import React, {Fragment, useState} from 'react';
import { 
  TableRow, 
  TableCell, 
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { buttonsTablePhotos, cellTablePhotos } from '../../../../style';
import ConformDialog from '../../ConformDelete';
import { Update } from '../Update';
import { getFileNameFromUrl } from '../../../utils';

export const TableRows = (props) => {
  const photo = props.photo;
  const tagsList = props.tagsList;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
    
  // searches the tagsList array for tag ids that match the tag ids attached to a specific photo
  const getIndexOfDefaultValues = () => {
    let defaultValues = []
    if (props.photo) {
      photo.attributes.tags.forEach((photoTag) => {
        defaultValues.push(props.tagsList.findIndex( tag => tag.id == photoTag.id ))
      })
    }
    return defaultValues;
  }
  
  // returns array of tag objects from tagsList where the tag.id is in photo.tags.id
  const getDefaultValues = () => {
    return getIndexOfDefaultValues().map(index => tagsList[index])
  }
  

  return (
    <Fragment >
      <TableRow>
        <TableCell>
          <img src={(photo?.attributes?.thumb)} 
            alt={photo?.type}></img>
        </TableCell>
        <TableCell sx={cellTablePhotos}>{getFileNameFromUrl(photo?.attributes.photo)}</TableCell>
        <TableCell sx={cellTablePhotos}> 
        {photo?.attributes?.tags &&
          photo?.attributes?.tags?.map((tag) => 
           {return tag.tag +", " }
          ) 
        }
        </TableCell>
        <TableCell sx={buttonsTablePhotos}>
            <IconButton onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() =>setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      {openDeleteDialog && 
      <ConformDialog 
        setOpenDialog={setOpenDeleteDialog} 
        title={"Photo"} 
        deleteFunction={() => props.removePhoto(photo.id)}/>
      }
      {openEditDialog && 
      <Update 
        dialogTitle={"Edit Tags"} 
        closeDialog={setOpenEditDialog} 
        editPhoto={props.editPhoto}
        photo={photo}
        tagsList={tagsList}
        setTagsList={props.setTagsList}
        selectedTagsList={props.selectedTagsList}
        setSelectedTagsList={props.setSelectedTagsList}
        selectedPhotos={getFileNameFromUrl(photo?.attributes.photo)}
        defaultTagsList={getDefaultValues()}
        createNewPhotoTag={props.createNewPhotoTag}
        />
      }
    </Fragment>
  )
}
