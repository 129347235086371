import React, { Fragment, useState } from 'react';
import { 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddNewAsset } from '../../../dialogs/AddNewAsset';

export const AssetRow = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const row = props.row;
  const assetId = props.assetId;
  const locationId = props.locationId;
  const cabinetId = props.cabinetId;

  return (
    <Fragment>
      <TableRow>
          <TableCell >{row.asset_type}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.make}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.model}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.owner}</TableCell>          
          <TableCell sx={cellStyleLocationTableBorder}>{row.status}</TableCell>
          <TableCell sx={cellStyleLocationTableButtons}>
            <IconButton onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Asset"} 
          deleteFunction={() => props.deleteAsset(row.id)}/>
      }
      {openEditDialog &&
        <AddNewAsset
          dialogTitle={"Edit Rack Mounted Asset"}
          locationId={locationId}
          cabinetId={cabinetId}
          closeDialog={setOpenEditDialog}
          loadingTable={props.loadingTable}
          assetId={assetId}
        />
      }
    </Fragment>
  )
}
