import React, { useState, useEffect } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {BasicTable} from './BasicTable';
import {ColumnsMenu} from './ColumnsMenu';
import Box from '@mui/material/Box';


const EntryPointTableControls = ( {entryPoints, setFiltered, columns, setColumns} ) => {

  const [filters, setFilters] = useState({
    locations: true,
    risers: true,
    carrier: "All"
  });

  
  const carrierList = [...new Set(entryPoints.map(entryPoint => entryPoint.carrier))]
  
  useEffect(() => {
    filterEntryPoints();
  }, [filters])
  
  const filterEntryPoints = () => {
    setFiltered(
      entryPoints
      .filter(entryPoint => !entryPoint.location || filters.locations)
      .filter(entryPoint => !entryPoint.riser || filters.risers)
      .filter(entryPoint => entryPoint.carrier === filters.carrier || filters.carrier === "All")
    )
  }
  
    
  const toggleFilter = (event) => {
    setFilters(
      {
        ...filters,
        [event.target.name]: event.target.checked,
      }
    );
  };
  
  const selectFilter = (event) => {
    setFilters(
      {
        ...filters,
        [event.target.name]: event.target.value,
      }
    );
  };

  return(
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup row>
        <FormControlLabel control={
          <Switch
            checked={filters.locations}
            name="locations"
            onChange={toggleFilter}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        } label="Locations" />
        <FormControlLabel control={
          <Switch
            checked={filters.risers}
            name="risers"
            onChange={toggleFilter}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        } label="Risers" />
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="carrier-select-label">Carrier</InputLabel>
          <Select
            labelId="carrier-select-label"
            id="carrier-select"
            autoWidth
            value={filters.carrier}
            name="carrier"
            label="Carrier"
            onChange={selectFilter}
          >
          <MenuItem value="All" key={"all"}>All</MenuItem>
          {
            carrierList.map( (x) => { 
              return(<MenuItem value={x} key={x}>{x}</MenuItem>) 
            })
          }
          </Select>
        </FormControl>
      </FormGroup>
      <ColumnsMenu columns={columns} setColumns={setColumns} />
    </Box>
  )
}


export const EntryPointTable = ( props ) => {
  const [filtered, setFiltered] = useState(props.rows);
  const [columns, setColumns] = useState(props.columns);

  return (
    <>
    <EntryPointTableControls 
      entryPoints={props.rows} 
      setFiltered={setFiltered}
      columns={columns}
      setColumns={setColumns}
    />
    <BasicTable
      columns={columns.filter(column => column.display)}
      filtered={filtered}
    />
    </>
  )
}

