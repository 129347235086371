import React from 'react';
import { 
  Card,
  CardMedia,
  CardContent,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { BuildingInformation } from './BuildingInformation';
import { ManagerInformation } from './ManagerInformation';
import { Box } from '@mui/system';

export const HeadInformation = (props) => {
  const property = props.property;
  const propertyPhoto = property.propertyphotoSet[0]?.photo;

  const buildingInformation = 
  {
  buildingName: property.buildingName,
  buildingAddress: property?.address?.formatted,
  buildingManagers: [
    { 
      id: "1",
      contact: property.buildingManager?.name ,
      role: "Building Manager"
    },
    {
      id: "2",
      contact: property.assetManager?.name,
      role: "Asset Manager"
    } 
  ]}

  // style
  const shadowStyle={
    width: 1100,
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    display: "flex",
    marginLeft: "20px",
    marginTop: "20px",
    overflow: "auto"
    
  }

  return (
    <Paper
      xs={12}
      sx={shadowStyle}
    >
      <Grid container>
      { propertyPhoto && 
        <Grid item
          sm={12}
          md="auto"
          component="img" 
          sx={{ maxHeight: 300 }}
          alt="building-main-photo"
          src={propertyPhoto} 
        />
      }
        <Grid item sm={12} md container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs sx={{ marginTop: "10px" }}>
              <BuildingInformation buildingName={buildingInformation.buildingName} buildingAddress={buildingInformation.buildingAddress}/>
            </Grid>
              {buildingInformation.buildingManagers.map((manager)=> {
                if (manager.contact) {
                  return <Grid item xs><ManagerInformation key={manager.id} contact={manager.contact} role={manager.role}/></Grid>
                }
                return null;
              }
              )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}
