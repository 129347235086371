import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  Table, 
  TableBody,
  TableCell,
  TableFooter,
  TablePagination, 
  TableRow 
} from '@mui/material';
import theme from '../../theme';
import { DialogModalTitle } from '../card/DialogModalTitle';
import { LabelModalData } from './LabelModalData';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { PhotoDialog } from '../photoDialogs/PhotoDialog';

export const RisersModal = (props) => {
  const riserSet = props.riserSet;
  const [page, setPage] = useState(0);
  const [showRiserPhoto, setShowRiserPhoto] = useState(false);
  const [showRoofDemarcationPhoto, setShowRoofDemarcationPhoto] = useState(false);
  const rowsPerPage  =(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeModal = () => {
      props.setShowModal(false);
  }

  const handleClickRiserPhoto = () => {
    setShowRiserPhoto(true);
  }

  const handleClickRoofDemarcationPhoto = () => {
    setShowRoofDemarcationPhoto(true)
  }

  // style
  const styleHeader = {
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "100px",
    padding:"1px 0px !important",
    align: "center",

  }
  const styleData ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "100px",
    align: "left",
    padding:"1px 0px !important"
  }
  const styleDataImg ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "50px",
    align: "right",
    padding:"1px 0px !important"
  }
  const styleImg  ={ 
    ':hover': {
      cursor: "pointer",
    },
  }

  return (
    <Dialog open={true}>
    <DialogModalTitle title="Risers" /> 
    <DialogContent>
    {showRiserPhoto && <PhotoDialog 
      setShowState={setShowRiserPhoto} 
      title={"Riser Photo"} photoSet={riserSet[page].riser_photo_set}/>
    }
    {showRoofDemarcationPhoto && <PhotoDialog 
      setShowState={setShowRoofDemarcationPhoto} title={"Roof Demarcation Photo"} 
      photoSet={riserSet[page].roof_demarcation_photo_set}/>
    }
    <Table aria-label="simple table" id="raiser-modal-table">
      <TableBody>
        <LabelModalData title={"Start"} data={riserSet[page].location.name}/>
        <LabelModalData title={"End"} data={riserSet[page].location2.name}/>
        {(riserSet[page].has_space_for_new_cables === true) ? 
        <LabelModalData title={"Space for new cables"} data={"Yes"}/>:
        <LabelModalData title={"Space for new cables"} data={"No"}/>
        }
        {(riserSet[page].has_space_for_termination_enclosure === true) ?
        <LabelModalData title={"space for termination enclosures"} data={"Yes"}/>:
        <LabelModalData title={"space for termination enclosures"} data={"No"}/>
        }
        {(riserSet[page].has_horizontal_cable_runs === true) ?
        <LabelModalData title={"Horizontal Cable runs"} data={"Yes"}/> :
        <LabelModalData title={"Horizontal Cable runs"} data={"No"}/>
        }
        {(riserSet[page].is_strip_out_required) ? 
        <LabelModalData title={"Strip out required"} data={"Yes"}/> :
        <LabelModalData title={"Strip out required"} data={"No"}/>
        }
        <LabelModalData title={">7m from another riser"} data={riserSet[page].is_over_7_meters_from_another_riser} />
        {(riserSet[page].runs_from_basement_to_roof) ? 
        <LabelModalData title={"Runs from basement to roof"} data={"Yes"}/>:
        <LabelModalData title={"Runs from basement to roof"} data={"No"}/>
        }
        <TableRow>
          <TableCell sx={styleHeader}>Riser Photos</TableCell>
          {(riserSet[page].riser_photo_set.length <= 1) ?
            <TableCell sx={styleData}>{riserSet[page].riser_photo_set.length} photo</TableCell> :
            <TableCell sx={styleData}>{riserSet[page].riser_photo_set.length} photos</TableCell>
          }
          <TableCell sx={styleDataImg}>
            <PhotoCameraIcon sx={styleImg} onClick={handleClickRiserPhoto}/>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={styleHeader}>Roof demarcation photos</TableCell>
          {(riserSet[page].roof_demarcation_photo_set.length <= 1) ?
            <TableCell sx={styleData}>{riserSet[page].roof_demarcation_photo_set.length} photo</TableCell> :
            <TableCell sx={styleData}>{riserSet[page].roof_demarcation_photo_set.length} photos</TableCell>
          }
          <TableCell sx={styleDataImg}>
            <PhotoCameraIcon sx={styleImg} onClick={handleClickRoofDemarcationPhoto} />
          </TableCell>
        </TableRow>       
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            sx ={{
              color: theme.palette.primary.main
            }}
            rowsPerPageOptions={[]}
            count={riserSet.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </TableRow>
      </TableFooter>
    </Table>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeModal}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
  )
}
