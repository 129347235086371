import React from 'react';
import { 
  TableCell, 
  TableRow 
} from '@mui/material';

export const EnhancedEmptyRow = (props) => {

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
  props.page > 0 ? Math.max(0, (1 + props.page) * props.rowsPerPage - props.rows.length) : 0;

  return (
    <>
      {emptyRows > 0 && (
        <TableRow style={{ height: 68 * emptyRows }}>
          <TableCell colSpan={6} />
        </TableRow>
      )}  
    </>
  )
}
