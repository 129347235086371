import React, { Fragment, useState } from 'react';
import { 
  Collapse, 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons, cellStyleLocationTableDropdownButton, rowBackground } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FibreCoreTable } from '../fibreCore/FibreCoreTable';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddFibreTube } from '../../../dialogs/AddFibreTube';

export const RowFibreTube = (props) => {
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const row = props.row;
  const fibreTubeId = props.fibreTubeId;
  const fibreTubeBundleId = props.fibreTubeBundleId


  return (
    <Fragment>
      <TableRow>
        <TableCell sx={cellStyleLocationTableDropdownButton}>
          <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.serial}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.asset}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.asset2}</TableCell>
          <TableCell sx={cellStyleLocationTableButtons}>
            <IconButton onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      <TableRow sx={rowBackground}>
        {/* Show Subtable */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/** add Fibre Core Table*/}
              <FibreCoreTable 
                fibreCoreList={row.fibre_core_set}
                deleteFibreTubeCore={props.deleteFibreTubeCore}
                fibreTubeId={fibreTubeId}
                loadingFibreTubeBundle={props.loadingFibreTubeBundle}/> 
          </Collapse>
        </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"fibre tube"} 
          deleteFunction={() => props.deleteFibreTube(fibreTubeId)}/>
      }
      {openEditDialog && 
        <AddFibreTube
          dialogTitle={"Edit Fibre Tube"} 
          fibreTubeId={fibreTubeId}
          fibreTubeBundleId={fibreTubeBundleId}
          closeDialog={setOpenEditDialog}
          loadingFibreTubeBundle={props.loadingFibreTubeBundle}
        />
      } 
    </Fragment>
  )
}
