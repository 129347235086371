
import React, { useState, useEffect } from 'react';
import { 
  Card, 
  Typography 
} from '@mui/material';
import { Box } from '@mui/system';
import { DropDownBoxWithLabel } from '../DropDownBoxWithLabel';
import { DateBox } from '../DateBox';
import { PropertySelect } from './PropertySelect';
import { PropertySelectEditSurvey } from './PropertySelectEditSurvey';
import { surveyTypeList, yseNoDataList } from '../../../models/enumTypeLists';
import { cardStyleBuildingInfo } from '../../../style';

export const BuildingInformation = (props) => {
  const dataList = props.dataList;
  const defaultPropertyValue = props.defaultPropertyValue;
  const defaultSurveyType = props.defaultSurveyType;
  const defaultSurveyComplete = props.defaultSurveyComplete;
  const defaultSurveyDate = props.defaultSurveyDate;
  const disableBox = props.disableBox;
  const [buildingName, setBuildingName] = useState(null);

  useEffect(() => {
    if (props.propertyId && props.dataList.length) {
      findBuildingName(props.propertyId);
    }
  }, [dataList, props.propertyId])

  
  const findBuildingName = (id) => {
    const buildingName = dataList.find(building => building.id === id)
    if (buildingName) {
      if (buildingName.attributes) {
        setBuildingName(buildingName.attributes.buildingName)
      }
    }
  }

  return (
    <Box sx={{marginTop: "20px"}}>
      <Typography variant="h5">Building Information</Typography>
      <Card align="left" style={cardStyleBuildingInfo}>
        <Box sx={{marginLeft: "40px"}} align="center">
          {props.surveyId ? 
            <PropertySelectEditSurvey 
              dataList={dataList}
              defaultValue={buildingName}
              buildingAlreadyExistDialog={props.buildingAlreadyExistDialog}/>
          : 
            <PropertySelect
              dataList={dataList} 
              setDisableBox={props.setDisableBox}
              setEnableSaveButton={props.setEnableSaveButton}
              buildingAlreadyExistDialog={props.buildingAlreadyExistDialog}
              setSurveyList={props.setSurveyList}
              setPropertyId={props.setPropertyId}/> 
          }
          <DateBox 
            boxTitle="Survey Date"
            defaultValue={defaultSurveyDate}
            setEnableSaveButton={props.setEnableSaveButton}
            setDate={props.setDate}
            disableBox={disableBox} />
          <DropDownBoxWithLabel 
            boxTitle={"Is Desktop Survey Complete"} 
            dataList={yseNoDataList} 
            setEnableSaveButton={props.setEnableSaveButton}
            defaultValue={defaultSurveyComplete}
            disableBox={disableBox}
            setValue={props.setDesktopSurveyComplete}/>
          <DropDownBoxWithLabel 
            boxTitle={"Type"} 
            dataList={surveyTypeList}
            setEnableSaveButton={props.setEnableSaveButton}
            defaultValue={defaultSurveyType} 
            disableBox={disableBox}
            setValue={props.setSurveyType}/>
        </Box>
      </Card>
    </Box>
  )
}
