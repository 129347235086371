import React, { useState, useContext, useEffect } from 'react';
import { 
    Checkbox,
    FormControlLabel, 
    FormGroup, 
  } from '@mui/material';
  import Button from '@mui/material/Button';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useTheme } from '@mui/material/styles';
  import { TextFieldBox } from '../surveyForm.js/TextFieldBox';
import { DropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import StoreContext from '../../context';
import { getCabinetsNameIdBySurveyId, getLocationsNameIdBySurveyId } from '../../service/asset';
import { AssetSelect } from '../surveyForm.js/connections/AssetSelect';
import { NumberFieldBox } from '../surveyForm.js/NumberFieldBox';
import { addNewFibreTubeBundle, getFibreTubeBundle, updateFibreTubeBundle } from '../../service/connections';
import { ownerType } from '../../models/enumTypeLists';
import { useSnackbar } from 'notistack';

export const AddFibreTubeBundle = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const fibreTubeBundleId = props.fibreTubeBundleId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [locationList, setLocationList] = useState([]);
  const [cabinetList, setCabinetList] = useState([]);
  const initState = {
    "serial": null,
    "numberOfTubes": null,
    "isInstalledByZetta": false,
    "survey": surveyId,
    "cabinet": "",
    "location": "",
    "qrCode": "",
    "owner": "",
    "description": ""
  };
  const [attributes, setAttributes] = useState(initState);
  const [error, setError] = useState(false);

  const loadingLocations = async() =>{
    getLocationsNameIdBySurveyId(tokenValue, surveyId)
    .then((response) =>
    setLocationList(response.data)
    )
  }
  const loadingCabinets = async() =>{
    getCabinetsNameIdBySurveyId(tokenValue, surveyId)
    .then((response) =>{
      setCabinetList(response.data)}
    )
  }
  
  const loadingFibreTubeBundle = async() => {
    getFibreTubeBundle(tokenValue, fibreTubeBundleId)
    .then((response) => {
      const respData = response.data.attributes;
      setAttributes(attributes =>({...attributes, numberOfTubes: respData?.numberOfTubes || 0}));
      setAttributes(attributes =>({...attributes, serial: respData?.serial}));
      setAttributes(attributes =>({...attributes, owner: respData?.owner}));
      setAttributes(attributes =>({...attributes, qrCode: respData?.qrCode}));
      setAttributes(attributes =>({...attributes, isInstalledByZetta: respData?.isInstalledByZetta}));
      setAttributes(attributes =>({...attributes, cabinet: response.data.relationships?.cabinet.data.id}));
      setAttributes(attributes =>({...attributes, location: response.data.relationships?.location.data.id}));
    })
  }
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    loadingCabinets();
    loadingLocations();
    fibreTubeBundleId && 
      loadingFibreTubeBundle()
  }, [])
  
  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if (fibreTubeBundleId){
      editFibreTubeBundle()
      props.closeDialog(false);
    }
    else {
      if ((attributes.cabinet) && (attributes.location)){
        addFibreTubeBundle();
        props.closeDialog(false);
      }
      else{
        enqueueSnackbar(
          "Please fill in the required fields.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    }
    
  }

  const setCabinet = (value) => {
    setAttributes(attributes =>({...attributes, cabinet: value}));
  }
  const setLocation = (value) => {
    setAttributes(attributes =>({...attributes, location: value}));
  }
  const setNumberOfTubes = (value) => {
    setAttributes(attributes =>({...attributes, numberOfTubes: value}));
  }
  const setSerial = (value) => {
    setAttributes(attributes =>({...attributes, serial: value}));
  }
  const setOwner = (value) => {
    setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQrCode = (value) => {
    setAttributes(attributes =>({...attributes, qrCode: value}));
  }
  const setInstalledByZetta = (value) => {
    setAttributes(attributes =>({...attributes, isInstalledByZetta: value}));
  }

  const addFibreTubeBundle = async() => {
    const body = {
      "data": {
        "type": "fibreTubeBundle",
        "id": null,
        "attributes": attributes
      }
    }
    addNewFibreTubeBundle(tokenValue, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Fibre Tube Bundle.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingFibreTubeBundle();
      }
    })
  }

  const editFibreTubeBundle = async() => {
    const body = {
      "data": {
        "type": "fibreTubeBundle",
        "id": fibreTubeBundleId,
        "attributes": attributes
      }
    }
    updateFibreTubeBundle(tokenValue, fibreTubeBundleId, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited Fibre Tube Bundle.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingFibreTubeBundle();
      }
    })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <AssetSelect 
          label={"Start Cabinet"} 
          dataList={cabinetList}
          defaultValue={attributes.cabinet} 
          setValue={setCabinet}
          required={true}/>
        <AssetSelect 
          label={"End Location"} 
          dataList={locationList}
          defaultValue={attributes.location} 
          setValue={setLocation}
          required={true}/>
        <NumberFieldBox 
          boxTitle={"Number of tubes"}
          setValue={setNumberOfTubes}
          defaultValue={attributes.numberOfTubes}
          setError={setError}
        />
        <TextFieldBox 
          boxTitle={"Serial"}
          setValue={setSerial}
          defaultValue={attributes.serial}
        />
        <DropDownBoxWithLabel 
          boxTitle={"Owner"} 
          dataList={ownerType}
          setValue={setOwner}
          defaultValue={attributes.owner}
        />
        <TextFieldBox
          boxTitle={"QR Code"}
          setValue={setQrCode}
          defaultValue={attributes.qrCode}
        />
        <FormGroup>
        <FormControlLabel 
          control={
          <Checkbox 
            checked={attributes.isInstalledByZetta}
            onChange={(e) => setInstalledByZetta(e.target.checked)}
          />} 
          label="Installed by Zetta as part of these works"
        />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
