import React from "react";
import { observer } from "mobx-react";
import {
  Box, 
  Grid,
} from "@mui/material";
import { HorizontalStepper } from "../components/stepper/HorizontalStepper";

export default observer(({ match }) => {
  const { id } = match.params;

  return (
    <Box sx={{m:2}} >
      <Grid item>
        <HorizontalStepper surveyId={id}/>
      </Grid>
    </Box>
  );
});