import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Box } from '@mui/material';
import theme from '../../theme';
import { Connections } from '../surveyForm.js/connections/Connections'
import { ConnectionTable } from '../surveyForm.js/connections/ConnectionTable';
import StoreContext from '../../context';
import { getFibreTubeBundleBySurveyId, deleteFibreCore, deleteFibreTube, deleteFibreTubeBundle } from '../../service/connections';
import { useSnackbar } from 'notistack';

export const FifthStep = forwardRef((props, ref) => {
  const history = useHistory();
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const [fibreTubeBundleList, setFibreTubeBundleList] = useState([]);
  // for handling error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useImperativeHandle(ref, () => ({
    saveDataFunction() {
      let path = `/surveys`;
      history.push(path);;
    }
  }));

  useEffect(() => {
    props.setShowSaveButton(true);
    props.setEnableSaveButton(true);
    surveyId &&
      loadingFibreTubeBundle();
  }, [])

  const loadingFibreTubeBundle = async () => {
    getFibreTubeBundleBySurveyId(tokenValue, surveyId)
    .then((response) => {
      if(response.data){
        setFibreTubeBundleList(response.data);
      }
    })   
  }

  const removeFibreTubeBundle = (id) => {
    deleteFibreTubeBundle(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Fibre Tube Bundle.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingFibreTubeBundle();
      };
    });
  }

  const removeFibreTube = (id) => {
    deleteFibreTube(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Fibre Tube.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingFibreTubeBundle();
      }
    });
  }
  
  const removeFibreTubeCore = (id) => {
    deleteFibreCore(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Fibre Core.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingFibreTubeBundle();
      }
    });
  }

  //style
  const cardStyle = {
    border: "none",
    boxShadow: "none", 
    minWidth: 804, 
    maxWidth: 1104,
    background: theme.palette.neutral.main
  }

  return (
    <Box align="center" sx={{ marginTop:"40px", marginLeft: "10px"  }} style={cardStyle}>
      <Connections />
      <ConnectionTable 
        fibreTubeBundleList={fibreTubeBundleList}
        deleteFibreTubeBundle={removeFibreTubeBundle}
        deleteFibreTube={removeFibreTube}
        deleteFibreTubeCore={removeFibreTubeCore}
        surveyId={surveyId}
        loadingFibreTubeBundle={loadingFibreTubeBundle}
        />
    </Box>
  )
})
