import React, {useState} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {Section, Documents, checkBool, checkString, Photos} from './GridComponents';

export const CommsRoom = ( {commsrooms, handleOpenModal} ) => {
  
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  if (!commsrooms.length) {
    return <Typography>No comms rooms on record for this building</Typography>
  }
  
  return(
    <Stack spacing={2}>
      <Pagination count={commsrooms.length} page={page} onChange={handleChange} />
      <Typography>{commsrooms[page-1].name}</Typography>
      
      <Section
        subheading={"Comms room details"}
        keyVals={{
          "Comms room name": commsrooms[page - 1].name,
          "Elevation": commsrooms[page - 1].elevation,
          "Dedicated space": commsrooms[page - 1].cr_dedicated_space,
          "Door lock": commsrooms[page - 1].cr_door_lock_type,
        }}
      />

      <Section
        subheading={"Available space"}
        keyVals={{
          "Space for a meetme cabinet": checkBool(commsrooms[page - 1].cr_has_space_for_meetme),
          "Maximum cabinet size that would fit": checkString(commsrooms[page - 1].cr_max_cabinet_size),
          "Dedicated space": checkString(commsrooms[page - 1].cr_dedicated_space),
          "Would a cabinet fit from the entrance": checkBool(commsrooms[page - 1].cr_would_cabinet_fit_from_entrance),
        }}
      />

      <Section
        subheading={"Power"}
        keyVals={{
          "Has appropriate power": checkBool(commsrooms[page - 1].cr_has_appropriate_power),
          "Has temporary power": checkString(commsrooms[page - 1].cr_has_temporary_power),
          "No. of 16A commandos required": checkString(commsrooms[page - 1].cr_16a_commandos_required),
        }}
      />

      <Section
        subheading={"Protection"}
        keyVals={{
          "Flood protection": checkString(commsrooms[page - 1].cr_flood_protection_details),
          "Fire protection": checkString(commsrooms[page - 1].cr_fire_protection),
          "Has HVAC": checkBool(commsrooms[page - 1].cr_has_hvac),
        }}
      />

      <Section
        subheading={"Room status"}
        keyVals={{
          "Ready for install": checkBool(commsrooms[page - 1].cr_is_ready_for_install),
          "Has diverse routes to risers": checkBool(commsrooms[page - 1].cr_has_diverse_routes_to_risers),
          "Other notes": checkString(commsrooms[page - 1].cr_other_notes),
        }}
      />
      
      <Photos
        photos={commsrooms[page - 1].locationphoto_set || [] }
        handleOpenModal={handleOpenModal}
      />
      

    </Stack>
  )
}
