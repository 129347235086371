import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import { rackMountedAssetType } from '../../models/enumTypeLists';
import { FirewallFields } from './asset/FirewallFields';
import { NetworkSwitchFields } from './asset/NetworkSwitchFields';
import { SRSPanelFields } from './asset/SRSPanelFields';
import { FibrePatchPanelFields } from './asset/FibrePatchPanelFields';
import { UPSFields } from './asset/UPSFields';
import { PDUFields } from './asset/PDUFields';
import StoreContext from '../../context';
import { addNewAsset, getAsset, getAssetTypeByName, updateAsset } from '../../service/asset';
import { useSnackbar } from 'notistack';

export const AddNewAsset = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedType, setSelectedType] = useState("");
  const locationId = props.locationId;
  const cabinetId = props.cabinetId;
  const assetId = props.assetId;
  const initAttributes = {
    "make": "",
    "model": "",
    "rackPositionInCabinet": null,
    "rackSize": null,
    "netPorts": null,
    "netMacAddress": null,
    "serial": "",
    "status": "N/A",
    "owner": "",
    "qrCode": "",
    "upsBatteryCapacity": null,
    "isInstalledByZetta": false,
    "pduAlignment": "",
    "pdu_number_of_plugs": null,
    "in_cabinet": cabinetId,
    "location": locationId,
    "asset_type_id": selectedType,
    
  };
  const [attributes, setAttributes] = useState(initAttributes);
  // for handling error
  const [error, setError] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if (error) {
      enqueueSnackbar(
        "Please enter valid Mac Address.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
    else {
      if (assetId) {
        editAsset();
        props.closeDialog(false);
      }
      else {
        if(selectedType){
          addAsset();
          props.closeDialog(false);
        }
        else {
          enqueueSnackbar(
            "Please select Asset Type.",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          ); 
        }
      }
      
    }
  }

  const addAsset = async () =>{
    const body = {
      "data": {
        "type": "asset",
        "id": null,
        "attributes": attributes
      }
    };
    addNewAsset(tokenValue, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingTable()
      }
      if(!response.data){
        enqueueSnackbar(
          response.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const editAsset = async () =>{
    const body = {
      "data": {
        "type": "asset",
        "id": assetId,
        "attributes": attributes
      }
    };
    updateAsset(tokenValue, assetId, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited the Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingTable()
      }
      if(!response.data){
        enqueueSnackbar(
          response.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  useEffect(() => {
    assetId &&
      loadingAsset()
  }, [])

  const loadingAssetType = () => {
    getAssetTypeByName(tokenValue, selectedType)
    .then((response) => {
      setAttributes(attributes =>({...attributes, asset_type_id: response.data[0].id}));
    })
  }
    
  const loadingAsset = async () => {
    getAsset(tokenValue, assetId)
    .then((response) => {
      const respData = response.data.attributes;
      setSelectedType(respData.assetType.name)
      setAttributes(attributes =>({...attributes, make: respData.make}));
      setAttributes(attributes =>({...attributes, make: respData.make}));
      setAttributes(attributes =>({...attributes, model: respData.model}));
      setAttributes(attributes =>({...attributes, rackPositionInCabinet: respData.rackPositionInCabinet}));
      setAttributes(attributes =>({...attributes, rackSize: respData.rackSize}));
      setAttributes(attributes =>({...attributes, netPorts: respData.netPorts}));
      setAttributes(attributes =>({...attributes, netMacAddress: respData.netMacAddress}));
      setAttributes(attributes =>({...attributes, serial: respData.serial}));
      setAttributes(attributes =>({...attributes, status: respData.status}));
      setAttributes(attributes =>({...attributes, owner: respData.owner}));
      setAttributes(attributes =>({...attributes, qrCode: respData.qrCode}));
      setAttributes(attributes =>({...attributes, isInstalledByZetta: respData.isInstalledByZetta}));
      setAttributes(attributes =>({...attributes, upsBatteryCapacity: respData.upsBatteryCapacity}));
      setAttributes(attributes =>({...attributes, pduAlignment: respData.pduAlignment}));
      setAttributes(attributes =>({...attributes, pdu_number_of_plugs: respData.pduNumberOfPlugs}));
    })
  }


  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DropDownBoxWithLabel 
          boxTitle={"Type"} 
          dataList={rackMountedAssetType}
          defaultValue={selectedType}
          setValue={setSelectedType} 
          />
      </DialogContent>
      { selectedType === "Firewall" &&
        <FirewallFields 
          setAttributes={setAttributes}
          attributes={attributes}
          error={error}
          setError={setError}
          loadingAssetType={loadingAssetType}
          />
      }
      { selectedType === "Network Switch" &&
        <NetworkSwitchFields 
          setAttributes={setAttributes}
          attributes={attributes}
          NetworkSwitchFields
          error={error}
          setError={setError}
          loadingAssetType={loadingAssetType}
          />
      }
      { selectedType === "SRS Panel" &&
        <SRSPanelFields 
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
          />
      }
      { selectedType === "Fibre Patch Panel" &&
        <FibrePatchPanelFields  
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
        />
      }
      { selectedType === "UPS" &&
        <UPSFields  
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
        />
      }
      { selectedType === "PDU" &&
        <PDUFields  
          setAttributes={setAttributes}
          attributes={attributes}
          loadingAssetType={loadingAssetType}
          />
      }
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
