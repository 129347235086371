import React from 'react';
import { 
  Box,
  CardContent,
  TextField
 } from '@mui/material'


export const PositionInCabSizePorts = (props) => {
  const attributes = props.attributes;

  return (
    <CardContent>
      <Box sx={{ display: 'flex', flexDirection: 'inline' }}>
        <TextField 
          id="outlined-basic-floors-lifts" 
          label={"Position in Cabinet"} 
          helperText={"U from bottom"}
          value={attributes.rackPositionInCabinet}
          type="number"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          style = {{width: 130, marginLeft: "20px"}} 
          onChange={(e) => props.setPositionInCabinet(e.target.value)}
        />
        <TextField 
          id="outlined-basic-floors-lifts" 
          label={"Size (U)"} 
          value={attributes.rackSize}
          variant="outlined"
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          style = {{width: 130, marginLeft: "20px"}} 
          onChange={(e) => props.setSize(e.target.value)}
        />
        <TextField 
          id="outlined-basic-floors-lifts" 
          label={"Ports"} 
          value={attributes.netPorts}
          type="number"
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          style = {{width: 130, marginLeft: "20px"}} 
          onChange={(e) => props.setPorts(e.target.value)}
        />
      </Box>
    </CardContent>
  )
}
