import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';

export const LocationSelect = (props) => {
  const dataList = props.dataList;
  const defaultValue = props.defaultValue;
  const label = props.label;
  const [selectState, setSelectState] = useState();  
  
  const handleChange = (event) => {
    props.setValue(event.target.value);
    setSelectState(event.target.value);
  }

  return (
    <CardContent>
      <TextField
        required={props.required}
        id="outlined-select-nearby-network"
        select
        label={label}
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || defaultValue}
        sx={{minWidth:400}}
        SelectProps={{
            MenuProps: {
              sx: { maxHeight: '50%', marginLeft: "2px"}
            }
          }}
      >
        {dataList?.map((d, index) => (
          <MenuItem
            key={d.id}
            value={d.id}
          >
            {d?.attributes?.name}
          </MenuItem>
        ))}
      </TextField>
    </CardContent>
  )
}
