import React, {useState} from "react";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SurveyList } from "../dialogs/table/SurveyList";
import { ButtonsInDialog } from "./ButtonsInDialog";

export default function BuildingAlreadyExist(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const surveyList = props.surveyList;
  const [selectedSurvey, setSelectedSurvey] = useState();

  const handleClose = () => {
    props.setOpenDialog(false);
    props.setDisableBox(true);
    props.setPropertyId("");
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle 
          id="building-already-exist" 
          variant="h6" 
          sx={{color: theme.palette.primary.main}}
        >
          Survey For This Building Already Exists
        </DialogTitle>
      <DialogContent>
        <SurveyList surveyList={surveyList} setSelectedSurvey={setSelectedSurvey}/>
      </DialogContent>
      <DialogContent >
        <ButtonsInDialog 
          handleClose={handleClose} 
          selectedSurvey={selectedSurvey}
          setOpenDialog={props.setOpenDialog}/>
      </DialogContent>
      
    </Dialog>
  );
}
