import React, {useContext} from "react";
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getIdDuplicateSurvey } from "../../service/survey";
import StoreContext from "../../context";

export const ButtonsInDialog = (props) => {
  const selectedSurvey = props.selectedSurvey;
  const history = useHistory();
  const { authStore } = useContext(StoreContext);
  const { surveyStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;

  const handleCreateNewSurvey = () => {
    props.setOpenDialog(false);
  }

  const handleDuplicateSurveyClick = () => {
    getIdDuplicateSurvey(tokenValue, selectedSurvey.id)
    .then((response) => {
      if(response.data){
        history.push(`/survey/${response.data.id}`);
        surveyStore.getOne('survey/' + response.data.id)
        surveyStore.reset()
      }
    })
  };

  const handleEditClick = () => {
    history.push(`/survey/${selectedSurvey.id}`);
  };

  return (
    <Grid container spacing={1} align= "right">
      <Grid item xs={4}>
      </Grid>
      <Grid item xs={8}>
      <Button 
        variant="contained"
        onClick={handleCreateNewSurvey}>
          Create new blank survey
        </Button>
      </Grid>
      <Grid item xs={4}>
      </Grid>
      <Grid item xs={8}>
        <Button 
          variant="contained"
          disabled = {!(selectedSurvey?.attributes?.status === "draft" && true)}  
          onClick={handleEditClick}
          >
            Edit selected survey
        </Button>
      </Grid>
      <Grid item xs={2}>
      <Button
          variant="text" 
          onClick={props.handleClose} >
          Cancel
        </Button>
      </Grid>
      <Grid item xs={10}>
        <Button 
          variant="contained" 
          disabled = {!(selectedSurvey?.attributes?.status === "complete" && true)}
          onClick={handleDuplicateSurveyClick}
          >
            Clone selected survey then edit it
        </Button>
      </Grid>
    </Grid>
  )
}
