import React, { useContext, useEffect, useState, Fragment } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import StoreContext from '../context';
import { getSurveyReportById } from '../service/survey';
import { AssetTable } from '../components/report/Assets'
import { Building } from '../components/report/Building'
import { CommsRoom } from '../components/report/CommsRoom'
import { Riser } from '../components/report/Riser'
import { EntryPointTable } from '../components/report/EntryPoints'
import { CabinetTable } from '../components/report/Cabinets'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Modal from '@mui/material/Modal';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100%',
  maxHeight: '100%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default observer(({ match }) => {
  const { authStore } = useContext(StoreContext);  
  const tokenValue = authStore.apiToken;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [value, setValue] = useState('building');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = (photourl) => {
    setModalPhoto(photourl);
    setOpenModal(true)
  };
  const handleCloseModal = () => setOpenModal(false);
  const [modalPhoto, setModalPhoto] = useState("");
  
  const { id } = match.params;
  
  useEffect(() => {
    getSurvey();
  }, [])

  const filterCommsRooms = () => {
    if (data) {
      return(
        data.attributes.locationSet.filter( (location) => {
            if (location.cr_is_comms_room) {
              return location
            }
        })
      )
    }
    else {
      return("")
    }
  }

  const getSurvey = () => {
    setLoading(true)
    getSurveyReportById(tokenValue, id)
    .then((response) => {
      if (response.data) {
        setData(response.data)
      }
      setLoading(false)
      setLoaded(true)
    })
  }

  if (loaded && !data) {
    return <Redirect to="/properties" />;
  }
  if (loading || !loaded) {
    return "Loading"
  }
  
  return (
    <Box sx={{m:2}} >
    <Typography variant="h3" sx={{ marginBottom: 5 }}>{data.attributes?.name ? data.attributes.name : ""}</Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Building" value="building" />
            <Tab label="Comms Rooms" value="commsrooms" />
            <Tab label="Risers" value="risers" />
            <Tab label="Entry Points" value="entryPoints" />
            <Tab label="Cabinets" value="cabinets" />
            <Tab label="Assets" value="assets" />
          </TabList>
        </Box>
        <TabPanel value="building">
          <Building
          data={data.attributes ? data.attributes : '' }
          handleOpenModal={handleOpenModal}
          />
          </TabPanel>
        <TabPanel value="commsrooms">
          <CommsRoom
          commsrooms={filterCommsRooms()}
          handleOpenModal={handleOpenModal}
          />
        </TabPanel>
        <TabPanel value="risers">
          <Riser
          risers={data.attributes.riserSet}
          handleOpenModal={handleOpenModal}
          />
        </TabPanel>
        <TabPanel value="entryPoints">
          <EntryPointTable
          rows={data.attributes?.entrypointSet ? data.attributes.entrypointSet : ''}
          columns={[
            {name: "Carrier", id: "carrier", display: true},
            {name: "Location", id: "location", display: true}, 
            {name: "Riser", id: "riser", display: true}, 
            {name: "Riser Floor", id: "riser_floor", display: true}, 
            {name: "Size", id: "entry_size", display: true}, 
            {name: "Free", id: "free_capacity", display: false}, 
            {name: "Diverse", id: "is_over_7_meters_from_another_entry", display: false}
          ]}
          />
        </TabPanel>
        <TabPanel value="cabinets">
          <CabinetTable
          rows={data.attributes?.cabinetSet ? data.attributes.cabinetSet : ''}
          columns={[
            {name: "Name", id:"name", display: true},
            {name: "Location", id: "location", display: true},
            {name: "Riser", id: "riser", display: true},
            {name: "Descripiton", id: "cab_description", display: true},
            {name: "Make", id: "make", display: false},
            {name: "Model", id: "model", display: false},
            {name: "Serial", id: "serial", display: false},
            {name: "QR code", id: "qr_code", display: false},
            {name: "Owner", id: "owner", display: false},
            {name: "Carrier", id: "network_carrier", display: false},
            {name: "Zetta install", id: "is_installed_by_zetta", display: false},
            {name: "Width", id: "cab_width", display: false},
            {name: "Height", id: "cab_height", display: false},
            {name: "Status", id: "status", display: true}
          ]}
          />
        </TabPanel>
        <TabPanel value="assets">
          <AssetTable
          rows={data.attributes?.assetSet ? data.attributes.assetSet : ''}
          columns={[
            {name: "Type", id:"asset_type", display: true},
            {name: "Location", id: "location", display: true},
            {name: "Riser", id: "riser", display: true},
            {name: "Cabinet", id: "in_cabinet", display: true},
            {name: "Make", id: "make", display: true},
            {name: "Model", id: "model", display: true},
            {name: "Serial", id: "serial", display: false},
            {name: "QR code", id: "qr_code", display: false},
            {name: "Owner", id: "owner", display: false},
            {name: "Carrier", id: "network_carrier", display: false},
            {name: "Zetta install", id: "is_installed_by_zetta", display: false},
            {name: "Cab. Pos.", id: "rack_position_in_cabinet", display: false},
            {name: "Size (U)", id: "rack_size", display: false},
            {name: "Ports", id: "net_ports", display: false},
            {name: "MAC", id: "net_mac_address", display: false},
            {name: "Switch", id: "ap_connected_switch", display: false},
            {name: "Speed", id: "ap_speed_test_speed", display: false},
            {name: "Speed test", id: "ap_speed_test_screenshot", display: false},
            {name: "Capacity (kWh)", id: "ups_battery_capacity", display: false},
            {name: "Status", id: "status", display: true}
          ]}
          />
        </TabPanel>
      </TabContext>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
        <img src={(modalPhoto)} style={{ maxHeight: '100%', maxWidth: '100%' }}></img>
        </Box>
      </Modal>
    </Box>
  );
});
