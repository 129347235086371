const yseNoDataList = [ "Unknown", "Yes", "No"]
const yseNoNADataList = [ "Unknown", "N/A", "Yes", "No"]
const yseNoList = ["Yes", "No"]
const statusList = [ "draft", "complete"]
const surveyTypeList = ["Initial", "Completion", "Enhancement"]
const wiredScoreRating = ["Not Certified", "Certified",
  "Silver","Gold","Platinum",
];
const documentTypes = ["Connectivity Guide", "Wayleave", "Floor Plan", "Schematic", "Service Agreement",
  "Test Results", "Handover/LOA", "Drawing", "RAMS", "Fibre Backbone Schematic"
]

const dedicatedSpaceTypesList = ["No", "Copper", "Fiber", "Copper/Fiber"]
const doorLockTypesList = ["Unknown", "None", "Identity-based","Key",
  "Keypad", "Other",
]

const elevationChoice = ["Unknown", "N", "NE", "E", "SE", "S", "SW", "W", "NW"]
const temporaryPowerTypes =["Unknown", "N/A", "No", "Yes"]

const fireProtectionTypes = ["Unknown", "None", "VESDA", "Gaseous fire suppression"]

const entrySizeTypes = ["Unknown", "<50mm", "50mm", "100mm"]
const freeCapacity = ["Unknown", "0%", "25%", "50%", "75%", "100%"]
const isOver7mFromAnotherEntryPoint = ["Unknown", "N/A", "No", "Yes"]

const widthType = ['600', '800']
const mountType = ['Unknown', 'Wall mounted', 'Floor mounted']
const ownerType = ['Landlord', 'Tenant', 'Network Carrier', 'Zetta']
const status = ['N/A',
                'On Order',     // asset has been ordered but not yet arrived
                'In Stock',     // arrived at Zetta not yet configured
                'Configuring',  // being configured at Zetta before installation
                'Standby',      // configured but not yet installed
                'Installed',    // in place but not yet online
                'Active',       // in use
                'Issue',        // requires maintenance
                'Disabled',     // in place but offline having been online
                'Removed',      // no longer on site
]
const alignmentType =["Unknown", "Horizontal", "Vertical"]

const rackMountedAssetType = ['Firewall', 'Network Switch', 'SRS Panel', 'Fibre Patch Panel', 'UPS', 'PDU']

const unmountedAssetType = ['UPS', 'Wireless Access Point', 'Termination Enclosure', 'BFP']

export {
  unmountedAssetType,
  alignmentType,
  yseNoDataList,
  statusList,
  surveyTypeList,
  wiredScoreRating,
  documentTypes,
  dedicatedSpaceTypesList,
  doorLockTypesList,
  elevationChoice,
  temporaryPowerTypes,
  fireProtectionTypes,
  entrySizeTypes,
  freeCapacity,
  isOver7mFromAnotherEntryPoint,
  widthType,
  mountType,
  ownerType,
  status,
  rackMountedAssetType,
  yseNoList,
  yseNoNADataList,
};
