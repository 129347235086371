import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Card, 
  CardContent,
  Typography, 
} from '@mui/material';
import { DropDownBoxWithLabel } from '../DropDownBoxWithLabel';
import { documentTypes } from '../../../models/enumTypeLists';
import { buttonStyleBuildingDoc, cardStyleBuildingDoc, textButtonStyleBuildingDoc } from '../../../style';
import { useSnackbar } from 'notistack';

export const AddBuildingDocument = (props) => {
  const [showForm, setShowForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [documentType, setDocumentType] = useState("");
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const handleClickOpenForm = () => {
    setShowForm((showForm) => !showForm)
  }

  const handleClickUpload = () => {
    if (selectedFile){
      props.addDocument(selectedFile, documentType);
      setShowForm(false);
      setSelectedFile();
    }
    else {
      enqueueSnackbar(
        "Please select file.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  }

  return (
    <Box sx={{marginTop: "20px"}}>
      <Button variant="text" sx={textButtonStyleBuildingDoc} onClick={handleClickOpenForm}>+ Add File</Button>
      {showForm &&
        <Card style={cardStyleBuildingDoc} sx={{marginTop: "20px"}}>
          <CardContent align="left">
            <Typography>Selected file</Typography>
            <Typography> {selectedFile?.name}</Typography>
          </CardContent>
          <CardContent>
            <Button variant="outlined" component="label">
              Select file 
              <input type="file" name="file" hidden onChange={(e) => setSelectedFile(e.target.files[0])}/>          
            </Button>
          </CardContent>
          <DropDownBoxWithLabel 
            boxTitle={"Document type"} 
            dataList={documentTypes}
            defaultValue={""} 
            setValue={setDocumentType}
          />
          <CardContent align="right">
            <Button variant="text" sx={buttonStyleBuildingDoc} onClick={() => setShowForm(false)}>Cancel</Button>
            <Button variant="contained" sx={buttonStyleBuildingDoc} onClick={() => handleClickUpload()} >+ UPLOAD</Button>
          </CardContent>
        </Card>
      }
    </Box>
  )
}
