import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { 
  Box,    
  CircularProgress,    
  Paper,   
  Table,  
  TableBody,    
  TableContainer,
  TableFooter,
} from "@mui/material";
import TableRow from '@mui/material/TableRow';
import StoreContext from "../context";
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { EnhancedTableHead } from "../components/table/EnhancedTableHead";
import { EnhancedTable } from "../components/table/EnhancedTable";
import { SearchPropertyName } from "../components/search/SearchPropertyName";
import theme from "../theme";
import Property from "../models/property";
import { propertiesListSearched } from "../service/createListProperty";

export default observer(() => {
  const { propertyStore } = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [searchedProperties, setSearchedProperties] = useState([]);
  const [startedSearching, setStartedSearching] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0)
  const tokenValue = authStore.apiToken;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = async (event) => {
    if (event.key === 'Enter') {
      if (event.target.value === ""){
		    setPage(0)
        setStartedSearching(false);
      }
      else {
        const response = await propertiesListSearched(event.target.value, tokenValue);
        setPage(0)
        setSearchedProperties(response);
        setStartedSearching(true);
      }
   }
  }

  useEffect(() => {
    setIsLoadingData(true);
      let pageString = page + 1;
      propertyStore.getMany('propertyList/?page%5Bnumber%5D=' + pageString).then((response) => {
        setCount(response.meta.pagination.count)
        setIsLoadingData(false);
      });
    setIsLoadingData(false);
  }, [propertyStore, page]);

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Building Name",
    },
    {
      id: "search-bar",
      numeric: false,
      disablePadding: true,
      label: <SearchPropertyName setSearchedProperties={setSearchedProperties}  handleInputChange={handleInputChange}/>,
    },
  ];
  
  // style
  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.secondary.main,
      fontSize: "20px",
    },  
  }));
  const shadowStyle={
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px"
  }

  return (
    <Box sx={{m:2}} >
      <Paper>
        <TableContainer sx={shadowStyle} >
          <Table style={{ flexGrow: 1 }} id="properties-table">
              <EnhancedTableHead headCells={headCells}></EnhancedTableHead>
              {isLoadingData ?
                <TableBody><TableRow><TableCell  align="right" ><CircularProgress /></TableCell></TableRow></TableBody>
              : 
              <>
                { startedSearching ?
                  <>
                  { (searchedProperties.length === 0) ?
                    <TableBody sx={{ width: '100%' }} >
                      <TableRow>
                        <StyledTableCell>Nothing found.</StyledTableCell>
                        <StyledTableCell align="right" width={40} /> 
                      </TableRow>
                    </TableBody> :
                    <>
                    <EnhancedTable rows={searchedProperties} page={0} rowsPerPage={90}></EnhancedTable> 
                  </>
                  }
                  </> :
                  <>
                  <EnhancedTable rows={propertyStore.findAll(Property).toJSON()} page={page} rowsPerPage={rowsPerPage}></EnhancedTable>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        sx ={{
                          color: theme.palette.primary.main
                        }}
                        rowsPerPageOptions={[10]}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                  </>
                }
                </>
              } 
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
});
