import React from 'react';
import { 
  Card, 
  Grid, 
  Skeleton 
} from '@mui/material';

import { EnhancedCardContent } from './EnhancedCardContent';
export const GridItem = (props) => {
  const gridItems = props.gridItems;
  const isLoadingData = props.isLoadingData;

  const shadowStyle={
    width: 235, 
    height: 275,
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px"
  }
  
  return (
    <>
      {gridItems.map((gridItem) => (
        <Grid item key={gridItem.id} >
          <Card sx={shadowStyle}>
          {isLoadingData  ? (
            <Skeleton variant="rectangular" width={275} height={275} />
            ) : (
              <>
                <EnhancedCardContent item={gridItem}/>
              </>
              
            )}
          </Card>
        </Grid>
      ))}      
    </>
  )
}
