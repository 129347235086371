import React from 'react';
import { 
  TableCell, 
  TableRow 
} from '@mui/material';

export const LabelModalData = (props) => {
  const headerText = props.title;
  const data = props.data;

  // style
  const styleHeader = {
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "250px",
    padding:"1px 0px !important",
    align: "center",

  }
  const styleData ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "100px",
    align: "left",
    padding:"1px 0px !important",
  }

  return (
    <TableRow>
      <TableCell sx={styleHeader}>{headerText}</TableCell>
      <TableCell sx={styleData}>{data}</TableCell>
      <TableCell />
    </TableRow>
  )
}
