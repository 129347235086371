import React from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    Typography 
} from '@mui/material';
import { boxCardStyle } from '../../../style';

export const Connections = () => {
      
  return (
    <Box >
      <Card align="left" style={boxCardStyle}>
        <CardContent>
          <Typography variant="h5">Connections</Typography>
          <Typography variant="body1">Record the connections between assets</Typography>
        </CardContent>
      </Card>
    </Box>
  )
}
