import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleSecondTable, secondarySubtableHeader } from '../../../../style';

export const HeadTable = () => {
       
  return (
    <TableHead >
        <TableRow sx={secondarySubtableHeader}>
          <TableCell align="left" sx={headCellStyleSecondTable}>Core</TableCell>
          <TableCell align="left" sx={headCellStyleSecondTable}>Start Port</TableCell>
          <TableCell align="left" sx={headCellStyleSecondTable}>End Port</TableCell>
          <TableCell align="left" sx={headCellStyleSecondTable}>Loss (dB)</TableCell>
          <TableCell align="left" sx={headCellStyleSecondTable}>Photo</TableCell>
          <TableCell />
        </TableRow>
    </TableHead>
  )
}
