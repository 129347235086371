import React, { useState } from 'react';
import { 
    Box,
    Button,
 } from '@mui/material';
import {  AddWiredScoreRatingDialog } from './AddWiredScoreRatingDialog';
import { textButtonStyleWSC } from '../../../style';

export const AddWiredScoreRatings = (props) => {
  const [showForm, setShowForm] = useState(false);

  const handleClickOpenForm = () => {
    setShowForm((showForm) => !showForm)
  }

  return (
    <Box>
      <Button variant="text" sx={textButtonStyleWSC} onClick={handleClickOpenForm}>+ Add WiredScore rating</Button>
      {showForm &&
        <AddWiredScoreRatingDialog 
          setShowForm={setShowForm}
          setWiredScoreRating={props.setWiredScoreRating}
          addRating={props.addRating} 
          />
      }
    </Box>
  )
}
