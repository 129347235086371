import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextFieldBox } from '../surveyForm.js/TextFieldBox';
import { DropDownBoxWithLabel } from '../surveyForm.js/DropDownBoxWithLabel';
import { mountType, ownerType, status, widthType } from '../../models/enumTypeLists';
import { NumberFieldBox } from '../surveyForm.js/NumberFieldBox';
import { addNewCabinet, getAssetTypeByName, getCabinet, updateCabinet } from '../../service/asset';
import StoreContext from '../../context';
import { RiserFloor } from './riser/RiserFloor';
import { useSnackbar } from 'notistack';
import LinearProgress from '@mui/material/LinearProgress';

export const AddCabinet = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const riserId = props.riserId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const locationId = props.locationId;
  const initState = {
    "cab_is_cabinet": true,
    "status": "N/A",
    "make": "",
    "model": "",
    "cabDescription": "",
    "cabWidth": "",
    "cabHeight": null,
    "cabHowManyFreeRackUnits": null,
    "cabMount": "",
    "owner": "",
    "location": locationId,
    "asset_type": "",
    "riser_floor": "",
    "riser": riserId
  } 
  const [attributes, setAttributes] = useState(initState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const cabinetId = props.cabinetId;
  // for handling error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const loadingAssetType = () => {
    getAssetTypeByName(tokenValue, "Cabinet")
    .then((response) => {
      setAttributes(attributes =>({...attributes, asset_type: response.data[0].id}));
    })
  }
  const loadingCabinet = async () => {
    setLoading(true);
    getCabinet(tokenValue, cabinetId)
    .then((response) => {
      setLoading(false);
      if(response.data) {
        const responseData = response.data.attributes;
        setAttributes(attributes =>({...attributes, cabDescription: responseData.cabDescription}));
        setAttributes(attributes =>({...attributes, cabHeight: responseData?.cabHeight}));
        setAttributes(attributes =>({...attributes, cabWidth: responseData.cabWidth}));
        setAttributes(attributes =>({...attributes, cabHowManyFreeRackUnits: responseData?.cabHowManyFreeRackUnits}));
        setAttributes(attributes =>({...attributes, cabMount: responseData.cabMount}));
        setAttributes(attributes =>({...attributes, owner: responseData.owner}));
        setAttributes(attributes =>({...attributes, status: responseData.status}));
        setAttributes(attributes =>({...attributes, riser_floor: responseData.riserFloor}));
      }
    })
  }

  const editCabinet = async () => {
    const body = {
      "data": {
        "type": "asset",
        "id": cabinetId,
        "attributes": attributes
      }
    }
    updateCabinet(tokenValue, cabinetId, body)
    .then((response) => {
      if(response.data){
        handleClose()
        props.loadingTable()
        enqueueSnackbar(
          "Successfully edited Cabinet.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
      } else if (response.errors) {
        response.errors.forEach((error) => {
          enqueueSnackbar(
            error.detail,
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "center" },
              }
          ); 
        })
      }
    })
  }

  useEffect(() => {
    loadingAssetType()
    cabinetId &&
      loadingCabinet()
  }, [])
  

  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if ( attributes.cabDescription && attributes.cabHeight && !error ){
      if(cabinetId) {
        editCabinet();
      }
      else {
        addCabinet();
      }
    }
    else {
      setSubmitError(true);
      enqueueSnackbar(
        "Please fill in the required fields.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  }

  const setDescription = (value) => {
    setAttributes(attributes =>({...attributes, cabDescription: value}));
  }
  const setHeight = (value) => {
    setAttributes(attributes =>({...attributes, cabHeight: value}));
  }
  const setWidth = (value) => {
    setAttributes(attributes =>({...attributes, cabWidth: value}));
  }
  const setHowManyFreeRackUnit = (value) => {
    setAttributes(attributes =>({...attributes, cabHowManyFreeRackUnits: value}));
  }
  const setMountType = (value) => {
    setAttributes(attributes =>({...attributes, cabMount: value}));
  }
  const setOwner  = (value) => {
    setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setStatus = (value) => {
    setAttributes(attributes =>({...attributes, status: value}));
  }

  const addCabinet = async() => {
    const body = {
      "data": {
        "type": "asset",
        "id": null,
        "attributes": attributes
      }
    }
    addNewCabinet(tokenValue, body)
    .then((response) => {
      if(response.data){
        handleClose()
        props.loadingTable()
        enqueueSnackbar(
          "Successfully added new Cabinet.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingTable();
      } else if (response.errors) {
        response.errors.forEach((error) => {
          enqueueSnackbar(
            error.detail,
              {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "center" },
              }
          ); 
        })
      }
    })
  }
   
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        { loading && <LinearProgress /> }
        {riserId && 
          <RiserFloor 
            attributes={attributes}
            setAttributes={setAttributes}
          />
        }
        <TextFieldBox 
          boxTitle={"Description"}
          setValue={setDescription}
          defaultValue={attributes.cabDescription}
          required={true}
          submitError={submitError}
        />
        <NumberFieldBox 
          boxTitle={"Height (U)"}
          setValue={setHeight}
          defaultValue={attributes.cabHeight}
          required={true}
          error={error}
          setError={setError}
          submitError={submitError}
        />
        <DropDownBoxWithLabel 
          boxTitle={"Width"} 
          dataList={widthType} 
          defaultValue={attributes.cabWidth}
          setValue={setWidth}/>
        <NumberFieldBox 
          boxTitle={"How many free rack units"}
          setValue={setHowManyFreeRackUnit}
          defaultValue={attributes.cabHowManyFreeRackUnits}
          required={false}
          error={error}
          setError={setError}
        />
        <DropDownBoxWithLabel 
          boxTitle={"Mount Type"} 
          dataList={mountType}
          defaultValue={attributes.cabMount} 
          setValue={setMountType}/>
        <DropDownBoxWithLabel 
          boxTitle={"Owner"} 
          dataList={ownerType}
          defaultValue={attributes.owner} 
          setValue={setOwner}/>
        <DropDownBoxWithLabel 
          boxTitle={"Status"} 
          dataList={status} 
          defaultValue={attributes.status}
          setValue={setStatus}
          required={true}/>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave} disabled={loading}>
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
