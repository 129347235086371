import React, { useContext } from "react";
import { Box } from '@mui/system';
import StoreContext from "../../context.js"
import { 
  Avatar, 
  IconButton,
  Menu,
  MenuItem, 
  Tooltip, 
  Typography 
} from '@mui/material';


export const EnhancedMenu = () => {
  const { authStore } = useContext(StoreContext);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ flexGrow: 0 }}>
    <Tooltip title="Open settings">
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar
          alt={authStore.profile.attributes.email}
          src="/static/images/avatar/2.jpg"
        />
      </IconButton>
    </Tooltip>
    <Menu
      sx={{ mt: "45px" }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorElUser)}
      onClose={handleCloseUserMenu}
    >
      <MenuItem key="logout" onClick={authStore.logout}>
        <Typography textAlign="center">Logout</Typography>
      </MenuItem>
    </Menu>
  </Box>
  )
}
