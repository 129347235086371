import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#59267A",
      light: "#8C52B8",
    },
    secondary: {
      main: "#9c9c9c",
    },
    info: {
      main: "#7a7af5",
    },
    warning: {
      main: "#fff06e",
    },
    error: {
      main: "#ed4763",
    },
    neutral: {
      main: "#ffffff"
    },
    black: {
      main: "#000000"
    },
    title: {
      main: "#9C9C9C"
    },
    grayBackground: {
      main:  "#21212114"
    },
    garyButton: {
      main: "rgb(0, 0, 0, 0.6)"
    },
    rowWithButton: {
      main: "#21212114"
    },
    secondTable: {
      main: "#FFF06E"
    },
    selected: {
      main: "#59267a1f"
    }, 
    selectedRow: {
      main: "#DEDEDE"
    },
    gray: {
      light: "#e8e8e8"
    }
  },
  typography:{
    h3: {
      // SummaryNumbers
      color: "#59267A",
      fontStyle: "normal",
      lineHeight: "56px",
      alignContent: "center",
      fontFamily: "Roboto",
      fontWeight: "48px"
    },
    h4: {
      // BuildingName
      fontFamily: "Roboto",
      fontWeight: 400,
      fontSize: "34px",
      lineHeight: "36px",
      textAlign: "center"
    },
  }

  
});
