import React from 'react';
import {
  Typography, 
} from "@mui/material";
import { SummaryNumber } from '../card/SummaryNumber';

export const WiredScoreRatingsElement = (props) => {
  const wiredScores = props.wiredScores;
  const wiredScoresTitle = props.wiredScoresTitle;
  const imageSource = props.imageSource;

  const imageStyle = {
    borderWidth: 5,
    height: "65px",
    width: "65px",
    alignSelf: "stretch"
  }

  const titleStyle = {
    fontSize: 14, 
    lineHeight: "24px",
    letterSpacing: "0.15px",
    fontFamily: "Roboto"
  }

  return (
    <div>
      <img src={imageSource} alt={wiredScoresTitle}
      style={imageStyle}></img>
      <SummaryNumber summaryNumber={wiredScores} />
      <Typography align="center" color="text.secondary.main" sx={titleStyle}>
        {wiredScoresTitle}
      </Typography>
    </div>
  )
}
