import React from 'react';
import { CardContent, Divider, Typography } from '@mui/material';
import theme from '../../theme';

export const CaptionWithDivider = (props) => {
  const captionTitle = props.caption; 

  return (
    <CardContent>
      <Typography variant="caption" 
        sx={{color: theme.palette.primary.main}}>
        {captionTitle}
      </Typography>
      <Divider></Divider>
  </CardContent>
  )
}
