import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react";
import { 
  Box,    
  CircularProgress,    
  Paper,   
  Table,  
  TableBody,    
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CachedIcon from '@mui/icons-material/Cached';
import StoreContext from "../context";
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material/styles';
import theme from "../theme";
import User from "../models/user";
import { toggleUserActive, invite } from "../service/service";
import { useSnackbar } from "notistack";

const UserMenuButton = ( {user} ) => {
  const { authStore, userStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSendInvite = () => {
    setAnchorEl(null);
    setLoading(true);
    invite(tokenValue, "users/" + user.meta.id + "/invite/")
    .then((response) => {
      if(response.data){
        userStore.getOne('users', user.meta.id)
        setLoading(false);
        enqueueSnackbar(
          response.data,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
      }
      if(response.errors){
        setLoading(false);
        enqueueSnackbar(
          response.errors,
          {
            variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        )
      }
    })
  };
  
  const handleToggleActive = async () => {
    setAnchorEl(null);
    const body = {
      "data":{
        "type": "user",
        "id": user.meta.id,
        attributes: {
          "is_active": !user.is_active,
        }
      }
    }
    toggleUserActive(tokenValue, "users/" + user.meta.id + "/", body)
    .then((response) => {
      if(response.data){
        console.log("Success");
      }
      if(response.errors){
        console.log("Fail");
      }
    })
  }
  
  return(
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSendInvite} disabled={user.status === 'Inactive'}>Send Invite</MenuItem>
        <MenuItem onClick={handleToggleActive}>Make {user.status === 'Inactive' ? '' : 'In'}active</MenuItem>
      </Menu>
    </div>
  )
}


export default observer(() => {
  const { userStore } = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const tokenValue = authStore.apiToken;

  useEffect(() => {
    getAllUsers()
  }, []);
  
  const getAllUsers = () => {
    setIsLoadingData(true);
      userStore.getAll('users').then((response) => {
        setIsLoadingData(false);
      });
    setIsLoadingData(false);
  }
  
  const getUserStatus = (user) => {
    if (user.isStaff) {
      return 'Admin'
    };
    if (user.client) {
      return <Tooltip title={user.client.name}><Typography variant="body2">Client</Typography></Tooltip>
    };
    if (user.manager) {
      return 'Manager'
    };
    if (user.partner) {
      return 'Partner'
    };
  }

  const getLastLogin = (user) => {
    if (!user.lastLogin) {
      return '-'
    }
    const d = new Date(user.lastLogin)
    return d.toLocaleString()
  }

  const getStatus = (user) => {
    if (user.status === 'Invited') {
      return <Tooltip title={user.invitedDate}><Typography variant="body2">{user.status}</Typography></Tooltip>
    }
    return user.status
  }
  
  
  return (
    <Box sx={{m:2}} >
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>User Type</TableCell>
            <TableCell>Last Login</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userStore.findAll(User).toJSON().map((user) => (
            <TableRow
              key={user.email}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{user.email}</TableCell>
              <TableCell>{user.firstName} {user.lastName}</TableCell>
              <TableCell>{getUserStatus(user)}</TableCell>
              <TableCell>{getLastLogin(user)}</TableCell>
              <TableCell align="right">{getStatus(user)}</TableCell>
              <TableCell align="right"><UserMenuButton user={user}></UserMenuButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <IconButton onClick={getAllUsers}>
      <CachedIcon />
    </IconButton>
    </Box>
  );
});
