import React, {useState, Fragment, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FirstStep } from './FirstStep';
import { SecondStep } from './SecondStep';
import { ThirdStep } from './ThirdStep';
import { StepLabel } from '@material-ui/core';
import { FourthStep } from './FourthStep';
import { FifthStep } from './FifthStep';
import { DataNotSaved } from '../dialogs/DataNotSaved';

export const HorizontalStepper = (props) => {
  const saveRef = useRef(null);
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [isSavedThirdStep, setIsSavedThirdStep] = useState(true);
  const [openDataNotSavedDialog, setOpenDataNotSavedDialog] = useState(false)
  const [fromFunction, setFromFunction] = useState("")
  const [stepForDialog, setStepForDialog] = useState(-1);
  
  const steps = [
    'Building',
    'Documents',
    'Site Details',
    'Assets',
    'Connections'
  ];
  
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    if (isSavedThirdStep===false && enableSaveButton===true) {
      setOpenDataNotSavedDialog(true);  
      setFromFunction("handleBack");
    }
    else {
      handleBackStep();
    }
  };

  const handleBackStep = () => {
    if( (activeStep+1) === 1 ||  (activeStep+1) ===3) {
      setShowSaveButton(true);
    }
    setIsSaved(true);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const handleStep = (step) => () => {
    if (isSavedThirdStep===false && enableSaveButton===true) {
      setOpenDataNotSavedDialog(true);  
      setFromFunction("handleStep");
      setStepForDialog(step);
    }
    else if (isSaved) {
      setActiveStep(step);
    }
  };


  const handleComplete = () => {
    setIsSavedThirdStep(true);
    saveRef.current.saveDataFunction();
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    //handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleCancel = () => {
    let path = `/surveys`;
    history.push(path);;
  };
  
  const handleNext = () => {
    if (isSavedThirdStep===false && enableSaveButton===true) {
        setOpenDataNotSavedDialog(true);
        setFromFunction("handleNext");  
    }
    else if (isSaved) {
      handleNextStep();
  }
  };

  const handleNextStep = () => {
    const newActiveStep =
    isLastStep() && !allStepsCompleted()
      ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
    setIsSaved(false)
  }
  
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={false}>
            <StepButton color="inherit" onClick={handleStep(index)} ></StepButton>
            <StepLabel align="center"> {label} </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
              {activeStep === 0 &&
                <FirstStep 
                  setSurveyId={props.setSurveyId}
                  surveyId={props.surveyId}
                  ref={saveRef} 
                  setIsSaved={setIsSaved}
                  setEnableSaveButton={setEnableSaveButton}
                  setShowSaveButton={setShowSaveButton}/>
              }
              {activeStep === 1 &&
                <SecondStep 
                  surveyId={props.surveyId}
                  setIsSaved={setIsSaved} 
                  setShowSaveButton={setShowSaveButton}
                  />
              }
              {activeStep === 2 &&
                <ThirdStep 
                  surveyId={props.surveyId}
                  ref={saveRef}
                  setIsSaved={setIsSaved}
                  setShowSaveButton={setShowSaveButton}
                  setEnableSaveButton={setEnableSaveButton}
                  setIsSavedThirdStep={setIsSavedThirdStep}/>
              }
              {activeStep === 3 &&
                <FourthStep 
                  surveyId={props.surveyId}
                  setIsSaved={setIsSaved}
                  setShowSaveButton={setShowSaveButton}/>
              }
              {activeStep === 4 &&
                <FifthStep 
                  surveyId={props.surveyId}
                  ref={saveRef}
                  setIsSaved={setIsSaved}
                  setEnableSaveButton={setEnableSaveButton}
                  setShowSaveButton={setShowSaveButton}/>
              }
              {openDataNotSavedDialog && 
                <DataNotSaved 
                  setClose={setOpenDataNotSavedDialog}
                  fromFunction={fromFunction}
                  handleBackStep={handleBackStep}
                  handleNextStep={handleNextStep}
                  step={stepForDialog}
                  setActiveStep={setActiveStep}
                  setIsSavedThirdStep={setIsSavedThirdStep} />
              }
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2,  bottom: "100px" }}>
              <Box sx={{ flex: '1 1 auto' }}/>
              {activeStep === 0 &&
                <Button
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              }
              {activeStep !== 0 &&
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              }
              {isSaved ? 
                <Button onClick={handleNext} sx={{ mr: 1, align: "right"}} >
                  Next
                </Button>
                :
                <Button onClick={handleNext} sx={{ mr: 1, align: "right"}} color="secondary">
                  Next
                </Button>
              }
              {activeStep !== steps.length &&
                showSaveButton &&
                  <Button onClick={handleComplete} variant='contained' disabled={!enableSaveButton}>
                  {(activeStep+1) === steps.length
                    ? 'Finish'
                    : 'Save'}
                  </Button>
              }
            </Box>
          </Fragment>
        )}
      </div>
    </Box>
  )
}
