import React, {useContext, useEffect, useState} from 'react';
import { 
  Card,
  Box,
} from '@mui/material';
import { CaptionWithDivider } from '../CaptionWithDivider';
import { cardStyleExterior } from '../../../style';
import { PhotoTables } from '../../dialogs/photos/tableWithPhotos/PhotoTables';
import StoreContext from '../../../context';
import { addNewExteriorPhoto, addNewExteriorPhotoTag, deleteExteriorPhoto, getExteriorPhotoBySurveyId, getExteriorPhotoTags, updateExteriorPhoto } from '../../../service/photos';
import { useSnackbar } from 'notistack';

export const ExteriorPhotos = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const [photosList, setPhotosList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const [selectedPhotosList, setSelectedPhotosList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  // for messages
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadingPhotos = () => {
    getExteriorPhotoBySurveyId(tokenValue, surveyId)
    .then((response) => {
      setPhotosList(response.data)
    })
  }

  const loadingPhotoTags = () => {
    getExteriorPhotoTags(tokenValue)
    .then((response) => {
      setTagsList( Array.from(response.data))
    })
    
  }

  useEffect(() => {
    loadingPhotos()
    loadingPhotoTags()
  }, [])
  
  const removeExteriorPhoto = (id) => {
    deleteExteriorPhoto(tokenValue, id)
    .then((response) => {
      if(response.data) {
        enqueueSnackbar(
          "Successfully deleted Exterior photo.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingPhotos();
      }     
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const addExteriorPhoto = () => {
    const formData = new FormData();
    formData.append("survey", surveyId);
    for (const [key, value] of Object.entries(selectedPhotosList)) {
      formData.append("photo_" + key, value);
    }
    for (const [key, value] of Object.entries(selectedTagsList)) {
      formData.append("tags", value);
    }
    addNewExteriorPhoto(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Exterior Photos.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }
  
  const editExteriorPhotoTags = (id, idTagsList) => {
    const formData = new FormData();
    if (idTagsList.length === 0){
      formData.append("tags", null);
    }
    else {
      for (const [key, value] of Object.entries(idTagsList)) {
        formData.append("tags", value); 
      }
    }
    updateExteriorPhoto(tokenValue, id, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully updated Tags for Exterior Photos.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const createExteriorPhotoTag = async (tagName) => {
    const formData = new FormData();
    formData.append('tag', tagName);
    const response = await addNewExteriorPhotoTag(tokenValue, formData)
    return response
  }

  return (
    <Card style={cardStyleExterior}>
      <CaptionWithDivider caption={"Exterior photos"} />
      <Box sx={{marginLeft: "110px",  maxWidth: 'md', marginBottom: "20px"}}>
        <PhotoTables 
          addRowText={"+ Add Exterior Photos"}
          photosList={photosList}
          removePhoto={removeExteriorPhoto}
          uploadPhotos={addExteriorPhoto}
          editPhoto={editExteriorPhotoTags}
          selectedPhotosList={selectedPhotosList}
          setSelectedPhotosList={setSelectedPhotosList}
          tagsList={tagsList}
          setTagsList={setTagsList}
          selectedTagsList={selectedTagsList}
          setSelectedTagsList={setSelectedTagsList}
          createNewPhotoTag={createExteriorPhotoTag}
          />
      </Box>
    </Card>
  )
}
