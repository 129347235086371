import React, { useState, useContext, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';
import StoreContext from "../../../context";
import { getNetworkSwitch } from '../../../service/asset';

export const ConnectedSwitchSelect = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const defaultValue = props.defaultValue;
  const label = props.label;
  const [selectState, setSelectState] = useState();  
  const [dataList, setDataList] = useState([]);  

  const loadingNetworkSwitch = () => {
    getNetworkSwitch(tokenValue)
    .then((response) =>{
      if(response.data){
        setDataList(response.data)
      }
    })
  }
  
  useEffect(() => {
    loadingNetworkSwitch()
  }, [])
  

  const handleChange = (event) => {
    props.setValue(event.target.value);
    setSelectState(event.target.value);
  }

  return (
    <CardContent>
      <TextField
        name={"select-connected-switch"}
        id="outlined-select-connected-switch"
        select
        label={label}
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || defaultValue}
        sx={{minWidth:400}}
      >
        {dataList?.map((d, index) => (
          <MenuItem
            key={index}
            value={d.id}
          >
            {d.attributes.name}
          </MenuItem>
        ))}
      </TextField>
    </CardContent>
  )
}
