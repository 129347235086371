import React, { useState, useEffect } from 'react';
import { Box, CardContent, Checkbox, FormControlLabel, FormGroup, MenuItem, TextField } from '@mui/material';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { alignmentType, ownerType, status } from '../../../models/enumTypeLists';

export const PDUFields = (props) => {
  const [alignment, setAlignment] = useState();
  const attributes = props.attributes;

  useEffect(() => {
    props.loadingAssetType();
  }, [])
  
  const handleAlignment = (value) => {
    setAlignment(value);
    props.setAttributes(attributes =>({...attributes, pduAlignment: value}));
  }
  
  const setNumberOfPlugs = (value) => {
    props.setAttributes(attributes =>({...attributes, pdu_number_of_plugs: value}));
  }
  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setOwner = (value) => {
    props.setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQRCode = (value) => {
    props.setAttributes(attributes =>({...attributes, qrCode: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, isInstalledByZetta: value}));
  }
  
  return (
    <>
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'inline' }}>
          <TextField
            id="outlined-select"
            select
            label={"Alignment"}
            variant="outlined"
            onChange={(e) => handleAlignment(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            value={alignment || attributes.pduAlignment}
            sx={{minWidth:200, marginLeft: "15px"}}
          >
            {alignmentType.map((d, index) => (
              <MenuItem
                key={index}
                value={d}
              >
                {d}
              </MenuItem>
            ))}
          </TextField>
          <TextField 
            id="outlined-basic-floors-lifts" 
            label={"Number of plugs"} 
            value={attributes.pdu_number_of_plugs}
            type="number"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            style = {{width: 200, marginLeft: "26px"}} 
            onChange={(e) => setNumberOfPlugs(e.target.value)}
          />
        </Box>
      </CardContent>
      <CardContent>
        <DropDownBoxWithLabel 
            boxTitle={"Status"} 
            dataList={status}
            defaultValue={attributes.status}
            setValue={setStatus}/>
        <DropDownBoxWithLabel 
            boxTitle={"Owner"} 
            dataList={ownerType}
            defaultValue={attributes.owner}
            setValue={setOwner} />
        <TextFieldBox 
          boxTitle={"QR Code"}
          setValue={setQRCode}
          defaultValue={attributes.qrCode} />
        <CardContent>
          <FormGroup>
            <FormControlLabel 
              control={
              <Checkbox 
                checked={attributes.isInstalledByZetta}
                onChange={(e) => setInstalledByZetta(e.target.checked)}
              />
              } 
              label="Installed by Zetta as part of these works" 
            />
          </FormGroup>
        </CardContent>
      </CardContent>
    </>
  )
}

