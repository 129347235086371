import React from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
} from '@mui/material';
import theme from '../../theme';
import { LabelModalData } from './LabelModalData';
import { DialogModalTitle } from '../card/DialogModalTitle';

export const CarrierListModal = (props) => {
  const rows = props.data // data from ExternalFibre
  
  const closeModal = () => {
    props.setShowModal(false);
  }

  // style
  const styleHeaderCell ={
    fontSize: "16px",
    lineHeight: "26px",
    letterSpacing: "0.5px",
    color: theme.palette.title.main
  }
  return (
    <Dialog open={true}>
      <DialogModalTitle title="Carriers" /> 
      <DialogContent>
        <TableContainer>
          <Table sx={{ minWidth: 350 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={styleHeaderCell}>Carrier</TableCell>
                <TableCell sx={styleHeaderCell}>Cable ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <LabelModalData key={row.id} title={row.network_carrier} data={row.serial}/>
              ))}     
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
