import React, { useState, useEffect } from 'react';
import { 
  CardContent, 
  TextField 
} from '@mui/material';

export const NumberFieldBox = (props) => {
  const [error, setError] = useState(false);


  // watch "submitError" prop, if is true, force a validation of the data
  // this causes setError(true) if the field was empty and also required
  // props.setError sets form level error that can prevent the form being sent with non validated data
  // props.formData is a bool that is true when the value from this component is sent to the backend as formData rather than JSON"
  // formData will accept empty strings as "null" for integer fields, but JSON will not, it expects null.
  useEffect(() => {
    if (props.submitError) {
      validate(props.defaultValue);
    }
  }, [props.submitError])
  
  const validate = (value) => {
    const regex = /\D/;
    if (regex.test(value) || value >= 32766) { 
      setError(true);
      props.setError(true);
      props.setValue(value);
    } else if ( value === "" && !props.formData ) {
      if (props.required) {
        props.setError(true)
        setError(true)
      }
      setError(false)
      props.setError(false)
      props.setValue(null)
    } else {
      setError(false)
      props.setError(false)
      props.setValue(value)
    }
  }
  
  const handleChange = (event) => {
    validate(event.target.value);
  };
  
  return (
    <CardContent>
      <TextField 
        error={error}
        id="outlined-basic-floors-lifts" 
        helperText={error ? 'Required, numeric values only' : ''}
        label={props.boxTitle} 
        value={props.defaultValue || ""}  
        required={props.required}
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        style = {{width: 400}} 
        onChange={handleChange}
      />
    </CardContent>
  )
}

export const NewNumberFieldBox = ({
  attributes,
  setAttributes,
  attributeKey,
  formData,
  boxTitle,
  error,
  setError,
  submitError,
  required,
}) => {
  const [componentError, setComponentError] = useState(false);
  useEffect(() => {
    if (submitError) {
      validate(attributes[attributeKey]);
    }
  }, [submitError])
  
  const validate = (value) => {
    const regex = /\D/;
    if (regex.test(value) || value >= 32766) { 
      setError(true);
      setComponentError(true);
      setAttributes({...attributes, [attributeKey]: value});
    } else if ( value === "" && !formData ) {
      if (required) {
        setError(true)
        setComponentError(true)
      }
      setError(false)
      setComponentError(false)
      // set the value to "null" if we are not using form data (ie we are sending JSON).
      // formData will cast null to "null"
      setAttributes({...attributes, [attributeKey]: null});
    } else {
      setError(false)
      setComponentError(false)
      setAttributes({...attributes, [attributeKey]: value});
    }
  }
  
  const handleChange = (event) => {
    validate(event.target.value);
  };
  
  return (
    <CardContent>
      <TextField 
        error={componentError}
        id="outlined-basic-floors-lifts" 
        helperText={error ? 'Required, numeric values only' : ''}
        label={boxTitle} 
        value={attributes[attributeKey] || ""}  
        required={required}
        type="text"
        InputLabelProps={{
          shrink: true
        }}
        style = {{width: 400}} 
        onChange={handleChange}
      />
    </CardContent>
)
}
