import React from 'react';
import { 
    Box, 
    Card, 
    CardContent, 
    Typography 
} from '@mui/material';
import { boxCardStyle } from '../../../style';

export const Locations = () => {
    
  return (
    <Box >
      <Card align="left" style={boxCardStyle}>
        <CardContent>
          <Typography variant="h5">Locations</Typography>
          <Typography variant="body1">Add a location, then fill in the assets for that location.</Typography>
        </CardContent>
      </Card>
    </Box>
  )
  
}
