import React from 'react';
import { 
  Card, 
  CardContent, 
  Grid, 
  Skeleton, 
  Typography
} from '@mui/material';

import { WiredScoreRatingsElement } from './WiredScoreRatingsElement'

export const WiredScoreRatingsCard = (props) => {
  const dashboardData = props.dashboardData;
  const isLoadingData = props.isLoadingData;


  // style
  const shadowStyleRatings={
    width: 308, 
    height: 275,
    boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px"
  }

  return (
    <Grid item >
      <Card container="true" spacing={4}
        sx={shadowStyleRatings}>
        { isLoadingData ? (
          <Skeleton variant="rectangular" width={308} height={275} />
        ) : (
          <CardContent  sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center"}}>
            <WiredScoreRatingsElement imageSource={"/WS_Gold.png"} wiredScores={dashboardData.count_data.wired_score.gold} wiredScoresTitle={"Gold"} />
            <WiredScoreRatingsElement imageSource={"/WS_Silver.png"} wiredScores={dashboardData.count_data.wired_score.silver} wiredScoresTitle={"Silver"} />
            <WiredScoreRatingsElement imageSource={"/WS_Certified.png"} wiredScores={dashboardData.count_data.wired_score.certified} wiredScoresTitle={"Certified"} />
            <WiredScoreRatingsElement imageSource={"/WS_Platinum.png"} wiredScores={dashboardData.count_data.wired_score.platinum} wiredScoresTitle={"Platinum"} />
            <Typography variant="h6" sx={{marginLeft: "40px", marginTop:"20px"}}>
              Wired Score Ratings
            </Typography>
          </CardContent>
        )}
      </Card>
    </Grid>
  )
}
