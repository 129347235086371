import React from 'react';
import { 
  DialogTitle, 
  Typography 
} from '@mui/material';
import theme from '../../theme';

export const DialogModalTitle = (props) => {

  // style
  const styleTitle = {
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontSize: "20px",
    lineHeight: "24px",
    align: "left",
    fontWeight: "bold"
  }
    
  return (
    <DialogTitle> 
      <Typography sx={styleTitle}>{props.title}</Typography>
    </DialogTitle>
  )
}
