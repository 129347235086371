import React from 'react';
import MenuItem from "@mui/material/MenuItem";


export const LockSurvey = (props) => {
  const survey = props.survey;
  const surveyId = props.surveyId;

  return (
    <MenuItem
      key={"lock"}
      disabled = {(survey.status !== "complete" ||  survey.status !== "draft") ? false : true}
      onClick={() => {props.changeStatusSurvey(surveyId)}}
      >
      {survey.status === "draft" ? "Lock" : "Unlock"  }
    </MenuItem>
  )
}
