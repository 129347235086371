import React, { useEffect } from 'react';
import { CardContent, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { MakeModel } from './MakeModel';
import { PositionInCabSizePorts } from './PositionInCabSizePorts';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { ownerType, status } from '../../../models/enumTypeLists';

export const SRSPanelFields = (props) => {
  const attributes = props.attributes;

  useEffect(() => {
    props.loadingAssetType();
  }, [])
  
  const setMake = (value) => {
    props.setAttributes(attributes =>({...attributes, make: value}));
  }
  const setModel = (value) => {
    props.setAttributes(attributes =>({...attributes, model: value}));
  }
  const setPositionInCabinet = (value) => {
    props.setAttributes(attributes =>({...attributes, rackPositionInCabinet: value}));
  }
  const setSize = (value) => {
    props.setAttributes(attributes =>({...attributes, rackSize: value}));
  }
  const setPorts = (value) => {
    props.setAttributes(attributes =>({...attributes, netPorts: value}));
  }
  const setSerial = (value) => {
    props.setAttributes(attributes =>({...attributes, serial: value}));
  }
  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setOwner = (value) => {
    props.setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQRCode = (value) => {
    props.setAttributes(attributes =>({...attributes, qrCode: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, isInstalledByZetta: value}));
  }

  return (
    <>
      <MakeModel 
        setMake={setMake}
        setModel={setModel}
        attributes={attributes}
      />
      <PositionInCabSizePorts
        setPositionInCabinet={setPositionInCabinet}
        setSize={setSize}
        setPorts={setPorts}
        attributes={attributes}
      />
      <CardContent>
        <TextFieldBox 
          boxTitle={"Serial Number"}
          setValue={setSerial} 
          defaultValue={attributes.serial}
        />
        <DropDownBoxWithLabel 
            boxTitle={"Status"} 
            dataList={status}
            defaultValue={attributes.status}
            setValue={setStatus}/>
        <DropDownBoxWithLabel 
            boxTitle={"Owner"} 
            dataList={ownerType}
            defaultValue={attributes.owner}
            setValue={setOwner} />
        <TextFieldBox 
          boxTitle={"QR Code"}
          setValue={setQRCode} 
          defaultValue={attributes.qrCode}/>
        <CardContent>
          <FormGroup>
            <FormControlLabel 
              control={
              <Checkbox 
                checked={attributes.isInstalledByZetta}
                onChange={(e) => setInstalledByZetta(e.target.checked)}
              />} 
              label="Installed by Zetta as part of these works" 
            />
          </FormGroup>
        </CardContent>
      </CardContent>
    </>
  )
}
