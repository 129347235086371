import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';

export const AssetSelect = (props) => {
    const dataList = props.dataList;
    const defaultValue = props.defaultValue;
    const label = props.label;
    const [selectState, setSelectState] = useState();  
    
    const handleChange = (event) => {
      props.setValue(event.target.value);
      setSelectState(event.target.value);
    }
  
    return (
      <CardContent>
        <TextField
          id="outlined-select-nearby-network"
          select
          required={props.required}
          label={label}
          variant="outlined"
          onChange={handleChange}
          InputLabelProps={{
            shrink: true
          }}
          value={selectState || defaultValue}
          sx={{minWidth:400}}
          SelectProps={{
              MenuProps: {
                sx: { maxHeight: '50%', marginLeft: "20px"}
              }
            }}
        >
          {dataList?.map((d, index) => (
            <MenuItem
              key={d.id}
              value={d.id}
            >
              {d?.attributes?.name}
            </MenuItem>
          ))}
        </TextField>
      </CardContent>
    )
}
