
import React, { useState } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';
import { RisersModal } from '../propertyCardModal/RisersModal';

export const Risers = (props) => {
  const [showModal, setShowModal] = useState(false)
  const riserSet = props.property.lastSurvey.riserSet;

  const cardInfo =
  {
    id: "risers",
    imageSource: "/Risers.png",
    summaryNumber: riserSet.length,
    title: "Risers",
    subtitle: riserSet?.filter((riser) =>  riser.is_over_7_meters_from_another_riser === "Yes").length + " Diverse"
  }
  
  // Press the button to show form
  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <Grid item>
      {( riserSet.length === 0) ?
        <></> :
        showModal && <RisersModal riserSet={riserSet} setShowModal={setShowModal}/>
      }
      <Card sx={props.shadowStyle} onClick={handleClick}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
  )
}
