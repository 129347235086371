import React, { useState, Fragment } from 'react';
import { 
  Typography,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import theme from '../../../theme';
import { AutocompleteTags } from './AutocompleteTags';

export const Upload = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const dialogTitle = props.dialogTitle;
  const selectedPhotosList = props.selectedPhotosList;

  const handleClose = () => {
    props.closeDialog(false);
    props.setSelectedPhotosList([]);
    props.setSelectedTagsList([]);
  };

  const handleSelect = (value) => {
    setError(false);
    Array.from(value).map((item) => {
      props.setSelectedPhotosList(selectedPhotosList => [...selectedPhotosList, item])
    })
  } 

  const handleUpload = () => {
    if (selectedPhotosList.length === 0){
      setError(true);
    }
    else{
      props.uploadPhotos();
      handleClose();
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-photo-dialog-title"
    >
      <DialogTitle id="upload-photo-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' >
          Selected files:
        </Typography>
        {error &&
          <Typography sx={{color: theme.palette.error.main}}>
            Please select files.
          </Typography>
        }
        { Array.from(selectedPhotosList).map((file, index) => 
          <Fragment key={index}>
            {file.name} ,
          </Fragment>
        )}
        <Stack spacing={3} sx={{ width: 269, marginTop: "20px" }}>
          <AutocompleteTags
            error=""
            setLoading={setLoading}
            tagsList={props.tagsList}
            setTagsList={props.setTagsList}
            selectedTagsList={props.selectedTagsList}
            setSelectedTagsList={props.setSelectedTagsList}
            defaultTagList={[]}
            createNewPhotoTag={props.createNewPhotoTag}
          />
        </Stack>
        <Button variant="outlined" component="label" sx={{marginTop: "20px"}}>
          Select file 
          <input 
            id="file-upload"
            type="file" 
            hidden 
            multiple 
            onChange={(e) => handleSelect(e.target.files)}/>          
        </Button>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleUpload} disabled={loading}>
          + UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  )
}
