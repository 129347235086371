import { observable, action, makeObservable } from "mobx";
import axios, { AxiosInstance } from "axios";

import { makePersistable } from "mobx-persist-store";
import { config as jsonApiConfig } from "@datx/jsonapi";
import config from "../config";
import RootStore from "./rootStore";

export default class AuthStore {
  httpClient: AxiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
  });

  rootStore: RootStore;

  @observable apiToken = "";

  @observable isLoggedIn: boolean | null = null;

  @observable loading = false;

  @observable profile = {
    id: null,
    attributes: {
      email: null,
    },
  };

  @observable error = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.httpClient = axios.create({
      baseURL: `${config.apiBaseUrl}/api/`,
      withCredentials: true,
    });

    makeObservable(this);
    makePersistable(this, {
      name: "authStore",
      properties: ["apiToken"],
      storage: window.localStorage,
    }).then(
      action(() => {
        if (this.apiToken) {
          this.setToken(this.apiToken);
          this.loadProfile();
        } else {
          this.isLoggedIn = false;
        }
      })
    );
  }

  @action.bound async loadProfile(): Promise<boolean> {
    return this.httpClient
      .get(`${config.apiBaseUrl}/api/users/me/`)
      .then((response) => {
        this.profile = response.data.data;
        this.isLoggedIn = true;
        jsonApiConfig.defaultFetchOptions = {
          credentials: "same-origin",
          headers: {
            ...jsonApiConfig.defaultFetchOptions.headers,
            Authorization: `Token ${this.apiToken}`,
          },
        };
        return true;
      })
      .catch(() => {
        this.logout();
        return false;
      });
  }

  @action.bound
  async loginEmailPassword(email: string, password: string): Promise<boolean> {
    return this.httpClient
      .post(
        `${config.apiBaseUrl}/auth/obtain-auth-token/`,
        {
          username: email,
          password,
        },
        { withCredentials: false }
      )
      .then((response) => {
        localStorage.setItem("role", response.data.user_type)
        this.setToken(response.data.token);
        return this.loadProfile();
      })
      .catch((error) => {
        if (!error.response) {
          throw new Error("Could not contact login server");
        }
        switch (error.response.status) {
          case 400:
            // Incorrect e-mail/password combination
            return false;
          default:
            throw error;
        }
      });
  }

  @action.bound setToken(token: string): void {
    this.apiToken = token;
    this.httpClient.interceptors.request.use(
      (requestConfig) => {
        if (this.apiToken) {
          // eslint-disable-next-line no-param-reassign
          requestConfig.headers = {
            ...requestConfig.headers,
            Authorization: `Token ${this.apiToken}`,
          };
        }
        return requestConfig;
      },
      (error) => Promise.reject(error)
    );
    jsonApiConfig.defaultFetchOptions = {
      credentials: "same-origin",
      headers: {
        ...jsonApiConfig.defaultFetchOptions.headers,
        Authorization: `Token ${this.apiToken}`,
      },
    };
  }

  @action.bound logout(): void {
    localStorage.clear()
    this.apiToken = "";
    this.isLoggedIn = false;
    this.profile = {
      id: null,
      attributes: {
        email: null,
      },
    };
    this.loading = false;
    this.rootStore.reset();
  }

  get loadedProfile(): boolean {
    return !!this.profile.id;
  }

  get hasProfile(): boolean {
    return !!this.profile.id;
  }
}
