import * as Sentry from "@sentry/react";
import React from "react";
import { ThemeProvider } from "@mui/material";

import "./App.css";
import { SnackbarProvider } from "notistack";
import LoginContainer from "./LoginContainer";
import RootStore from "./stores/rootStore";
import theme from "./theme";

import StoreContext from "./context";
import config from "./config";

Sentry.init(config.sentry);

const rootStore = new RootStore();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3} preventDuplicate>
        <StoreContext.Provider value={rootStore}>
          <LoginContainer authStore={rootStore.authStore} />
        </StoreContext.Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
