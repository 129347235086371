import React, { useState, useEffect } from "react";
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {BasicTable} from './BasicTable';
import {ColumnsMenu} from './ColumnsMenu';
import Box from '@mui/material/Box';


const AssetTableControls = ( {assets, columns, setColumns, setFiltered} ) => {
  
  const [filters, setFilters] = useState({
    locations: true,
    risers: true,
    cabinets: true,
    assetType: "All",
    status: "All",
  });
  
  const assetTypeList = [...new Set(assets.map(asset => asset.asset_type))]
  const statusList = [...new Set(assets.map(asset => asset.status))]
  
  useEffect(() => {
    filterAssets();
  }, [filters])
  
  const filterAssets = () => {
    setFiltered(
      assets
      .filter(asset => !asset.location || asset.in_cabinet || filters.locations)
      .filter(asset => !asset.riser || filters.risers)
      .filter(asset => !asset.in_cabinet || filters.cabinets)
      .filter(asset => asset.asset_type === filters.assetType || filters.assetType === "All")
      .filter(asset => asset.status === filters.status || filters.status === "All")
    )
  }
  
  
  const toggleFilter = (event) => {
    setFilters(
      {
        ...filters,
        [event.target.name]: event.target.checked,
      }
    );
  };
  
  const selectFilter = (event) => {
    setFilters(
      {
        ...filters,
        [event.target.name]: event.target.value,
      }
    );
  };

  return(
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup row>
        <FormControlLabel control={
          <Switch
            checked={filters.locations}
            name="locations"
            onChange={toggleFilter}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        } label="Locations" />
        <FormControlLabel control={
          <Switch
            checked={filters.risers}
            name="risers"
            onChange={toggleFilter}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        } label="Risers" />
        <FormControlLabel control={
          <Switch
            checked={filters.cabinets}
            name="cabinets"
            onChange={toggleFilter}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        } label="Cabinets" />
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="asset-type-select-label">Asset Type</InputLabel>
          <Select
            labelId="asset-type-select-label"
            id="asset-type-select"
            autoWidth
            value={filters.assetType}
            name="assetType"
            label="Asset Type"
            onChange={selectFilter}
          >
          <MenuItem value="All" key={"all"}>All</MenuItem>
          {
            assetTypeList.map( (x) => { 
              return(<MenuItem value={x} key={x}>{x}</MenuItem>) 
            })
          }
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="status-select-label">Status</InputLabel>
          <Select
            labelId="status-select-label"
            id="status-select"
            autoWidth
            value={filters.status}
            name="status"
            label="Status"
            onChange={selectFilter}
          >
          <MenuItem value="All" key={"all"}>All</MenuItem>
          {
            statusList.map( (x) => { 
              return(<MenuItem value={x} key={x}>{x}</MenuItem>) 
            })
          }
          </Select>
        </FormControl>

      </FormGroup>
      <ColumnsMenu columns={columns} setColumns={setColumns} />
    </Box>
  )

}


export const AssetTable = ( props ) => {
  const [filtered, setFiltered] = useState(props.rows);
  const [columns, setColumns] = useState(props.columns);

  return (
    <>
    <AssetTableControls assets={props.rows} columns={columns} setColumns={setColumns} setFiltered={setFiltered}/>
    <BasicTable
      columns={columns.filter(column => column.display)}
      filtered={filtered}
    />
    </>
  )
}

