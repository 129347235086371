import React, { useState } from 'react';
import theme from '../../theme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SubtableDropdownEntryPoint } from './SubtableDropdownEntryPoint';
import { TableFooter, TablePagination } from '@mui/material';
import { EnhancedEmptyRow } from './EnhancedEmptyRow';


export const TableEntryPoints = (props) => {
  const [page, setPage] = useState(0);
  const rowsPerPage  =(5);
  const rows = props.rows;


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  // style
  const styleHeaderCell ={
    fontSize: "16px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    color: theme.palette.title.main
  }


  return (
    <TableContainer sx={{ minWidth: 550, height: "550px"}} >
      <Table id="entry-points-table">
        <TableHead>
          <TableRow sx={styleHeaderCell}>
            <TableCell>Size</TableCell>
            <TableCell align="right">Free Capacity</TableCell>
            <TableCell align="right">Carrier</TableCell>
            <TableCell align="right">Diverse</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <SubtableDropdownEntryPoint key={row.id} row={row} />
          ))}
          <EnhancedEmptyRow page={page} rowsPerPage={5} rows={rows}/>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination 
              sx ={{
                color: theme.palette.primary.main
              }}
              rowsPerPageOptions={[]}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              
            />
          </TableRow>
      </TableFooter>
      </Table>
    </TableContainer>
  )
}
