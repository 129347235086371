import React, { useState } from 'react'
import { 
  Box, 
  Grid 
} from '@mui/material';
import { HorizontalStepper } from './stepper/HorizontalStepper';

export const AddNewSurveyForm = () => {
  const [surveyId, setSurveyId] = useState();

  return (
    <Box sx={{m:2}} >
      <Grid item>
        <HorizontalStepper
          surveyId={surveyId}
          setSurveyId={setSurveyId}
        />
      </Grid>
    </Box>
  );
}
