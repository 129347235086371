import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { 
  Box,    
  Button,     
  Paper,
  Stack,
  Table,
  TableContainer,
} from "@mui/material";
import StoreContext from "../context";
import { AdminTable } from "../components/survey/AdminTable";
import Survey from "../models/survey";
import Property from "../models/property";
import { ClientTable } from "../components/survey/ClientTable";
import { deleteSurvey, updateStatusSurvey } from "../service/survey";
import { buttonStyleSurveyPge, shadowStyleSurveyPage } from "../style";

export default observer(({ match }) => {
  const history = useHistory();
  const { authStore } = useContext(StoreContext);
  const { surveyStore } = useContext(StoreContext);
  const { propertyStore } = useContext(StoreContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(0)
  const tokenValue = authStore.apiToken;

  const successfulMessage = () => {
    enqueueSnackbar(
      "Successfully deleted Survey.",
      {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }
    );
  }

  const errorMessage = () => {
    enqueueSnackbar(
      "Can not delete Survey!",
      {
        variant: "error",
        autoHideDuration: 3000,
        anchorOrigin: { vertical: "top", horizontal: "center" },
      }
    );
  }

  const removeSurvey = async(id) => {
    const response = await deleteSurvey(tokenValue, id)
      if (response.data === null){
        surveyStore.removeOne(Survey, id)
        if((count-1) === 10){
          setPage(0);
        }
        setCount(count => count -1);
        successfulMessage();
    }
    else
      errorMessage();
  }

  const changeStatusSurvey = async(id) => {
    setIsLoadingData(true);
    updateStatusSurvey(tokenValue, id)
    .then((response) => {
      if (response.data){
        enqueueSnackbar(
          "Successfully changed Survey Status.",
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
        surveyStore.reset();
        propertyStore.reset();
        setCount(0);
        setIsLoadingData(false);
      }
      else {
        setIsLoadingData(false);
        enqueueSnackbar(
          "Can not change Survey Status!",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
    })
  }

  useEffect(() => {
    setIsLoadingData(true);
      let pageString = page + 1;
      surveyStore.getMany('survey/?page%5Bnumber%5D=' + pageString)
      .then((response) => {
        setCount(response.meta.pagination.count)
      }).then(()=>{
        setIsLoadingData(false);
      })
      .catch((e)=> {
        enqueueSnackbar(
          "There was an error contacting the server. Please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      })
  }, [surveyStore, page, count]);

  const handleNewSurveyClick = (param, event) => {
    history.push(`/createNewSurvey`);
  };

  return (
    <Box sx={{m:2}} >
      <Paper>
        <TableContainer sx={shadowStyleSurveyPage} >
          <Table style={{ flexGrow: 1 }} id="properties-table">
            {localStorage.role === "staff" &&
              <AdminTable isLoadingData={isLoadingData} surveyList={surveyStore.findAll(Survey).toJSON()}
                page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} count={count}
                removeSurvey={removeSurvey} changeStatusSurvey={changeStatusSurvey}
                /> 
            }
            {localStorage.role === "client" &&
              <ClientTable isLoadingData={isLoadingData}  surveyList={surveyStore.findAll(Survey).toJSON()} 
                page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} count={count}/> 
            }
          </Table>
        </TableContainer>
      </Paper>
      {localStorage.role === "staff" ?
      <Stack sx={{marginTop: "40px"}} spacing={2} direction="row">
        <Button size="large" sx={buttonStyleSurveyPge} variant="contained" onClick={handleNewSurveyClick}>+ Add survey</Button>
      </Stack> :
      <></>
      }    
    </Box>
  );
  
});
