import React, { useState } from 'react';
import { 
  Box,
  Card, 
  CardContent, 
  IconButton, 
  Typography
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import theme from '../../../theme';
import ConformDialog from '../../dialogs/ConformDelete';
import { cardStyleWSCShadow, cardSxWSC, imageStyleWSC, separatorRatingWSC } from '../../../style';


export const WiredScoreRatings = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dataList = props.dataList;
  
  const handleClickOpen = (item) => {
    setOpenDialog(true);
  };

  return (
    <Box sx={{marginTop: "20px"}}>
      <Typography variant="h5">WiredScore Ratings</Typography>  
      <Card style={cardStyleWSCShadow} sx={cardSxWSC}>
        {dataList.map((item) =>
        <Box  key={item.id} sx={{display: "flex", flexDirection: "inline",}}> 
          <CardContent >
            <img src={(item.attributes.wiredscoreRating)!= null ? ("/WS_" + item.attributes.wiredscoreRating +".png") : ""} 
            alt={item.wiredscoreRating}
            style={imageStyleWSC}></img>
          </CardContent>
          <Box style={separatorRatingWSC} sx={{display: "flex", flexDirection: "inline", marginBottom: "10px"}}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography >
                {item.attributes.wiredscoreRating}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                {item.attributes.validUntil}
              </Typography>
            </CardContent>
            <CardContent>
              <IconButton onClick={() => handleClickOpen(item)} sx={{marginTop: "10px"}}>
                <CancelRoundedIcon sx={{color: theme.palette.primary.main}}/>
              </IconButton>
            </CardContent>
          </Box>
          {openDialog &&  
            <ConformDialog setOpenDialog={setOpenDialog} title="Rating" deleteFunction={() => props.removeRating(item.id)}/>
          }
        </Box>
        )}
      </Card>
    </Box>
  )
}
