import React from 'react'
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox'

export const RiserFloor = (props) => {
  const attributes = props.attributes;

  const setRiserFloor = (value) => {
    props.setAttributes(attributes =>({...attributes, riser_floor: value}));
  }

  return (
    <TextFieldBox 
      boxTitle={"Floor"}
      setValue={setRiserFloor}
      defaultValue={attributes.riser_floor}/>
  )
}
