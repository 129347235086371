import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';
import { Locations } from '../surveyForm.js/assets/Locations';
import { LocationTable } from '../surveyForm.js/assets/LocationTable';
import { deleteAsset, deleteCabinet, deleteEntryPoint, deleteLocation, deleteUnmountedAsset, getLocationsBySurveyId } from '../../service/asset';
import StoreContext from '../../context';
import { useSnackbar } from 'notistack';
import { Risers } from '../surveyForm.js/assets/risers/Risers';
import { RiserTable } from '../surveyForm.js/assets/risers/RiserTable';
import { deleteRiser, getRisersBySurveyId } from '../../service/risers';

export const FourthStep = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const [locationList, setLocationList] = useState([]);
  const [riserList, setRiserList] = useState([]);
  // for handling error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadingLocations = async () => {
    getLocationsBySurveyId(tokenValue, surveyId)
    .then((response) => {
      if(response.data){
        setLocationList(response.data);
        closeSnackbar();
      }
      if(response.error){
        enqueueSnackbar(
          response.error.details,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });    
  }

  const removeLocation= (id) => {
    deleteLocation(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Location.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingLocations();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  const removeEntryPoint = (id) => {
    deleteEntryPoint(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Entry Point.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingLocations();
        loadingRisers();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  const removeUnmountedAsset = (id) => {
    deleteUnmountedAsset(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Unmounted Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingLocations();
        loadingRisers();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  const removeCabinet = (id) => {
    deleteCabinet(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Cabinet.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingLocations();
        loadingRisers();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  const removeAsset = (id) => {
    deleteAsset(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Asset.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingLocations();
        loadingRisers();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  const loadingRisers = async () => {
    getRisersBySurveyId(tokenValue, surveyId)
    .then((response) => {
      if(response.data){
        setRiserList(response.data)
        closeSnackbar();
      }
      if(response.error){
        enqueueSnackbar(
          response.error.details,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });  
  }

  const removeRiser = (id) => {
    deleteRiser(tokenValue, id)
    .then((response)=>{
      if(response.data){
        enqueueSnackbar(
          "Successfully deleted Riser.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        loadingRisers();
      }
    })
    .catch(()=>{
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    })
  }

  useEffect(() => {
    props.setShowSaveButton(false);
    props.setIsSaved(true)
    if (surveyId) {
      loadingLocations();
      loadingRisers();
    }
  }, [])

  //style
  const cardStyle = {
    border: "none",
    boxShadow: "none", 
    minWidth: 804, 
    maxWidth: 1104,
    background: theme.palette.neutral.main
  }

  return (
    <Box align="center" sx={{ marginTop:"40px", marginLeft: "10px"  }} style={cardStyle}>
      <Locations />
      <LocationTable 
        locationList={locationList}
        removeLocation={removeLocation}
        removeEntryPoint={removeEntryPoint}
        removeUnmountedAsset={removeUnmountedAsset}
        removeCabinet={removeCabinet}
        removeAsset={removeAsset}
        surveyId={surveyId}
        loadingLocations={loadingLocations}
        loadingRisers={loadingRisers}
        />
      <Risers />
      <RiserTable
        riserList={riserList}
        surveyId={surveyId}
        removeRiser={removeRiser}
        loadingRisers={loadingRisers}
        loadingLocations={loadingLocations}
        removeEntryPoint={removeEntryPoint}
        removeUnmountedAsset={removeUnmountedAsset}
        removeCabinet={removeCabinet}
        removeAsset={removeAsset}
        />
    </Box>
  )
}
