import React, { Fragment, useState, useContext } from 'react';
import { 
  Collapse, 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtonsWithPhoto, cellStyleLocationTableDropdownButton, rowBackground } from '../../../../style'
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ConformDialog from '../../../dialogs/ConformDelete';
import { RowWithButtons } from '../RowWithButtons';
import { RowWithEntryPointTable } from '../RowWithEntryPointTable';
import { RowWithCabinets } from '../RowWithCabinets';
import { RowWithUnmountedAssetTable } from '../RowWithUnmountedAssetTable';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { DialogWithTable } from '../../../dialogs/photos/DialogWithTable';
import StoreContext from '../../../../context';
import { addNewRiserPhoto, addNewRiserPhotoTag, deleteRiserPhoto, getPhotosByRiserId, getRiserPhotoTags, updateRiserPhoto } from '../../../../service/photos';
import { useSnackbar } from 'notistack';


export const RiserWithSubtable = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [listRiserPhotos, setListRiserPhotos] = useState([])
  const [tagsList, setTagsList] = useState([]);
  const [selectedPhotosList, setSelectedPhotosList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);
  const row = props.row;
  const surveyId = props.surveyId;
  const riserId = props.riserId;

  // for messages
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notDeletedEntryPoints = row.attributes?.entryPointSet.filter((entryPoint) => entryPoint.is_deleted === false).length
  const notDeletedCabinets = row.attributes?.cabinetSet.filter((cabinet) => cabinet.is_deleted === false).length
  const notDeletedUnmountedAsset =  row.attributes?.unmountedAssetSet.filter((asset) => asset.is_deleted === false).length
  
  const handleEdit = () => {
    props.setOpenEditDialog(true)
    props.setRiserId(riserId)
  }

  const loadingRiserPhotos = () => {
    getPhotosByRiserId(tokenValue, row.id)
    .then((response) =>{
      setListRiserPhotos(response.data)
    })
  }

  const loadingPhotoTags = () => {
    getRiserPhotoTags(tokenValue)
    .then((response) => {
      if(response.data) {
        setTagsList(Array.from(response.data))
      }
    })
  }
  
  const removeRiserPhoto = (id) => {
    deleteRiserPhoto(tokenValue, id)
    .then((response) => {
      if(response.data) {
        loadingRiserPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const addRiserPhoto = () => {
    const formData = new FormData();
    formData.append("riser", riserId);
    for (const [key, value] of Object.entries(selectedPhotosList)) {
      formData.append("photo_" + key, value);
    }
    for (const [key, value] of Object.entries(selectedTagsList)) {
      formData.append("tags", value);
    }
    addNewRiserPhoto(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Riser Photos.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingRiserPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }
  
  const createRiserPhotoTag = async (tagName) => {
    const formData = new FormData();
    formData.append('tag', tagName);
    const response = await addNewRiserPhotoTag(tokenValue, formData)
    return response
  }

  const editRiserPhoto = (id, idTagsList) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(idTagsList)) {
      formData.append("tags", value);
    }
    updateRiserPhoto(tokenValue, id, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully updated Tags for Riser Photo.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingRiserPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell sx={cellStyleLocationTableDropdownButton}>
          <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.attributes.location.name}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.attributes.location2.name}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.attributes.location.elevation}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.attributes.description}</TableCell>
          <TableCell sx={cellStyleLocationTableButtonsWithPhoto}>
            <IconButton onClick={() => setOpenPhotoDialog(true)}>
              <PhotoCameraIcon></PhotoCameraIcon>
            </IconButton>
            <IconButton onClick={() => handleEdit()}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      <TableRow sx={rowBackground}>
        {/* Buttons: +ENTRY_PONT +CABINET +ACCESS_POINT   +TERMINATION_ENCLOSURE*/}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
              {notDeletedEntryPoints !== 0 &&
                <RowWithEntryPointTable 
                  entryPointList={row.attributes.entryPointSet}   
                  deleteEntryPoint={props.removeEntryPoint}
                  surveyId={surveyId}
                  loadingTable={props.loadingRisers}
                  riserId={row.id}
                />
              }
              {notDeletedUnmountedAsset !== 0 &&
                <RowWithUnmountedAssetTable 
                  unmountedAssetList={row.attributes.unmountedAssetSet}
                  deleteUnmountedAsset={props.removeUnmountedAsset}
                  riserId={riserId}
                  locationId={""}
                  surveyId={surveyId}
                  loadingTable={props.loadingRisers}
                />
              }
              {notDeletedCabinets !== 0 &&
              <RowWithCabinets 
                cabinetList={row.attributes.cabinetSet}
                deleteCabinet={props.removeCabinet}
                deleteAsset={props.removeAsset}
                surveyId={surveyId}
                loadingTable={props.loadingRisers}
                riserId={row.id}
              />
            }
              <RowWithButtons
                surveyId={surveyId}
                riserId={row.id}
                loadingTable={props.loadingRisers}
               />
          </Collapse>
        </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Riser"} 
          deleteFunction={() => props.removeRiser(row.id)}/>
      }
      {openPhotoDialog && 
        <DialogWithTable
          dialogTitle={row.attributes.location.description + " photos"}
          addRowText={"+ Add " + row.attributes.location.description + " Photos"}
          closeDialog={setOpenPhotoDialog}
          photoList={listRiserPhotos}
          setPhotoList={setListRiserPhotos}
          getPhotoList={loadingRiserPhotos}
          removePhoto={removeRiserPhoto}
          tagsList={tagsList}
          setTagsList={setTagsList}
          getTagsList={loadingPhotoTags}
          selectedPhotosList={selectedPhotosList}
          setSelectedPhotosList={setSelectedPhotosList}
          uploadPhotos={addRiserPhoto}
          selectedTagsList={selectedTagsList}
          setSelectedTagsList={setSelectedTagsList}
          editPhoto={editRiserPhoto}
          createNewPhotoTag={createRiserPhotoTag}
        />
      }
    </Fragment>
  )
}
