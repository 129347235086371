import { get } from "./service";


export const getAllPropertyNameId = async (tokenValue)=> {
  const apiUrl = "buildingNameId/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getAllPropertyForMap= async (tokenValue)=> {
  const apiUrl = "propertyListMaps/";
  const response = await get(tokenValue, apiUrl)
  return response
}