import React, { useState,useEffect } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';
import { CarrierListModal } from '../propertyCardModal/CarrierListModal';

export const CarrierList = (props) => {
  /*
  maybe we need change props because we need more details in ModelForm (Dialog)
  */
  const [showModal, setShowModal] = useState(false)
  const [BFPList, setBFPList] = useState([])
  //const data = props.property.lastSurvey.externalFibreSet;

  useEffect(() => {
    props.property.lastSurvey.locationSet.map((location) => {
      location.unmounted_asset_set.map((unmountedAsset) => {
        if(unmountedAsset.asset_type === "BFP" && unmountedAsset.is_deleted === false){
          setBFPList(BFPList => [...BFPList, unmountedAsset]);
        }
      })
    })
    props.property.lastSurvey.riserSet.map((riser) => {
      riser.unmounted_asset_set.map((unmountedAsset) => {
        if(unmountedAsset.asset_type === "BFP" && unmountedAsset.is_deleted === false){
          setBFPList(BFPList => [...BFPList, unmountedAsset]);
        }
      })
    })
  }, [])

  

  const cardInfo =
  {
    id: "carrier-list",
    imageSource: "/Carriers.png",
    summaryNumber: BFPList.length,
    title: "Carriers",
    subtitle: "On net"
  }
  
  // Press the button to show form
  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <Grid item>
      {( BFPList.length === 0) ?
        <></> :
        showModal && <CarrierListModal setShowModal={setShowModal} data={BFPList}/>
      }
      <Card sx={props.shadowStyle} onClick={handleClick}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
  )
}
