import React, { Fragment, useState } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { HeadTable } from './HeadTable';
import { RowFibreTube } from './RowFibreTube';
import { onClickAddLocationTable } from '../../../../style';
import { AddFibreTube } from '../../../dialogs/AddFibreTube';


export const FibreTubeTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const fibreTubeList = props.fibreTubeList;
  const fibreTubeBundleId = props.fibreTubeBundleId;
  
  return (
    <Box sx={{marginLeft: "50px", marginRight: "-14px", marginBottom: "1px"}}>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add Fibre Tube
          </Typography>
        </caption>
        <HeadTable />
        <TableBody>
          {fibreTubeList.map((fibreTube) => 
            {return fibreTube.is_deleted === false ?
            <RowFibreTube 
              key={fibreTube.id}
              row={fibreTube}
              fibreTubeId={fibreTube.id}
              deleteFibreTube={props.deleteFibreTube}
              deleteFibreTubeCore={props.deleteFibreTubeCore}
              loadingFibreTubeBundle={props.loadingFibreTubeBundle}
              fibreTubeBundleId={fibreTubeBundleId}
              />
              :
              <Fragment key={fibreTube.id}/>
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddFibreTube
        cabinetId={props.cabinetId}
        surveyId={props.surveyId}
        dialogTitle={"Add new Fibre Tube"}
        fibreTubeBundleId={fibreTubeBundleId}
        loadingFibreTubeBundle={props.loadingFibreTubeBundle}
        closeDialog={setOpenDialog}
         />
    }
    </Box>
  )
}
