import React, { Fragment, useState } from 'react';
import { 
  Collapse, 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons, cellStyleLocationTableDropdownButton, rowBackground } from '../../../style'
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FibreTubeTable } from './fibreTube/FibreTubeTable';
import ConformDialog from '../../dialogs/ConformDelete';
import { AddFibreTubeBundle } from '../../dialogs/AddFibreTubeBundle';

export const ConnectionRowWithSubtable = (props) => {
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const row = props.row;
  const fibreTubeBundleId = props.fibreTubeBundleId;
  const surveyId = props.surveyId;

  return (
    <Fragment>
      <TableRow>
        <TableCell sx={cellStyleLocationTableDropdownButton}>
        <IconButton
        aria-label="expand row"
        size="small"
        onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
        </IconButton>
        </TableCell>
        <TableCell sx={cellStyleLocationTableBorder}>{row?.cabinet?.name}</TableCell>
        <TableCell sx={cellStyleLocationTableBorder}>{row?.location?.name}</TableCell>
        <TableCell sx={cellStyleLocationTableBorder}>{row?.location?.description}</TableCell>
        <TableCell sx={cellStyleLocationTableButtons}>
          <IconButton onClick={() => setOpenEditDialog(true)}>
            <EditIcon></EditIcon>
          </IconButton>
          <IconButton onClick={() => setOpenDeleteDialog(true)}>
            <CancelIcon></CancelIcon>
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={rowBackground}>
        {/* Show Subtable */}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/** add Fibre Tube Table */}
            <FibreTubeTable
              cabinetId={row?.cabinet?.id}
              surveyId={surveyId}
              fibreTubeList={row.fibreTubeSet}
              deleteFibreTube={props.deleteFibreTube}
              deleteFibreTubeCore={props.deleteFibreTubeCore}
              fibreTubeBundleId={fibreTubeBundleId}
              loadingFibreTubeBundle={props.loadingFibreTubeBundle}
              />
          </Collapse>
        </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Fibre Tube Bundle"} 
          deleteFunction={() => props.deleteFibreTubeBundle(fibreTubeBundleId)}/>
      }
      {openEditDialog &&
        <AddFibreTubeBundle 
          dialogTitle={"Edit Fibre Tube Bundle"}
          surveyId={surveyId}
          closeDialog={setOpenEditDialog}
          fibreTubeBundleId={fibreTubeBundleId}
          loadingFibreTubeBundle={props.loadingFibreTubeBundle}
        />
      }
    </Fragment>
  )
}
