import config from "../config";

const baseUrl = `${config.apiBaseUrl}/api/`;

export const get = async(tokenValue, api_url) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'GET',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
  return response.json()
}

export const deleteItem = async(tokenValue, api_url) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'DELETE',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
  return response.json()
}

export const addItem = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'POST',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    },
    body:  JSON.stringify(body)
  })
  return response.json()
}

export const updateItem = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PUT',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    },
    body:  JSON.stringify(body)
  })
  return response.json()
}

export const addFile = async(tokenValue, api_url, body) => {
  const response = await fetch(baseUrl + api_url, {
    method: 'POST',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
    },
    body:  body
  })
  return response.json()
}

export const updateFile = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PUT',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
    },
    body:  body
  })
  return response.json()
}

export const updateItemPatch = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PATCH',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    },
    body: JSON.stringify(body)
  })
  return response.json()
}

export const actionItemPatch = async(tokenValue, api_url) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PATCH',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }
  })
  return response.json()
}

export const deletePhoto = async(tokenValue, api_url) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'DELETE',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    }})
  return response
}

export const updatePhoto = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PATCH',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
    },
    body:  body
  })
  return response.json()
}

export const resetPassword = async(api_url, body) => {
  const response = await fetch(baseUrl + api_url, {
    method: 'POST',
    headers: { 
      'Content-Type': 'application/vnd.api+json'
    },
    body: JSON.stringify(body)
  })
  return response.json()
}

export const invite = async(tokenValue, api_url) => {
  const response = await fetch(baseUrl + api_url, {
    method: 'PATCH',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    },
  })
  return response.json()
}

export const addFileReturnStatus = async(tokenValue, api_url, body) => {
  const response = await fetch(baseUrl + api_url, {
    method: 'POST',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
    },
    body:  body
  })
  return response
}

export const updateFileReturnStatus = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PUT',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
    },
    body:  body
  })
  return response
}

export const toggleUserActive = async(tokenValue, api_url, body) => {
  const response = await fetch (baseUrl + api_url, {
    method: 'PATCH',
    headers: { 
      'Authorization': 'Token ' +  tokenValue,
      'Content-Type': 'application/vnd.api+json'
    },
    body: JSON.stringify(body)
  })
  return response
}
