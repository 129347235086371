import { addFile, deleteItem, get, updatePhoto} from "./service";

export const getExteriorPhotoBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "exteriorPhoto/?survey=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getPhotosByLocationId = async (tokenValue, locationId)=> {
  const apiUrl = "locationPhoto/?location=" + locationId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getPhotosByRiserId = async (tokenValue, riserId)=> {
  const apiUrl = "riserPhoto/?riser=" + riserId;
  const response = await get(tokenValue, apiUrl)
  return response
}

// delete photos
export const deleteExteriorPhoto = async(tokenValue, id)  => {
  const apiUrl = "exteriorPhoto/" + id;
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteLocationPhoto = async(tokenValue, id)  => {
  const apiUrl = "locationPhoto/" + id;
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteRiserPhoto = async(tokenValue, id)  => {
  const apiUrl = "riserPhoto/" + id;
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

// loading tags
export const getExteriorPhotoTags = async (tokenValue)=> {
  const apiUrl = "exteriorPhotoTag/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getLocationPhotoTags = async (tokenValue)=> {
  const apiUrl = "locationPhotoTag/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getRiserPhotoTags = async (tokenValue)=> {
  const apiUrl = "riserPhotoTag/";
  const response = await get(tokenValue, apiUrl)
  return response
}

// create Tags
export const addNewExteriorPhotoTag = async (tokenValue, body)=> {
  const apiUrl = "exteriorPhotoTag/";
  const response = await addFile(tokenValue, apiUrl, body)
  return await response
}

export const addNewLocationPhotoTag = async (tokenValue, body)=> {
  const apiUrl = "locationPhotoTag/";
  const response = await addFile(tokenValue, apiUrl, body)
  return await response
}

export const addNewRiserPhotoTag = async (tokenValue, body)=> {
  const apiUrl = "riserPhotoTag/";
  const response = await addFile(tokenValue, apiUrl, body)
  return await response
}

// post 
export const addNewExteriorPhoto = async (tokenValue, body) => {
  const apiUrl = "exteriorPhoto/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const addNewLocationPhoto = async (tokenValue, body) => {
  const apiUrl = "locationPhoto/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const addNewRiserPhoto = async (tokenValue, body) => {
  const apiUrl = "riserPhoto/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}


// patch
export const updateExteriorPhoto = async (tokenValue, id, body) => {
  const apiUrl = "exteriorPhoto/" + id + "/";
  const response = await updatePhoto(tokenValue, apiUrl, body)
  return response
}

export const updateLocationPhoto = async (tokenValue, id, body) => {
  const apiUrl = "locationPhoto/" + id + "/";
  const response = await updatePhoto(tokenValue, apiUrl, body)
  return response
}

export const updateRiserPhoto = async (tokenValue, id, body) => {
  const apiUrl = "riserPhoto/" + id + "/";
  const response = await updatePhoto(tokenValue, apiUrl, body)
  return response
}
