const getLastSlash = (string) => {
  if (string.lastIndexOf('/') > 0) {
    return string.lastIndexOf('/') + 1;
  } else {
    return 0
  }
}

const getFileNameFromUrl = (url) => {
  const qIndex = url.indexOf('?');
  const sIndex = getLastSlash(url);
  if (qIndex > 0) {
    // has a question mark
    return url.slice(sIndex, qIndex)
  } else {
    return url.slice(sIndex)
  }
}

export { getFileNameFromUrl  }
