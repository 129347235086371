import React, {useState} from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {Section, Documents, checkBool, checkString, Photos} from './GridComponents';

export const Riser = ( {risers, handleOpenModal} ) => {
  
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  if (!risers.length) {
    return <Typography>No risers on record for this building</Typography>
  }
  
  return(
    <Stack spacing={2}>
      <Pagination count={risers.length} page={page} onChange={handleChange} />
      <Typography>{risers[page-1].description}</Typography>
      
      <Section
        subheading={"Location"}
        keyVals={{
          "Start location": risers[page - 1].location,
          "End location": risers[page - 1].location2,
        }}
      />
      
      <Section
        subheading={"Details"}
        keyVals={{
          "Has space for new cables": checkBool(risers[page - 1].has_space_for_new_cables),
          "Has space for termination enclosure": checkBool(risers[page - 1].has_space_for_termination_enclosure),
          "Has horizontal cable runs": checkBool(risers[page - 1].has_horizontal_cable_runs),
          "Is strip out required": checkBool(risers[page - 1].is_strip_out_required),
          "Is over 7m from another riser": checkString(risers[page - 1].is_over_7_meters_from_another_riser),
          "Runs from basement to roof": checkBool(risers[page - 1].runs_from_basement_to_roof),
        }}
      />
      
      <Photos
        photos={risers[page - 1].riser_photo_set || [] }
        handleOpenModal={handleOpenModal}
      />
      

    </Stack>
  )
}
