import React from 'react';
import { 
  Box,
  CardContent,
  TextField
 } from '@mui/material'

export const MakeModel = (props) => {
  const attributes = props.attributes;

  return (
    <CardContent>
      <Box sx={{ display: 'flex', flexDirection: 'inline' }}>
        <TextField 
          id="outlined-basic-floors-lifts" 
          label={"Make"} 
          value={attributes.make}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          style = {{width: 200, marginLeft: "20px"}} 
          onChange={(e) => props.setMake(e.target.value)}
        />
        <TextField 
          id="outlined-basic-floors-lifts" 
          label={"Model"} 
          value={attributes.model}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          style = {{width: 200, marginLeft: "26px"}} 
          onChange={(e) => props.setModel(e.target.value)}
        />
      </Box>
    </CardContent>
  )
}
