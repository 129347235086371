import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { useObserver } from "mobx-react";
import Container from "@mui/material/Container";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import Properties from "./pages/Properties";
import Property from "./pages/Property";
import Survey from "./pages/Survey";
import SurveyForm from "./pages/SurveyForm";
import SurveyReport from "./pages/SurveyReport";
import Users from "./pages/Users";
import { AddNewSurveyForm } from "./components/AddNewSurveyForm";
import AddSurveyDuplicateForm  from "./components/AddSurveyDuplicateForm";

function Main() {
  const styles = {
    backgroundContainer: {
        backgroundImage: `url(${"Complete-spiro-10-2.jpg"})`,
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
        position: 'fixed',
        bottom: '0',
        width: '100%',
        height: '100%',
        zIndex: '-1',
    }
  };
  
  /* Get rid of ?token=<id> param if the user is authenticated */
  const params = new URLSearchParams(window.location.search);
  if (params.get("token")) {
    window.history.pushState({}, null, "/");
  }

  return useObserver(() => (

    <Router>
      <Navbar />
      <div className="main">
        <div className="wrapper">
        <div id="spirograph" style={styles.backgroundContainer}></div>
          <div className="page-contents">
            <Container>
              <Switch>
                <Route path="/" exact component={Dashboard} />
                <Route path="/properties" component={Properties} />
                <Route path="/property/:id" component={Property} />
                <Route path="/surveys" component={Survey} />
                <Route path="/survey/:id" component={SurveyForm} />
                <Route path="/report/:id" component={SurveyReport} />
                {localStorage.role === "staff" &&
                  <>
                  <Route path="/createNewSurvey" component={AddNewSurveyForm} />
                  <Route path="/users" component={Users} />
                  </>
                }
                  <Route path="/duplicateSurvey/:id" component={AddSurveyDuplicateForm} />
              </Switch>
            </Container>
          </div>
        </div>
      </div>
    </Router>
  ));
}

export default Main;
