import React, { useContext, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';
import StoreContext from "../../../context";
import { getSurveyByPropertyIdWithoutPagination } from '../../../service/survey';

export const PropertySelect = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const dataList = props.dataList;
  const [selectState, setSelectState] = useState();
  
  const searchForSurvey = async (propertyId) =>{
    const response = await getSurveyByPropertyIdWithoutPagination(tokenValue, propertyId);
    props.setSurveyList(response.data)
    return response;
  }

  const handleChange = async (event) => {
    setSelectState(event.target.value);
    const response = await searchForSurvey(event.target.value.id);
    if (response.data.length !== 0){
      props.buildingAlreadyExistDialog(true);
    }
    props.setPropertyId(event.target.value.id)
    props.setDisableBox(false);
    props.setEnableSaveButton(true);
  }

  return (
    <CardContent>
      <TextField
        required
        id="outlined-select-property"
        select
        label="Property"
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || ""}
        sx={{minWidth:400}}
        SelectProps={{
            MenuProps: {
              sx: { maxHeight: '50%',
                    marginLeft: "60px" }
            }
          }}
      >
        {dataList?.map((d) => (
          <MenuItem
            key={d.id}
            value={d}
          >
            {d.attributes.buildingName}
          </MenuItem>
        ))}
      </TextField>
      
    </CardContent>
  )
}
