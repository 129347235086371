import React, { Fragment, useState, useContext } from 'react';
import { 
  Collapse, 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtonsWithPhoto, cellStyleLocationTableDropdownButton, rowBackground } from '../../../style'
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { RowWithButtons } from './RowWithButtons';
import { RowWithEntryPointTable } from './RowWithEntryPointTable';
import { RowWithCabinets } from './RowWithCabinets';
import ConformDialog from '../../dialogs/ConformDelete';
import { RowWithUnmountedAssetTable } from './RowWithUnmountedAssetTable';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { DialogWithTable } from '../../dialogs/photos/DialogWithTable';
import StoreContext from '../../../context';
import { addNewLocationPhoto, addNewLocationPhotoTag, deleteLocationPhoto, getLocationPhotoTags, getPhotosByLocationId, updateLocationPhoto } from '../../../service/photos';
import { useSnackbar } from 'notistack';

export const LocationWithSubtable = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false);
  const [listLocationPhotos, setListLocationPhotos] = useState([])
  const [tagsList, setTagsList] = useState([]);
  const [selectedPhotosList, setSelectedPhotosList] = useState([]);
  const [selectedTagsList, setSelectedTagsList] = useState([]);

  const row = props.row;
  const locationId=props.locationId;
  const surveyId= props.surveyId;

  // for messages
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notDeletedCabinets = row.attributes?.cabinetSet.filter((cabinet) => cabinet.is_deleted === false).length
  const notDeletedEntryPoints = row.attributes?.entryPointSet.filter((entryPoint) => entryPoint.is_deleted === false).length
  const notDeletedUnmountedAsset =  row.attributes?.unmountedAssetSet.filter((asset) => asset.is_deleted === false).length
  
  const handleEdit = () => {
    props.setOpenEditDialog(true)
    props.setLocationId(locationId)
  }

  const loadingLocationPhotos = () => {
    getPhotosByLocationId(tokenValue, row.id)
    .then((response) =>{
      setListLocationPhotos(response.data)
    })
  }
  
  const removeLocationPhoto = (id) => {
    deleteLocationPhoto(tokenValue, id)
    .then((response) => {
      if(response.data) {
        loadingLocationPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const loadingPhotoTags = () => {
    getLocationPhotoTags(tokenValue)
    .then((response) => {
      if(response.data) {
        setTagsList(Array.from(response.data))
      }
    })
  }

  const addLocationPhoto = () => {
    const formData = new FormData();
    formData.append("location", locationId);
    for (const [key, value] of Object.entries(selectedPhotosList)) {
      formData.append("photo_" + key, value);
    }
    for (const [key, value] of Object.entries(selectedTagsList)) {
      formData.append("tags", value);
    }
    addNewLocationPhoto(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Location Photos.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingLocationPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const createLocationPhotoTag = async (tagName) => {
    const formData = new FormData();
    formData.append('tag', tagName);
    const response = await addNewLocationPhotoTag(tokenValue, formData)
    return response
  }

  const editLocationPhotoTags = (id, idTagsList) => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(idTagsList)) {
      formData.append("tags", value);
    }
    updateLocationPhoto(tokenValue, id, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully updated Tags for Location Photo.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        loadingLocationPhotos();
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }
  
  return (
    <Fragment>
      <TableRow>
        <TableCell sx={cellStyleLocationTableDropdownButton}>
          <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </IconButton>
          </TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.attributes?.floor}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.attributes?.room}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.attributes?.description}</TableCell>
          <TableCell sx={cellStyleLocationTableButtonsWithPhoto}>
            <IconButton onClick={() => setOpenPhotoDialog(true)}>
              <PhotoCameraIcon></PhotoCameraIcon>
            </IconButton>
            <IconButton onClick={() => handleEdit()}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      <TableRow sx={rowBackground}>
        {/* EntryPoint table */}
        {/* Buttons: +ENTRY_PONT +CABINET +ACCESS_POINT   +TERMINATION_ENCLOSURE*/}
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {notDeletedEntryPoints !== 0 &&
              <RowWithEntryPointTable 
                entryPointList={row.attributes.entryPointSet}
                deleteEntryPoint={props.removeEntryPoint}
                locationId={locationId}
                surveyId={surveyId}
                loadingTable={props.loadingLocations}
                />
            }
            {notDeletedUnmountedAsset !== 0 &&
              <RowWithUnmountedAssetTable 
                unmountedAssetList={row.attributes.unmountedAssetSet}
                deleteUnmountedAsset={props.removeUnmountedAsset}
                locationId={locationId}
                riserId={""}
                surveyId={surveyId}
                loadingTable={props.loadingLocations}
              />
            }
            {notDeletedCabinets !== 0 &&
              <RowWithCabinets 
                cabinetList={row.attributes.cabinetSet}
                deleteCabinet={props.removeCabinet}
                deleteAsset={props.removeAsset}
                locationId={locationId}
                loadingTable={props.loadingLocations}
              />
            }
              <RowWithButtons 
                surveyId={surveyId}
                locationId={locationId}
                loadingTable={props.loadingLocations} />
          </Collapse>
        </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Location"} 
          deleteFunction={() => props.removeLocation(row.id)}/>
      }
      {openPhotoDialog && 
        <DialogWithTable
          dialogTitle={row.attributes.name + " photos"}
          addRowText={"+ Add " + row.attributes.name + " Photos"}
          closeDialog={setOpenPhotoDialog}
          photoList={listLocationPhotos}
          setPhotoList={setListLocationPhotos}
          getPhotoList={loadingLocationPhotos}
          removePhoto={removeLocationPhoto}
          tagsList={tagsList}
          setTagsList={setTagsList}
          getTagsList={loadingPhotoTags}
          selectedPhotosList={selectedPhotosList}
          setSelectedPhotosList={setSelectedPhotosList}
          uploadPhotos={addLocationPhoto}
          selectedTagsList={selectedTagsList}
          setSelectedTagsList={setSelectedTagsList}
          editPhoto={editLocationPhotoTags}
          createNewPhotoTag={createLocationPhotoTag}
          />
      }
    </Fragment>
  )
}
