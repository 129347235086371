import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { onClickAddLocationTable } from '../../../../style';
import { HeadRiserTable } from '../HeadRiserTable';
import { AddRiser } from '../../../dialogs/riser/AddRiser';
import { RiserWithSubtable } from './RiserWithSubtable';

export const RiserTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [riserId, setRiserId] = useState(null);
  const riserList = props.riserList;
  const surveyId = props.surveyId;

  return (
    <Box>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add new Riser 
          </Typography>
        </caption>
        <HeadRiserTable />
        <TableBody>
          {riserList.map((riser) => 
            <RiserWithSubtable 
              key={riser.id}
              riserId={riser.id}
              row={riser}
              surveyId={surveyId}
              removeRiser={props.removeRiser}
              loadingLocations={props.loadingLocations}
              setRiserId={setRiserId}
              setOpenEditDialog={setOpenEditDialog}
              removeEntryPoint={props.removeEntryPoint}
              loadingRisers={props.loadingRisers}
              removeUnmountedAsset={props.removeUnmountedAsset}
              removeCabinet={props.removeCabinet}
              removeAsset={props.removeAsset}/>
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddRiser
        dialogTitle={"Add New Riser"} 
        closeDialog={setOpenDialog}
        surveyId={surveyId}
        loadingRisers={props.loadingRisers}
      />
    } 
    {openEditDialog && 
      <AddRiser
        dialogTitle={"Edit Riser"} 
        closeDialog={setOpenEditDialog}
        surveyId={surveyId}
        loadingRisers={props.loadingRisers}
        riserId = {riserId}
      />
    } 
    </Box>
  )
}
