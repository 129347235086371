import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import {useTheme} from '@mui/material/styles';
import { headStyleTable, headCellStyleTable } from "../../style";


const Row = ( {rows, columns} ) => {
  const theme = useTheme();
  
  if (rows.length) {
    return(
      rows.map((row) => (
        <TableRow
          key={row.id}
          sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.grayBackground.main } }}
        >
          {columns.map((column) => {
            return(
            <TableCell key={row.id + column.id}>
              {row[column.id]}
            </TableCell>
            )
          }) }
        </TableRow>
      ))
    )
  } else return(
    <TableRow>
      <TableCell component="th" scope="row">
        No data to display
      </TableCell>
    </TableRow>
  )
}




export const BasicTable = ( {columns, filtered} ) => {
  return (
    <TableContainer component={Paper}>
    
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={headStyleTable}>
          <TableRow>
          {
            columns.map((column) => {
              return(<TableCell sx={headCellStyleTable} key={column.id}>{column.name}</TableCell>)
            })
          }
          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            rows={filtered}
            columns={columns}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
