import { addItem, deleteItem, get } from "./service";

export const getWiredScoreRatingBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "wiredScoreRating/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteWiredScoreRating = async (tokenValue, id)=> {
  const apiUrl = "wiredScoreRating/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const addNewWiredScoreRating = async (tokenValue, body) => {
  const apiUrl = "wiredScoreRating/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}