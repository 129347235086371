import React, { useState } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';
import { LocationConnectedModal } from '../propertyCardModal/LocationConnectedModal';

export const LocationConnected = (props) => {
  const property = props.property;
  const [showModal, setShowModal] = useState(false)

  const calculate_locations_connected = () => {
    let count = 0;
    if (property.lastSurvey.fibreTubeBundleSet) {
      for (let i = 0; i < property.lastSurvey.fibreTubeBundleSet.length; i++) {
        count += property.lastSurvey.fibreTubeBundleSet[i].fibre_tube_set.length;
      }
    }
    return count;
  }

  
  const cardInfo =
  {
    id: "location-connected",
    imageSource: "/Fibre backbone.png",
    summaryNumber: calculate_locations_connected(),
    title: "Locations Connected",
    subtitle: "With Fibre Backbone"
  }
  
  // Press the button to show form
  const handleClick = () => {
    setShowModal(true);
  }

  return (
    <Grid item>
      {/** I need card because of click on card */}
      {( property.lastSurvey.fibreTubeBundleSet.length === 0) ?
        <></> :
        showModal && <LocationConnectedModal setShowModal={setShowModal} fibreTubeBundleSet={property.lastSurvey.fibreTubeBundleSet}/>
      }
      
     <Card sx={props.shadowStyle} onClick={handleClick}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
  )
}
