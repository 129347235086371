import React, {useEffect, useState} from 'react';
import { 
  Box, 
  CardContent, 
  Checkbox, 
  Chip, 
  FormControlLabel, 
  FormGroup, 
  ListItem, 
  Typography,
  Button,
} 
from '@mui/material';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { status } from '../../../models/enumTypeLists';
import { NetworkCarrierSelect } from './NetworkCarrierSelect';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { deleteAssetPhoto } from '../../../service/asset';
import { RiserFloor } from '../riser/RiserFloor';


export const BFP = (props) => {
  const attributes = props.attributes;
  const tokenValue = props.tokenValue;
  const [photo, setPhoto] = useState(null);
  const riserId = props.riserId;

  useEffect(() => {
    props.loadingAssetType();
  }, [])
  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, is_installed_by_zetta: value}));
  }
  const setNetworkCarrier = (value) => {
    props.setAttributes(attributes =>({...attributes, network_carrier: value}));
  }
  const setSerial = (value) => {
    props.setAttributes(attributes =>({...attributes, serial: value}));
  }
  const handleSetPhoto = (value) => {
    setPhoto(value);
    props.setAttributes(attributes =>({...attributes, image_asset_photo: value}));
  }
  const handleDeletePhoto = (id) => {
    if (id) {
      deleteAssetPhoto(tokenValue, id)
    }
    setPhoto(null);
  } 

  return (
    <>
      <CardContent>
        {riserId && 
          <RiserFloor 
            attributes={attributes}
            setAttributes={props.setAttributes}
          />
        }
        <DropDownBoxWithLabel 
            boxTitle={"Status"} 
            dataList={status}
            defaultValue={attributes.status}
            setValue={setStatus} />
        <NetworkCarrierSelect 
          label={"Network Carrier"}
          setValue={setNetworkCarrier}
          defaultValue={attributes.network_carrier}
        />
        <TextFieldBox 
          boxTitle={"Serial Number"}
          setValue={setSerial} 
          defaultValue={attributes.serial}
        />
        {/*<CardContent>
          <Typography>Asset Photo</Typography>
          {photo === null & attributes.image_asset_photo === [] ?
            <></>
          :
            <ListItem>
              {attributes?.image_asset_photo[0] && 
                attributes.image_asset_photo?.map((photo) => 
                  <Chip
                    key={photo.id}
                    label={photo?.photo.substring(photo?.photo.lastIndexOf('/')+1)}
                    onDelete={() => handleDeletePhoto(photo.id)}
                  />
                )
              }
              {photo?.name &&
                <Chip
                  label={photo?.name} onDelete={handleDeletePhoto}/>
              }
            </ListItem>
          }
          
          <Button variant="outlined" component="label" sx={{marginTop: "20px"}}>
            Select file 
            <input  id="file-board-photo" type="file" hidden onChange={(e) => handleSetPhoto(e.target.files[0])}/>          
          </Button>
        </CardContent>
        */}
        <CardContent>
          <FormGroup>
            <FormControlLabel 
              control={
              <Checkbox 
                checked={attributes.is_installed_by_zetta}
                onChange={(e) => setInstalledByZetta(e.target.checked)}
              />
              } 
              label="Installed by Zetta as part of these works" 
            />
          </FormGroup>
        </CardContent>
      </CardContent>
    </>
  )
}
  