import { config as jsonApiConfig } from "@datx/jsonapi";
import AuthStore from "./authStore";
import DataStore from "./dataStore";
import LoadStatus from "../models/loadStatus";

import config from "../config";
import PropertyStore from "./propertyStore";
import SurveyStore from "./surveyStore";
import UserStore from "./userStore";

jsonApiConfig.baseUrl = `${config.apiBaseUrl}/api/`;
jsonApiConfig.transformRequest = (request) => {
  const ret = {
    ...request,
    // This is annoying - Django requires / at the end of URLs, so add it if it isn't present
    // If query params are specified (check for '?') don't add it at the very end
    url:
      request.url +
      (request.url[request.url.length - 1] !== "/" && !request.url.includes("?")
        ? "/"
        : ""),
  };
  return ret;
};

class RootStore {
  authStore = null;
  dataStore = null;
  propertyStore = null;
  surveyStore = null;
  userStore = null;

  constructor() {
    this.authStore = new AuthStore(this);
    this.dataStore = new DataStore();
    this.propertyStore = new PropertyStore();
    this.surveyStore = new SurveyStore();
    this.userStore = new UserStore();
    this.initialize();
  }

  initialize() {
    this.dataStore.add(
      [
        { name: "Property" },
        { name: "CountDashboardData" },
        { name: "Survey" },
        { name: "User" },
      ],
      LoadStatus
    );
    this.propertyStore.add(
      [
        { name: "Property" }
      ],
      LoadStatus
    );
    
    this.surveyStore.add(
      [
        { name: "Survey" }
      ],
      LoadStatus
    );
    this.userStore.add(
      [
        { name: "User" }
      ],
      LoadStatus
    );
  }

  reset() {
    this.dataStore.reset();
    this.propertyStore.reset();
    this.surveyStore.reset();
    this.userStore.reset();
    this.initialize();
  }
}

export default RootStore;
