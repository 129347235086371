import React, { Fragment, useState } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { onClickAddLocationTable } from '../../../../style';
import { HeadTable } from './HeadTable';
import { AddFibreCore } from '../../../dialogs/AddFibreCore';
import { RowFibreCore } from './RowFibreCore';

export const FibreCoreTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const fibreCoreList = props.fibreCoreList;
  const fibreTubeId = props.fibreTubeId;
  
  return (
    <Box sx={{marginLeft: "50px", marginRight: "-14px", marginBottom: "1px"}}>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add Fibre Core
          </Typography>
        </caption>
        <HeadTable />
        <TableBody>
          {fibreCoreList.map((fibreCore)=>
           {return fibreCore.is_deleted === false ?
            <RowFibreCore
              key={fibreCore.id}
              row={fibreCore}
              deleteFibreTubeCore={props.deleteFibreTubeCore}
              fibreCoreId={fibreCore.id}
              fibreTubeId={fibreTubeId}
              loadingFibreTubeBundle={props.loadingFibreTubeBundle}
              />
            :
              <Fragment key={fibreCore.id}/>
            }
          )}
          
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddFibreCore 
        dialogTitle={"Add New Fibre Core"}
        closeDialog={setOpenDialog}
        fibreTubeId={fibreTubeId}
        loadingFibreTubeBundle={props.loadingFibreTubeBundle}/>
    }
    </Box>
  )
}
