import React from 'react';
import { Card } from '@mui/material';
import { CaptionWithDivider } from '../CaptionWithDivider';
import { DropDownBoxWithLabel } from '../DropDownBoxWithLabel';
import { MultilineBoxWithLabel } from '../MultilineBoxWithLabel';
import { Box } from '@mui/system';
import { TextFieldBox } from '../TextFieldBox';
import { yseNoDataList } from '../../../models/enumTypeLists';
import { cardStyleNoBorderNoShadow } from '../../../style';

export const Access = (props) => {
  const attributes = props.attributes;
  let hasLift = "";
  let hasLoadingBay = "";
    
  if (attributes?.hasLifts === true){
    hasLift = "Yes"
  }
  if (attributes?.hasLifts === false){
    hasLift = "No"
  }
  if (attributes?.hasLifts === null) {
    hasLift = "Unknown"
  }

  if (attributes.hasLoadingBay === true){
    hasLoadingBay = "Yes"
  }
  if (attributes.hasLoadingBay === false){
    hasLoadingBay = "No"
  }
  if (attributes.hasLoadingBay === null) {
    hasLoadingBay = "Unknown"
  }

  const setHasLift = (value) =>{
    let hasLiftBoolean = null;
    if(value === "Yes"){
      hasLiftBoolean = true
    }
    if(value === "No"){
      hasLiftBoolean = false
    }
    props.setAttributes(attributes =>({...attributes, hasLifts: hasLiftBoolean}));
     props.setEnableSaveButton(true)
  }

  const setHasLoadingBay = (value) =>{
    let hasLoadingBayBoolean = null;

    if(value === "Yes"){
      hasLoadingBayBoolean = true
    }
    if(value === "No"){
      hasLoadingBayBoolean = false
    }
    props.setAttributes(attributes =>({...attributes, hasLoadingBay: hasLoadingBayBoolean}));
     props.setEnableSaveButton(true)

  }

  const setFloorsServedByLifts = (value) => {
    props.setAttributes(attributes =>({...attributes, floorsServedByLifts: value}));
     props.setEnableSaveButton(true)
  }

  const setLiftsDetails= (value) => {
    props.setAttributes(attributes =>({...attributes, liftsDetails: value}));
     props.setEnableSaveButton(true)
  }
  
  const setLoadingBayDetails= (value) => {
    props.setAttributes(attributes =>({...attributes, loadingBayDetails: value}));
     props.setEnableSaveButton(true)
  }



  return (
    <Card style={cardStyleNoBorderNoShadow}>
      <CaptionWithDivider caption={"Access"} />
      <Box sx={{marginLeft: "100px"}}>
        <DropDownBoxWithLabel 
          boxTitle={"Has Lifts"} 
          dataList={yseNoDataList} 
          defaultValue={hasLift}
          setValue={setHasLift}
          />
        <TextFieldBox 
          boxTitle="Floors served by lifts"
          defaultValue={attributes.floorsServedByLifts} 
          setValue={setFloorsServedByLifts}
          />
        <MultilineBoxWithLabel 
          boxTitle={"Lift details"} 
          defaultValue={attributes.liftsDetails}
          setValue={setLiftsDetails}/>
        <DropDownBoxWithLabel 
          boxTitle={"Has Loading bay"} 
          dataList={yseNoDataList}
          defaultValue={hasLoadingBay}
          setValue={setHasLoadingBay}
          />
        <MultilineBoxWithLabel 
          boxTitle={"Loading bay details"}
          defaultValue={attributes.loadingBayDetails}
          setValue={setLoadingBayDetails} />
      </Box>
    </Card>
  )
}
