import React, { useState } from "react";
import { useObserver } from "mobx-react";
import { useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";

import { resetPassword } from "../service/service";
import { useSnackbar } from "notistack";
import BackgroundImage from "../images/splash_background.jpg";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

const styles = {
  box: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
  },
  card: {
    maxWidth: "400px",
    borderRadius: "10px",
  },
  cardMedia: {
    width: "90%",
    margin: "10px auto 0px auto",
  },
};

export default function Login({ authStore }) {
  const params = new URLSearchParams(window.location.search);
  const getResetToken = () => { 
    const token = params.get("token");
    if (!token) {
      return null;
    }
    if (token.endsWith('/')) {
      return token.slice(0, -1);
    }
    return token;
  };
  const resetToken = getResetToken();
  

  const [loading, setLoading] = useState();
  const [requestReset, setRequestReset] = useState(false);
  const { register, handleSubmit, reset } = useForm();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSubmit = async (data) => {
    setLoading(true);
    authStore
      .loginEmailPassword(data.email, data.password)
      .then((result) => {
        // window.location.reload()
        setLoading(false);
        if (result) {
          closeSnackbar();
        }
        if (!result) {
          enqueueSnackbar(
            "E-mail/password not recognized. If you have forgotten your password, " +
              "please contact your account manager.",
            {
              variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
          );
          reset();
        }
      })
      .catch(() => {
        setLoading(false);
        enqueueSnackbar(
          "There was an error contacting the server. Please try again later.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      });
  };

  const handlePasswordReset = async (data) => {
    setLoading(true);
    const body = {
      "data":{
        "type": "userPasswordReset",
        "id": "",
        "token": resetToken,
        "email": data.email,
        "password1": data.password1,
        "password2": data.password2,
      }
    }
    resetPassword("resetPassword/", body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          response.data,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        data.password = data.password1
        onSubmit(data);
      }
      if(response.errors){
        setLoading(false);
        enqueueSnackbar(
          response.errors,
          {
            variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        )
      }
    })
  }

  
  const handleRequestReset = async (data) => {
    setLoading(true);
    const body = {
      "data":{
        "type": "userPasswordResetRequest",
        "id": "",
        "email": data.email,
      }
    }
    resetPassword("requestResetPassword/", body)
    .then((response) => {
      if(response.data){
        setLoading(false);
        setRequestReset(false);
        enqueueSnackbar(
          response.data,
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
      }
      if(response.errors){
        setLoading(false);
        enqueueSnackbar(
          response.errors,
          {
            variant: "error",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        )
      }
    })
  }

  const loginForm = () => {
    return  <>
              <form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <TextField
                  margin="dense"
                  fullWidth
                  label="E-mail Address"
                  {...register("email")}
                />
                <TextField
                  margin="dense"
                  fullWidth
                  type="password"
                  label="Password"
                  {...register("password")}
                />
                <LoadingButton
                  loading={loading}
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  {...register("submit")}
                >
                  Login
                </LoadingButton>
              <Button variant="text" onClick={() => { setRequestReset(true) }}>Request password reset</Button>
              </Stack>
              </form>
            </>

  }

  const requestResetForm = () => {
    return  <form onSubmit={handleSubmit(handleRequestReset)}>
              <Stack spacing={2}>
              <TextField
                margin="dense"
                fullWidth
                label="E-mail Address"
                {...register("email")}
              />
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                {...register("submit")}
              >
                Request password reset
              </LoadingButton>
              <Button variant="text" onClick={() => { setRequestReset(false); setLoading(false); }}>Cancel</Button>
              </Stack>
            </form>
  }
  
  
  const resetForm = () => {
    return  <form onSubmit={handleSubmit(handlePasswordReset)}>
              <TextField
                margin="dense"
                fullWidth
                label="E-mail Address"
                {...register("email")}
              />
              <TextField
                margin="dense"
                fullWidth
                type="password"
                label="New Password"
                {...register("password1")}
              />
              <TextField
                margin="dense"
                fullWidth
                type="password"
                label="Repeat Password"
                {...register("password2")}
              />
              <LoadingButton
                loading={loading}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                {...register("submit")}
              >
                Create password
              </LoadingButton>
            </form>
  }
  
  // needs observer to observe loading state in store and render dots accordingly
  return useObserver(() => (
    <div
      className="login-container"
      style={{ backgroundImage: "url(/splash_background.jpg)" }}
    >
      <Box
        width={1} /* 1 is equiv to 100% */
        height="100vh" /* vh is viewport height */
        flexDirection="column"
        style={styles.box}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Card style={styles.card}>
          <CardMedia
            style={styles.cardMedia}
            component="img"
            image="/ZettaConnect_logo_rgb_300-01.png"
            alt="Zetta Connect logo"
          />
          <CardContent>
          {resetToken ? resetForm() : '' }
          {requestReset ? requestResetForm() : '' }
          {!resetToken && !requestReset ? loginForm() : ''}
          </CardContent>
        </Card>
      </Box>
    </div>
  ));
}
