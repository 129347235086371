import React, { useState, Fragment } from 'react';
import { 
  Typography,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { AutocompleteTags } from './AutocompleteTags';


export const Update = (props) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const dialogTitle = props.dialogTitle;
  const selectedPhotos = props.selectedPhotos;
  const tagsList = props.tagsList;
  const selectedTagsList = props.selectedTagsList;

  const handleClose = () => {
    props.closeDialog(false);
    props.setSelectedTagsList([]);
  };

  const handleUpload = async () => {  
    if(selectedTagsList.length === 0){
      setError(true);
    }
    else {
      setError(false);
      props.editPhoto(props.photo.id, selectedTagsList);
      handleClose();
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-photo-dialog-title"
    >
      <DialogTitle id="upload-photo-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <Typography variant='h6' >
          Selected files:
        </Typography>
          <Fragment>
            {selectedPhotos} 
          </Fragment>
        <AutocompleteTags
          error={error}
          setLoading={setLoading}
          tagsList={tagsList}
          setTagsList={props.setTagsList}
          selectedTagsList={selectedTagsList}
          setSelectedTagsList={props.setSelectedTagsList}
          defaultTagsList={props.defaultTagsList}
          createNewPhotoTag={props.createNewPhotoTag}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleUpload}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}
