import React, { Fragment, useState } from 'react';
import { 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddFibreCore } from '../../../dialogs/AddFibreCore';

export const RowFibreCore = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const fibreTubeId = props.fibreTubeId;
  const fibreCoreId = props.fibreCoreId;
  
  const row = props.row;

  return (
    <Fragment>
      <TableRow>
          <TableCell >{row.number}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.start_port}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.end_port}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row.loss}</TableCell>          
          <TableCell sx={cellStyleLocationTableBorder}>{row?.photo?.substring(row.photo.lastIndexOf('/')+1)}</TableCell>
          <TableCell sx={cellStyleLocationTableButtons}>
            <IconButton onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"fibre core"} 
          deleteFunction={() => props.deleteFibreTubeCore(row.id)}/>
      }
      {openEditDialog && 
        <AddFibreCore 
          dialogTitle={"Edit Fibre Core"}
          closeDialog={setOpenEditDialog}
          fibreCoreId={fibreCoreId}
          fibreTubeId={fibreTubeId}
          loadingFibreTubeBundle={props.loadingFibreTubeBundle} 
        />
      }
    </Fragment>
  )
}
