import React from 'react';
import { CardContent } from '@mui/material';
import { CardSubtitle } from './CardSubtitle';
import { CardTitle } from './CardTitle';
import { SummaryNumber } from './SummaryNumber';

export const EnhancedCardContent = (props) => {
  const item = props.item;

  // style
  const styleImage ={
    height: "85px",
    width: "85px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginLeft: "60px"
  }

  return (
    <CardContent>
      <img src={item.imageSource} style={styleImage} alt={item.id + "-photo"}></img>
      <SummaryNumber summaryNumber={item.summaryNumber} />
      <CardTitle cardTitle={item.title} />
      <CardSubtitle subtitle={item.subtitle}/>
    </CardContent>
  )
}
