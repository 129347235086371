import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';


export const EnhancedTableHead = (props) => {
  const headCells = props.headCells;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral.main,
      fontSize: "24px",
    },
  }));
  
  return (
    <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <StyledTableCell
              key={headCell.id}
            >{headCell.label}</StyledTableCell>
          ))}
        </TableRow>
    </TableHead>
  )
}
