import React, { useState } from 'react';
import { 
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableContainer, 
  Typography
} from '@mui/material';
import { HeadLocationTable } from './HeadLocationTable';
import { AddNewLocation } from '../../dialogs/AddNewLocation';
import { onClickAddLocationTable } from '../../../style';
import { LocationWithSubtable } from './LocationWithSubtable';


export const LocationTable = (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const locationList = props.locationList;
  const surveyId = props.surveyId;


  return (
    <Box>
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
          <Typography 
            variant="caption" 
            sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
            >+ Add new Locations 
          </Typography>
        </caption>
        <HeadLocationTable />
        <TableBody>
          {locationList.map((location) =>
            <LocationWithSubtable 
              key={location.id}
              row={location} 
              locationId={location.id}
              removeLocation={props.removeLocation}  
              removeEntryPoint={props.removeEntryPoint}
              removeUnmountedAsset={props.removeUnmountedAsset}
              removeCabinet={props.removeCabinet}
              removeAsset={props.removeAsset}
              loadingLocations={props.loadingLocations} 
              loadingRisers={props.loadingRisers}
              surveyId={surveyId}  
              setOpenDialog={setOpenDialog}
              setLocationId={setLocationId}
              setOpenEditDialog={setOpenEditDialog}
              />
          )}
        </TableBody>
      </Table>
    </TableContainer>
    {openDialog && 
      <AddNewLocation 
        dialogTitle={"Add New Location"} 
        closeDialog={setOpenDialog}
        surveyId={surveyId}        
        loadingLocations={props.loadingLocations} 
        loadingRisers={props.loadingRisers}
        locationId={locationId}
        setLocationId={setLocationId}
        
      />
    }
    {openEditDialog && 
      <AddNewLocation 
        dialogTitle={"Edit Location"} 
        closeDialog={setOpenEditDialog}
        surveyId={surveyId}        
        loadingLocations={props.loadingLocations} 
        loadingRisers={props.loadingRisers}
        locationId={locationId}
        setLocationId={setLocationId}
        locationList={locationList}
      />
    }
    
    </Box>
  )
}
