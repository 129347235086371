import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import MenuIcon from "@mui/icons-material/Menu";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export const ColumnsMenu = ( {columns, setColumns} ) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (id) => {
    const colIndex = columns.findIndex(column => column.id == id)
    var cols = [...columns]
    cols[colIndex].display = !cols[colIndex].display
    setColumns(cols)
  };
  


  return (
    <Box style={{float: 'right'}}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MenuIcon />
      </IconButton >
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button"
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "20ch"
          }
        }}>
        {
          columns.map( (column) => { 
            return(
              <MenuItem value={column.id} name={column.name} key={column.id} onClick={() => handleSelect(column.id)}>
                <ListItemIcon> {column.display === true ? <CheckBoxIcon color="primary" fontSize="small" /> : <CheckBoxOutlineBlankIcon color="primary" fontSize="small" />} </ListItemIcon>
                <ListItemText>{column.name}</ListItemText>
              </MenuItem>
            )
          })
        }
      </Menu>
    </Box>
  )
}
