import React, {Fragment} from 'react';
import {
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography
} from '@mui/material';
import { cellStyleLocationSubtable, locationSubtableTableTitle, primarySubtableHeader } from '../../../style';
import { RowEntryPoint } from './entryPoints/RowEntryPoint';

export const RowWithEntryPointTable = (props) => {
  const entryPointList = props.entryPointList;
  const locationId = props.locationId;
  const surveyId = props.surveyId;
  const riserId = props.riserId;
  
  return (
    <Box>
      <Typography variant="h6"  style={locationSubtableTableTitle}>EntryPoint</Typography>
      <TableContainer component={Paper}>
        <Table >
          <TableHead sx={primarySubtableHeader}>
            <TableRow sx={{ borderRadius: "10px"}}>
              <TableCell align="left" sx={cellStyleLocationSubtable} >Carrier</TableCell>
              <TableCell align="left" sx={cellStyleLocationSubtable}>Entry Size</TableCell>
              <TableCell align="left" sx={cellStyleLocationSubtable}>Free Capacity</TableCell>            
              <TableCell align="left" sx={cellStyleLocationSubtable}>Is over 7m from another entry</TableCell>
              <TableCell />
            </TableRow>
        </TableHead>
        <TableBody>
          {entryPointList.map((entryPoint)=> 
           {
            return entryPoint.is_deleted === true ?
            <Fragment 
              key={entryPoint.id}/>
            :
            <RowEntryPoint 
              key={entryPoint.id}
              row={entryPoint}
              deleteEntryPoint={props.deleteEntryPoint}
              surveyId={surveyId}
              locationId={locationId}
              entryPointId={entryPoint.id}
              loadingTable={props.loadingTable}
              riserId={riserId}
              />
           }
          )}
        </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
