import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import { DialogModalTitle } from '../card/DialogModalTitle';
import { LabelModalData } from './LabelModalData';
import theme from '../../theme';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { PhotoDialog } from '../photoDialogs/PhotoDialog';


export const CommsRoomsModal = (props) => {
  const commsRoomsList = props.data;
  const [showLandlordPhoto, setShowLandlordPhoto] = useState(false);
  const [showCableRoutesPhoto, setShowCableRoutesPhoto] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage  =(1);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const closeModal = () => {
      props.setShowModal(false);
  }
  
  const handleClickLandlordPhoto = () => {
    setShowLandlordPhoto(true);
  }

  const handleClickCableRoutesPhoto = () => {
    setShowCableRoutesPhoto(true);
  }

  // style
  const styleHeader = {
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "100px",
    padding:"1px 0px !important",
    align: "center",

  }
  const styleData ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "100px",
    align: "left",
    padding:"1px 0px !important"
  }

  const styleDataImg ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "50px",
    align: "right",
    padding:"1px 0px !important",
    ':hover': {
      cursor: "pointer",
    }
  }

  return (
    <Dialog open={true}>
      <DialogModalTitle title="Comms Rooms" /> 
      <DialogContent>
      {showLandlordPhoto && <PhotoDialog 
      setShowState={setShowLandlordPhoto} 
      title={"Riser Photo"} photoSet={[{
          "photo": commsRoomsList[page].cr_landlord_distribution_board_photo,
          "description" : "landlord_distribution_board_photo"
      }]}/>
      }
      {showCableRoutesPhoto && <PhotoDialog 
      setShowState={setShowCableRoutesPhoto} 
      title={"Riser Photo"} photoSet={[{
          "photo": commsRoomsList[page].cr_cable_routes_for_diverse_power_photo,
          "description" : "cable_routes_for_diverse_power_photo"
      }]}/>
      }
      <Table aria-label="simple table">
        <TableBody>
          <LabelModalData title={"Dedicated Space"} data={commsRoomsList[page].cr_dedicated_space ? commsRoomsList[page].cr_dedicated_space : "-"}/>
          <LabelModalData title={"Door Lock Type"} data={commsRoomsList[page].cr_door_lock_type ? commsRoomsList[page].cr_door_lock_type : "-"}/>
          <LabelModalData title={"Space for MeetMe Cabinet"} data={commsRoomsList[page].cr_has_space_for_meetme ? "Yes" : "-"}/>
          <LabelModalData title={"Max Cabinet size"} data={commsRoomsList[page].cr_max_cabinet_size ? commsRoomsList[page].cr_max_cabinet_size : "-"}/>
          {(commsRoomsList[page].cr_would_cabinet_fit_from_entrance) === true ?  
            <LabelModalData title={"Cabinet would fit from entrance to comms room"} data={"Yes"}/> :
            <LabelModalData title={"Cabinet would fit from entrance to comms room"} data={"No"}/>
          }
          {(commsRoomsList[page].cr_has_appropriate_power) === true ? 
            <LabelModalData title={"Appropriate power"} data={"Yes"}/> :
            <LabelModalData title={"Appropriate power"} data={"No"}/>
          }
          <LabelModalData title={"16a Commands required"} data={commsRoomsList[page].cr_16a_commandos_required ? commsRoomsList[page].cr_16a_commandos_required : "-"} />
          <TableRow>
          <TableCell sx={styleHeader}>Landlord distribution board</TableCell>
          <TableCell sx={styleData}>1 photo</TableCell>
          <TableCell sx={styleDataImg}>
            <PhotoCameraIcon onClick={handleClickLandlordPhoto} />
          </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styleHeader}>Cable routes for diverse power</TableCell>
            <TableCell sx={styleData}>1 photo</TableCell>
            <TableCell sx={styleDataImg}>
              <PhotoCameraIcon onClick={handleClickCableRoutesPhoto}/>
            </TableCell>
          </TableRow>
          <LabelModalData title={"Flood protection"} data={commsRoomsList[page].cr_flood_protection_details ? commsRoomsList[page].cr_flood_protection_details : "-"}/>
          <LabelModalData title={"Fire Protection"} data={commsRoomsList[page].cr_fire_protection ? commsRoomsList[page].cr_fire_protection : "-"}/>
          {(commsRoomsList[page].cr_has_hvac) === true ?
            <LabelModalData title={"HVAC"} data={"Yes"}/> :
            <LabelModalData title={"HVAC"} data={"No"}/>
          }
          {(commsRoomsList[page].cr_is_ready_for_install) === true ?
            <LabelModalData title={"Ready for install"} data={"Yes"}/> :
            <LabelModalData title={"Ready for install"} data={"No"}/>
          }
          {(commsRoomsList[page].cr_has_diverse_routes_to_risers) === true ?
            <LabelModalData title={"Diverse routes to risers"} data={"Yes"}/> :
            <LabelModalData title={"Diverse routes to risers"} data={"-"}/>
          }
          {(commsRoomsList[page].cr_other_notes) === "" ? 
            <LabelModalData title={"Notes"} data={"-"}/> :
            <LabelModalData title={"Notes"} data={commsRoomsList[page].cr_other_notes}/>
          }
         
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              sx ={{
                color: theme.palette.primary.main
              }}
              rowsPerPageOptions={[]}
              count={commsRoomsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
    </TableFooter>
      </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
