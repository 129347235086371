import { addFile, deleteItem, deletePhoto, get, updateFile } from "./service";

export const getRisersBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "risers/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteRiser = async(tokenValue, id) => {
  const apiUrl = "risers/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const addNewRiser = async (tokenValue, body) => {
  const apiUrl = "risersPost/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const getRiser = async (tokenValue, id)=> {
  const apiUrl = "risers/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const updateRiser = async (tokenValue, id, body)=> {
  const apiUrl = "risersPost/" + id + "/";
  const response = await updateFile(tokenValue, apiUrl, body)
  return response
}

export const deleteRiserPhoto = async(tokenValue, id) => {
  const apiUrl = "riserPhoto/" + id + "/";
  const response = await deletePhoto(tokenValue, apiUrl)
  return response
}

export const deleteRoofDemarcationPhoto = async(tokenValue, id) => {
  const apiUrl = "roofDemarcationPhoto/" + id + "/";
  const response = await deletePhoto(tokenValue, apiUrl)
  return response
}