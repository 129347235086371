import React from 'react';
import { 
  Typography 
} from '@mui/material';

export const ManagerInformation = (props) => {
  const contact = props.contact;
  const role = props.role;

  return (
    <>
        <Typography variant="h6" align="left" sx={{marginLeft: "15px"}}>{contact}</Typography>
        <Typography align="left"  sx={{marginLeft: "15px", marginTop: "-5px"}}>{role}</Typography>
    </>
    
  )
}
