import theme from "./theme";

// style
const shadowStyleSurveyPage={
  boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2)",
  borderRadius: "10px"
}

const buttonStyleSurveyPge={
  boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "50px"
}

const buttonStyleAdmin = {
  color: theme.palette.garyButton.main,
  ':hover': {
    cursor: "pointer",
  }
}

const headStyleTable ={
  background: theme.palette.primary.main,
}
const headCellStyleTable ={
  borderLeft: "solid rgba(156, 156, 156, 1)",
  color: theme.palette.neutral.main,
}
const headCellStyleTableWidth ={
  borderLeft: "solid rgba(156, 156, 156, 1)",
  color: theme.palette.neutral.main,
  width: "125px"
}
const headCellPhotoTable = {
  borderRight: "solid rgba(156, 156, 156, 1)",
  color: theme.palette.neutral.main,
}

const boxCardStyle = {
  border: "none",
  boxShadow: "none", 
  minWidth: 1104, 
  maxWidth: 1104
}
const onClickAddLocationTable = {
  ':hover': {
    backgroundColor: theme.palette.grayBackground.main,
    cursor: "pointer",
  }
}
const buttonsTablePhotos = {
  borderLeft: "solid rgba(232, 232, 232)",
  width: "80px",
  align: "center"
}
const cellTablePhotos ={
  borderLeft: "solid rgba(232, 232, 232)"
}
const cellStyleLocationTableBorder ={
  borderLeft: "solid rgba(232, 232, 232)"
}
const cellStyleLocationTableButtons ={
  borderLeft: "solid rgba(232, 232, 232)",
  width: "80px",
  align: "center"
}
const cellStyleLocationTableButtonsWithPhoto ={
  borderLeft: "solid rgba(232, 232, 232)",
  width: "125px",
  align: "center"
}
const cellStyleLocationTableDropdownButton ={
  width: "30px",
  align: "center"
}
const cardStyleWSC = {
  border: "none",
  boxShadow: "none",  
  minWidth: 504, 
  maxWidth: 704,
  backgroundColor: theme.palette.grayBackground.main
}

const buttonStyleWSC ={
  align: "center",
  marginBottom: "10px",
  marginLeft: "10px"
}

const textButtonStyleWSC = {
  color: theme.palette.black.main
}
const cardStyleBuildingInfo = {
  border: "none",
  boxShadow: "none", 
  borderBottom: "solid rgba(33, 33, 33, 0.08)", 
  minWidth: 654, 
  maxWidth: 654,
  align: "center"
}

const cardStyleWSCShadow = {
  border: "none",
  minWidth: 380, 
  maxWidth: 380,
  boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)", 
} 
const cardSxWSC ={
  display: "flex", 
  flexDirection: "column", 
  marginTop: "20px",
  marginBottom: "20px",
  marginLeft: "100px"
}
const imageStyleWSC = {
  borderWidth: 5,
  height: "65px",
  width: "65px",
  alignSelf: "stretch"
}
const separatorRatingWSC = {
  border: "none",
  boxShadow: "none", 
  borderBottom: "solid rgba(33, 33, 33, 0.08)", 
  minWidth: 250, 
  maxWidth: 250,
}
const cardStyleBuildingDoc = {
  border: "none",
  boxShadow: "none",  
  minWidth: 650, 
  maxWidth: 650,
  backgroundColor: theme.palette.grayBackground.main
}
const textButtonStyleBuildingDoc = {
  color: theme.palette.black.main
}
const buttonStyleBuildingDoc ={
  align: "center",
  marginBottom: "10px",
  marginLeft: "10px"
}
const cardStyleBuildingDocTable = {
  border: "none",
  minWidth: 650, 
  maxWidth: 650,
  boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)", 
} 
const cardSxBuildingDocTable ={
  display: "flex", 
  flexDirection: "column", 
  marginLeft: "0px",
  marginTop: "40px",
}
const cardStyleNoBorderNoShadow = {
  border: "none",
  boxShadow: "none",  
}
const cardStyleExterior = {
  border: "none",
  boxShadow: "none",  
}
const addNearbyNetworkExterior = {
  color: theme.palette.primary.main,
  '&:hover': {
    cursor: "pointer",
    backgroundColor: theme.palette.grayBackground.main, 
  }
}
const cardStyleSiteDetails = {
  border: "none",
 boxShadow: "none", 
 minWidth: 1104, 
 maxWidth: 1104
}

const lightColorButton = {
  backgroundColor: theme.palette.primary.light,
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "10px",
  marginBottom: "10px",
  
}

const rowBackground = {
  backgroundColor: theme.palette.rowWithButton.main
}

const locationSubtableTableTitle = {
  marginLeft: "20px", 
  marginBottom: "20px",
  marginTop: "20px"
}

const primarySubtableHeader = {
  backgroundColor:  theme.palette.primary.light,
}

const secondarySubtableHeader = {
  backgroundColor: theme.palette.secondTable.main,
}

const cellStyleLocationSubtable ={
  borderRight: "solid rgba(255, 255, 255, 1)",
  color: theme.palette.neutral.main,
}

const headCellStyleSecondTable ={
  borderRight: "solid rgba(255, 255, 255, 1)",
}

export {
  headCellStyleSecondTable,
  secondarySubtableHeader,
  cellStyleLocationSubtable,
  primarySubtableHeader,
  locationSubtableTableTitle,
  shadowStyleSurveyPage,
  buttonStyleSurveyPge,
  buttonStyleAdmin,
  headStyleTable,
  headCellStyleTable,
  boxCardStyle,
  onClickAddLocationTable,
  cellStyleLocationTableBorder,
  buttonStyleWSC,
  cardStyleWSC,
  textButtonStyleWSC,
  cardStyleBuildingInfo,
  cardStyleWSCShadow,
  cardSxWSC,
  imageStyleWSC,
  separatorRatingWSC,
  cardStyleBuildingDoc,
  textButtonStyleBuildingDoc,
  buttonStyleBuildingDoc,
  cardStyleBuildingDocTable,
  cardSxBuildingDocTable,
  cardStyleNoBorderNoShadow,
  cardStyleExterior,
  addNearbyNetworkExterior,
  cardStyleSiteDetails,
  cellStyleLocationTableButtons,
  cellStyleLocationTableDropdownButton,
  lightColorButton,
  rowBackground,
  headCellPhotoTable,
  buttonsTablePhotos,
  cellTablePhotos,
  cellStyleLocationTableButtonsWithPhoto,
  headCellStyleTableWidth
}