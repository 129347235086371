import React from 'react';
import { 
  CardContent, 
} from '@mui/material';
import TextField from '@mui/material/TextField';

export const PropertySelectEditSurvey = (props) => {
  const defaultValue = props.defaultValue;
  
  return (
    <CardContent>
      <TextField
        disabled={true}
        id="outlined-select-property"
        label="Property"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ 
          readOnly: true, 
        }}
        value={defaultValue || ""}
        sx={{minWidth:400}}
      >
      </TextField>
      
    </CardContent>
  )
}
