import React from 'react';
import { 
  CardContent, 
  TextField 
} from '@mui/material';

export const MultilineBoxWithLabel = (props) => {
  const boxTitle = props.boxTitle;
  const defaultValue = props.defaultValue;

  const handleChange = (event) => {
    props.setValue(event.target.value);
  };

  return (
    <CardContent>
      <TextField
          id="outlined-multiline-static"
          label={boxTitle}
          multiline
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          rows={4}
          defaultValue={defaultValue}
          style = {{width: 400}}
          onChange={handleChange}
      />
    </CardContent>
  )
}
