import React from 'react';
import { 
  Card,
  Box,
} from '@mui/material';
import { CaptionWithDivider } from '../CaptionWithDivider';
import { MultilineBoxWithLabel } from '../MultilineBoxWithLabel';
import { NearbyNetworkSelect } from './NearbyNetworkSelect';
import { cardStyleExterior } from '../../../style';

export const Exterior = (props) => {
  const attributes = props.attributes;
  const surveyId = props.surveyId;

  const setExteriorSurveyObservations = (value) => {
    props.setAttributes(attributes =>({...attributes, exteriorSurveyObservations: value}));
     props.setEnableSaveButton(true)
  }

  const setNearbyNetworks = (value) => {
    props.setAttributes(attributes =>({...attributes, nearby_networks: (value).map((item) => item.id)}));
     props.setEnableSaveButton(false)
  }

  return (
    <Card style={cardStyleExterior}>
      <CaptionWithDivider caption={"Exterior"} />
      <Box sx={{marginLeft: "100px"}}>
        <NearbyNetworkSelect 
          surveyId={surveyId}         
          setValue={setNearbyNetworks}
          defaultValue={attributes.nearby_networks}
          setEnableSaveButton={props.setEnableSaveButton}
        />
        <MultilineBoxWithLabel  
          boxTitle="Exterior Survey Observations"
          defaultValue = {attributes.exteriorSurveyObservations}
          setValue = {setExteriorSurveyObservations}
          />
      </Box>
    </Card>
  )
}
