import React from "react";

import { observer } from "mobx-react";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Main from "./Main";
import Login from "./pages/Login";

export default observer(({ authStore }) => {
  // Show a loading indicator until we've worked out if we're logged in or not
  if (authStore.isLoggedIn === null) {
    return (
      <Box
        width={1} /* 1 is equiv to 100% */
        height="100vh" /* vh is viewport height */
        flexDirection="column"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (authStore.isLoggedIn === false) {
    return <Login authStore={authStore} />;
  }

  return <Main />;
});
