import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleTable, headCellStyleTableWidth, headStyleTable } from '../../../style';


export const HeadRiserTable = (props) => {
  
    return (
        <TableHead sx={headStyleTable}>
            <TableRow>
              <TableCell/>
              <TableCell align="left" sx={headCellStyleTable}>Start Location</TableCell>
              <TableCell align="left" sx={headCellStyleTable}>End Location</TableCell>
              <TableCell align="left" sx={headCellStyleTable}>Elevation</TableCell>
              <TableCell align="left" sx={headCellStyleTable}>Description</TableCell>
              <TableCell sx={headCellStyleTableWidth}/>
            </TableRow>
        </TableHead>
      )
    }
    