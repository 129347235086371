import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { 
  TableBody,
} from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { EnhancedEmptyRow } from '../table/EnhancedEmptyRow';
import ConformDialog from '../dialogs/ConformDelete';
import { MenuForSurveyActions } from './MenuForSurveyActions';


export const EnhancedTableAdmin = (props) => {
  const history = useHistory();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [id, setId] = useState("");
  const rows = props.rows;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;

  const handleEditClick = (param, event) => {
    history.push(`/survey/${param.id}`);
  };

  const handleViewClick = (param, event) => {
    history.push(`/report/${param.id}`);
  };

  const handleDeleteClick = (id) =>{
    setOpenDeleteDialog(true)
    setId(id)
  }

  // style, define new element
  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
      color: theme.palette.primary.main,
      fontSize: "20px",
    },  
  }));

  return (
    <TableBody sx={{ width: '100%' }} >
      {(rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
      ).map((row)=>{
        return(
          <TableRow hover={true} key={row.meta.id}>
            <StyledTableCell >{row.property}</StyledTableCell>
            <StyledTableCell >{row.user}</StyledTableCell>
            <StyledTableCell >{row.surveyedAt}</StyledTableCell>
            <StyledTableCell >{row.status}</StyledTableCell>
            <StyledTableCell align="right" width={40}>
              <MenuForSurveyActions 
                survey={row}
                surveyId={row.meta.id}
                deleteSurvey={handleDeleteClick}
                editSurvey={handleEditClick}
                viewSurvey={handleViewClick}
                changeStatusSurvey={props.changeStatusSurvey}
              />
            </StyledTableCell>
          </TableRow>
        )
      })}
      <EnhancedEmptyRow page={page} rowsPerPage={rowsPerPage} rows={rows}/>
      {openDeleteDialog && 
        <ConformDialog setOpenDialog={setOpenDeleteDialog} title={"survey"} deleteFunction={() => props.removeSurvey(id)}/>
      }
    </TableBody>
  )
}
