import { addFile, deleteItem, get, updateItem } from "./service";

export const addNewSiteDetails = async (tokenValue, body, surveyId) => {
    const apiUrl = "siteDetails/" + surveyId + "/";
    const response = await updateItem(tokenValue, apiUrl, body)
    return response
}

export const getSiteDetails = async (tokenValue, surveyId)=> {
  const apiUrl = "siteDetails/" + surveyId  + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteNearbyNetworkCarrierManholePhoto = async (tokenValue, id)=> {
  const apiUrl = "nearbyNetworkCarrierManholePhoto/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteFrontPhoto = async (tokenValue, id)=> {
  const apiUrl = "frontPhoto/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const addNewNearbyNetworkCarrierManholePhoto = async (tokenValue, body) => {
  const apiUrl = "nearbyNetworkCarrierManholePhoto/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const addNewFrontPhoto = async (tokenValue, body) => {
  const apiUrl = "frontPhoto/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const getNearbyNetworksBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "nearbyNetworksBySurveyId/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}