import React, { useState, useContext, useEffect }  from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import StoreContext from '../../../context';
import { getLocationsNameIdBySurveyId } from '../../../service/asset';
import { addNewRiser, getRiser, updateRiser } from '../../../service/risers';
import { useSnackbar } from 'notistack';
import { RiserFields } from './RiserFields';

export const AddRiser = (props) => {
  const dialogTitle = props.dialogTitle;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const riserId = props.riserId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [locationList, setLocationList] = useState([]);
  const initState = {
    "survey": surveyId,
    "location": "",
    "location2": "",
    "has_space_for_new_cables": false,
    "has_space_for_termination_enclosure": false,
    "has_horizontal_cable_runs": false,
    "is_strip_out_required": false,
    "is_over_7_meters_from_another_riser": "",
    "runs_from_basement_to_roof": false,
    "description": ""
  }
  const [attributes, setAttributes] = useState(initState);

  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if (attributes.location && attributes.location2) {     
      if (riserId){
        editRiser();
      }
      else {
        addRiser();
      }
      props.closeDialog(false);
    }
    else {
      enqueueSnackbar(
        "Please select all required fields.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  }

  const loadingLocations = () =>{
    getLocationsNameIdBySurveyId(tokenValue, surveyId)
    .then((response) => {
      setLocationList(response.data)
    })
  }

  useEffect(() => {
    loadingLocations();
    riserId &&
      loadingRiser();
  }, [])

  const addRiser = async() => { 
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }    
    addNewRiser(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Riser.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingRisers()
      }
      if(!response.data){
        enqueueSnackbar(
          response?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  const editRiser = async() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    formData.append("id", riserId);
    updateRiser(tokenValue, riserId ,formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited Riser.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         ); 
        props.loadingRisers();
      }
      if(!response.data){
        enqueueSnackbar(
          response?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  const loadingRiser = async() => {
    getRiser(tokenValue, riserId)
    .then((response) => {
      if (response.data){
        const respData = response.data.attributes;
        setAttributes(attributes =>({...attributes, location: respData.location.id}));        
        setAttributes(attributes =>({...attributes, location2: respData.location2.id}));        
        setAttributes(attributes =>({...attributes, has_space_for_new_cables: respData.hasSpaceForNewCables}));       
        setAttributes(attributes =>({...attributes, has_space_for_termination_enclosure: respData.hasSpaceForTerminationEnclosure}));        
        setAttributes(attributes =>({...attributes, has_horizontal_cable_runs: respData.hasHorizontalCableRuns}));        
        setAttributes(attributes =>({...attributes, is_strip_out_required: respData.isStripOutRequired}));        
        setAttributes(attributes =>({...attributes, is_over_7_meters_from_another_riser: respData.isOver7MetersFromAnotherRiser}));
        setAttributes(attributes =>({...attributes, runs_from_basement_to_roof: respData.runsFromBasementToRoof}));
        setAttributes(attributes =>({...attributes, description: respData.description}));
      }
    })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <RiserFields 
        attributes={attributes}
        setAttributes={setAttributes}
        locationList={locationList}
      />
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
