import React from "react";
import { 
  CardContent,
  TextField
} from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

export const DateBox = (props) => { 
  const boxTitle = props.boxTitle;
  const defaultValue = props.defaultValue;
  const disableBox = props.disableBox;

  const handleInput = (newData) =>{
    const dataFormat = newData.getFullYear()
    + '-' +  ((newData.getMonth() > 8) ? (newData.getMonth() + 1) : ('0' + (newData.getMonth() + 1))) 
    + '-' + ((newData.getDate() > 9) ? newData.getDate() : ('0' + newData.getDate()))
    props.setDate(dataFormat)
    props.setEnableSaveButton(true);
  }

  return (
    <CardContent  sx={{ display: 'flex' }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disabled={disableBox} 
          label={boxTitle}
          inputFormat="MM/dd/yyyy"
          value={defaultValue}
          defaultValue={defaultValue}
          onChange={(newData) => handleInput(newData)}
          renderInput={(params) => <TextField {...params} />}
        />
      </LocalizationProvider>
        
    </CardContent>
  )
}
