import React, { useEffect, useState } from 'react';
import { DateBox } from '../DateBox'
import { DropDownBoxWithLabel } from '../DropDownBoxWithLabel';
import { 
  Button, 
  Card,
  CardContent 
} from '@mui/material';
import { wiredScoreRating } from '../../../models/enumTypeLists';
import { buttonStyleWSC, cardStyleWSC } from '../../../style';
import { useSnackbar } from 'notistack';

export const AddWiredScoreRatingDialog = (props) => {
  const [date, setDate] = useState(new Date());
  const [newWiredScoreRating, setNewWiredScoreRating] = useState("");
    // for errors
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  useEffect(() => {
    const dataFormat = date.getFullYear()
      + '-' +  ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) 
      + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
      
    setDate(dataFormat)
  }, [])

  const handleClickAddRating = () => {
    if(newWiredScoreRating){
      props.addRating(date, newWiredScoreRating);
      props.setShowForm(false);
    }
    else {
      enqueueSnackbar(
        "Please select type of wired score rating.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      ); 
    }
  }
  
  return (
    <Card style={cardStyleWSC} sx={{marginTop: "20px"}}>
      <DateBox 
        boxTitle="Valid until" 
        defaultValue={date}
        setDate={setDate}
      />
      <DropDownBoxWithLabel 
        boxTitle={"WiredScore rating"} 
        dataList={wiredScoreRating} 
        defaultValue={""} 
        setValue={setNewWiredScoreRating}/>
      <CardContent align="right">
        <Button variant="text" sx={buttonStyleWSC} onClick={() => props.setShowForm(false)}>Cancel</Button>
        <Button variant="contained" sx={buttonStyleWSC} onClick={() => handleClickAddRating()}>+ Add Rating</Button>
      </CardContent>
    </Card>
  )
}
