import React, { useState, useContext, useEffect }  from 'react';
import { 
    Checkbox,
    FormControlLabel, 
    FormGroup, 
  } from '@mui/material';
  import Button from '@mui/material/Button';
  import Dialog from '@mui/material/Dialog';
  import DialogActions from '@mui/material/DialogActions';
  import DialogContent from '@mui/material/DialogContent';
  import DialogTitle from '@mui/material/DialogTitle';
  import useMediaQuery from '@mui/material/useMediaQuery';
  import { useTheme } from '@mui/material/styles';
  import { TextFieldBox } from '../surveyForm.js/TextFieldBox';
import { AssetSelect } from '../surveyForm.js/connections/AssetSelect';
import { getAssetNameId } from '../../service/asset';
import StoreContext from '../../context';
import { addNewFibreTube, getFibreTube, updateFibreTube } from '../../service/connections';
import { useSnackbar } from 'notistack';

export const AddFibreTube = (props) => {
  const dialogTitle = props.dialogTitle;
  const fibreTubeId = props.fibreTubeId;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const fibreTubeBundleId = props.fibreTubeBundleId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [startAssetList, setStartAssetList] = useState([]);
  const [endAssetList, setEndAssetList] = useState([]);
  const initState = {
    "serial": "",
    "is_installed_by_zetta": false,
    "asset": "",
    "asset2": "",
    "fibreTubeBundle": fibreTubeBundleId
  };
  const [attributes, setAttributes] = useState(initState);
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const loadingAssets = async() =>{
    getAssetNameId(tokenValue, null, props.cabinetId)
    .then((response) =>
      setStartAssetList(response.data)
    )
    getAssetNameId(tokenValue, props.surveyId, null)
    .then((response) =>
      setEndAssetList(response.data)
    )
  }

  const loadingFibreTube = async () => {
    getFibreTube(tokenValue, fibreTubeId)
    .then((response) => {
      const respData = response.data.attributes;
      setAttributes(attributes =>({...attributes, serial: respData.serial}));
      setAttributes(attributes =>({...attributes, is_installed_by_zetta: respData.isInstalledByZetta})); 
      setAttributes(attributes =>({...attributes, asset: response.data.relationships.asset.data.id}));      
      setAttributes(attributes =>({...attributes, asset2: response.data.relationships.asset2.data.id}));
    })
  }

  useEffect(() => {
    fibreTubeId &&
      loadingFibreTube();
    loadingAssets();
  }, [])


  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if(fibreTubeId){
      editFibreTube();
      props.closeDialog(false);
    }
    else {
      if((attributes.asset) && (attributes.asset2)){
        addFibreTube();
        props.closeDialog(false);
      }
      else {
        enqueueSnackbar(
          "Please fill in the required fields.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    }
  }

  const setAsset = (value) => {
    setAttributes(attributes =>({...attributes, asset: value}));
  }
  const setAsset2 = (value) => {
    setAttributes(attributes =>({...attributes, asset2: value}));
  }
  // ??? tube number on figma, in model serial
  const setSerial = (value) => {
    setAttributes(attributes =>({...attributes, serial: value}));
  }
  const setInstalledByZetta = (value) => {
    setAttributes(attributes =>({...attributes, is_installed_by_zetta: value}));
  }

  const addFibreTube = async() => {
    const body = {
      "data": {
        "type": "fibreTube",
        "id": null,
        "attributes": attributes
      }
    }
    addNewFibreTube(tokenValue, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Fibre Tube.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        props.loadingFibreTubeBundle();
      }
      if(!response.data){
        enqueueSnackbar(
          "Please select Start/End Asset.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  const editFibreTube = async() => {
    const body = {
      "data": {
        "type": "fibreTube",
        "id": fibreTubeId,
        "attributes": attributes
      }
    }
    updateFibreTube(tokenValue, fibreTubeId, body)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited Fibre Tube",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        props.loadingFibreTubeBundle();
      }
      if(!response.data){
        enqueueSnackbar(
          "Please select Start/End Asset.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }
    
  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
      <>Cab id: {props.cabinetId}</>
        <AssetSelect 
          dataList={startAssetList}
          defaultValue={attributes.asset}
          label={"Start Asset"}
          setValue={setAsset}
          required={true}/>
        <AssetSelect 
          dataList={endAssetList}
          defaultValue={attributes.asset2}
          label={"End Asset"}
          setValue={setAsset2}
          required={true}/>
        <TextFieldBox 
          boxTitle={"Tube Number"}
          setValue={setSerial}
          defaultValue={attributes.serial}/>
        <FormGroup>
        <FormControlLabel 
          control={
          <Checkbox 
            checked={attributes.is_installed_by_zetta}
            onChange={(e) => setInstalledByZetta(e.target.checked)}
            />}
          label="Installed by Zetta as part of these works" 
        />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
