import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
export const DataNotSaved = (props) => {
  const fromFunction = props.fromFunction;

  const handleStayOnPage = () => {
    props.setClose(false);
  };

  const handleLeavePage = () => {
    props.setClose(false);
    if (fromFunction === "handleBack"){
      props.handleBackStep();
    }
    if (fromFunction === "handleNext"){
      props.handleNextStep();
    }
    if (fromFunction === "handleStep") {
      props.setActiveStep(props.step)
    }
    props.setIsSavedThirdStep(true);
  };

  return (
    <Dialog
    open={true}
    keepMounted
    onClose={handleStayOnPage}
  >
    <DialogTitle>{"This page contains unsaved data, are you sure you want to leave?"}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        This page contains unsaved data, are you sure you want to leave?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleLeavePage}>Leave Page</Button>
      <Button onClick={handleStayOnPage}>Stay on Page</Button>
    </DialogActions>
  </Dialog>
  )
}
