import { addFile, deleteItem, get } from "./service";

export const getBuildingDocumentsBySurveyId = async (tokenValue, surveyId)=> {
    const apiUrl = "buildingDocuments/?filter%5Bsearch%5D=" + surveyId;
    const response = await get(tokenValue, apiUrl)
    return response
  }
  
export const addNewBuildingDocument = async (tokenValue, body) => {
    const apiUrl = "buildingDocuments/";
    const response = await addFile(tokenValue, apiUrl, body)
    return response
  }

export const deleteBuildingDocument = async (tokenValue, id) => {
  const apiUrl = "buildingDocuments/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}