import React from 'react';
import { 
  Box, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  Grid, 
  Typography 
} from '@mui/material';
import { DialogModalTitle } from '../card/DialogModalTitle';

export const AssetsModal = (props) => {
  const locationSet = props.locationSet;

  const closeModal = () => {
    props.setShowModal(false);
  }
 
  return (
    <Dialog open={true}>
      <DialogModalTitle title="Assets" /> 
      <DialogContent  sx={{ minWidth: 400 }}>
        {locationSet.map((location) =>
          <Box key={location.id}>
            <Grid>            
              <Grid item>
                <Typography>{location.floor} {location.room} {location.description}</Typography>
                {location.cabinet_set.map((cabinet) => <Box key={cabinet.id}>
                  <Typography sx={{marginLeft: "10px"}}>{cabinet.make} {cabinet.model} {cabinet.description}</Typography>
                  {cabinet.rack_mounted_asset_set.map((asset) => 
                    <Typography key={asset.id} sx={{marginLeft: "20px"}}>{asset.asset_type} {asset.make} {asset.model}</Typography>
                  )}
                  </Box>
                )}
                {location.unmounted_asset_set.map((asset) => 
                  <Typography key={asset.id} sx={{marginLeft: "20px"}}>{asset.asset_type} {asset.make} {asset.model}</Typography>
                )}
                
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
