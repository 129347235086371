import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { 
  CardContent, 
  TextField 
} from '@mui/material';

export const DropDownBoxWithLabel = (props) => {
  const boxTitle = props.boxTitle;
  const items = props.dataList;
  const defaultValue = props.defaultValue;
  const disableBox = props.disableBox;
  const [selectState, setSelectState] = useState();
  
  const handleChange = (event) => {
    props.setValue(event.target.value);
    setSelectState(event.target.value);
  };
  
  return (
    <CardContent>
      <TextField
        required={props.required}
        disabled={disableBox}
        id="outlined-select"
        select
        label={boxTitle}
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={selectState || defaultValue}
        sx={{minWidth:400}}
      >
        {items?.map((d, index) => (
          <MenuItem
            key={index}
            value={d}
          >
            {d}
          </MenuItem>
        ))}
      </TextField>
    </CardContent>
  )
}

// easier to use, just send the array of attributes for that form, and the key of the attribute you want to modify
export const NewDropDownBoxWithLabel = ({
  boxTitle,
  attributes,
  setAttributes,
  attributeKey,
  dataList,
  defaultValue,
  disableBox,
  required,
}) => {
  
  const handleChange = (event) => {
    setAttributes({...attributes, [attributeKey]: event.target.value})
  };
  
  return (
    <CardContent>
      <TextField
        required={required}
        disabled={disableBox}
        id="outlined-select"
        select
        label={boxTitle}
        variant="outlined"
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
        value={attributes[attributeKey] || ""}
        sx={{minWidth:400}}
      >
        {dataList?.map((d, index) => (
          <MenuItem
            key={index}
            value={d}
          >
            {d}
          </MenuItem>
        ))}
      </TextField>
    </CardContent>
  )
}
