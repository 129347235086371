import React, {  useEffect, useState } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';

export const Ups = (props) => {
  const [sumBatteryCapacity, setSumBatteryCapacity] = useState(0);
  const [sumUPS, setSumUPS] = useState(0);


  function sumUnmountedAssetSet(upsCounts, asset) {
    if (asset.asset_type === 'UPS') {
      upsCounts.upsCount += 1;
      if (asset.ups_battery_capacity) {
        upsCounts.batteryCapacity += asset.ups_battery_capacity;
      }
    }
    return upsCounts;
  }
  
  function sumMountedAssetSet(upsCounts, cabinet) {
    // if there are rack mounted assets in this cabinet
    if (cabinet.rack_mounted_asset_set.length) {
      // array reduce the rack mounted assets
      return cabinet.rack_mounted_asset_set.reduce((total, rackMountedAsset) => {
        if (rackMountedAsset.asset_type === 'UPS') {
          upsCounts.upsCount += 1;
          if (rackMountedAsset.ups_battery_capacity) {
            upsCounts.batteryCapacity += rackMountedAsset.ups_battery_capacity;
          }
        } 
        return upsCounts;
      }, {upsCount: 0, batteryCapacity: 0})
    };
    return upsCounts;
  }
  

  function sumUpsByLocation(upsCounts, location) {
    
    let unmountedAssetUpsCounts = {upsCount: 0, batteryCapacity: 0};
    let mountedAssetUpsCounts = {upsCount: 0, batteryCapacity: 0};
    // if there are unmounted assets at this location
    if (location.unmounted_asset_set.length) {
      unmountedAssetUpsCounts = location.unmounted_asset_set.reduce(sumUnmountedAssetSet, {upsCount: 0, batteryCapacity: 0});
    }
    // if there are cabinets at this location
    if (location.cabinet_set.length) {
      mountedAssetUpsCounts = location.cabinet_set.reduce(sumMountedAssetSet, {upsCount: 0, batteryCapacity: 0});      
    }
    upsCounts.upsCount = upsCounts.upsCount + unmountedAssetUpsCounts.upsCount + mountedAssetUpsCounts.upsCount;
    upsCounts.batteryCapacity = upsCounts.batteryCapacity + unmountedAssetUpsCounts.batteryCapacity + mountedAssetUpsCounts.batteryCapacity;
    return upsCounts;
  }
  
  useEffect( () => {
    const upsCounts = props.property.lastSurvey.locationSet.reduce(sumUpsByLocation, {upsCount: 0, batteryCapacity: 0})
    setSumUPS(upsCounts.upsCount);
    setSumBatteryCapacity(upsCounts.batteryCapacity);
  }, []);

  const cardInfo =
  {
    id: "ups",
    imageSource: "/UPS.png",
    summaryNumber: sumUPS,
    title: "UPS",
    subtitle: sumBatteryCapacity + "kWh capacity",
  }
  return (
    <Grid item>
      <Card sx={props.shadowStyle}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
    )
}
