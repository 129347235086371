import { get } from "./service";

export const getAllNetworkCarrier = async (tokenValue)=> {
  const apiUrl = "nearbyNetworks/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getNearbyNetworkCarrierManholePhotosBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "nearbyNetworkCarrierManholePhoto/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getFrontPhotoBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "frontPhoto/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getNearbyNetworkById = async (tokenValue, id)=> {
  const apiUrl = "nearbyNetworks/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}