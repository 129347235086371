import React, { useState } from 'react';
import { 
  Card, 
  Grid 
} from '@mui/material';
import { EnhancedCardContent } from '../card/EnhancedCardContent';
import { CommsRoomsModal } from '../propertyCardModal/CommsRoomsModal';

export const CommsRooms = (props) => {
  const [showModal, setShowModal] = useState(false);
  const commsRoomsList = props.property.lastSurvey.locationSet?.filter(
    (location) => location.cr_is_comms_room);

  const cardInfo =
  {
    id: "comms-rooms",
    imageSource: "/Comms room.png",
    summaryNumber: props.property.lastSurvey.locationSet?.filter(
      (location) => location.cr_is_comms_room).length || 0,
    title: "Comms Rooms",
    subtitle: "",
  }
  
  // Press the button to show form
  const handleClick = () => {
      setShowModal(true);
  }
    
  return (
    <Grid item>
      {(props.property.lastSurvey.locationSet?.filter((location) => location.cr_is_comms_room).length === 0 ) ? 
      <></> :
        showModal && <CommsRoomsModal setShowModal={setShowModal} data={commsRoomsList}/>
      }
      <Card sx={props.shadowStyle} onClick={handleClick}>
        <EnhancedCardContent item={cardInfo} />
      </Card>
    </Grid>
  )
}
