import React, { Fragment, useState } from 'react';
import { 
  IconButton,  
  TableCell, 
  TableRow, 
} from '@mui/material'
import { cellStyleLocationTableBorder, cellStyleLocationTableButtons } from "../../../../style"
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ConformDialog from '../../../dialogs/ConformDelete';
import { AddEntryPoint } from '../../../dialogs/AddEntryPoint';

export const RowEntryPoint = (props) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const row = props.row;
  const entryPointId = props.entryPointId;
  const locationId = props.locationId;
  const surveyId = props.surveyId;
  const riserId = props.riserId;

  return (
    <Fragment>
      <TableRow>
          <TableCell>{row?.carrier?.name}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.entry_size}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.free_capacity}</TableCell>
          <TableCell sx={cellStyleLocationTableBorder}>{row?.is_over_7_meters_from_another_entry}</TableCell>
          <TableCell sx={cellStyleLocationTableButtons}>
            <IconButton  onClick={() => setOpenEditDialog(true)}>
              <EditIcon></EditIcon>
            </IconButton>
            <IconButton onClick={() => setOpenDeleteDialog(true)}>
              <CancelIcon></CancelIcon>
            </IconButton>
          </TableCell>
      </TableRow>
      {openDeleteDialog && 
        <ConformDialog 
          setOpenDialog={setOpenDeleteDialog} 
          title={"Entry Point"} 
          deleteFunction={() => props.deleteEntryPoint(row.id)}
        />
      }
      {openEditDialog &&
        <AddEntryPoint 
          dialogTitle={"Edit Entry Point"}
          entryPointId={entryPointId}
          closeDialog={setOpenEditDialog}
          locationId={locationId}
          surveyId={surveyId}
          loadingTable={props.loadingTable}
          riserId={riserId}
        />

      }
    </Fragment>
  )
}
