import { addFile, addItem, deleteItem, get, updateFile, updateItem } from "./service";

export const getFibreTubeBundleBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "fibreTubeBundle/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteFibreTubeBundle = async (tokenValue, id)=> {
  const apiUrl = "fibreTubeBundle/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteFibreTube = async (tokenValue, id)=> {
  const apiUrl = "fibreTube/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteFibreCore = async (tokenValue, id)=> {
  const apiUrl = "fibreCore/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const addNewFibreTubeBundle = async (tokenValue, body) => {
  const apiUrl = "fibreTubeBundlePost/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const addNewFibreTube = async (tokenValue, body) => {
  const apiUrl = "fibreTube/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const addNewFibreCore = async (tokenValue, body) => {
  const apiUrl = "fibreCore/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const getFibreCore = async (tokenValue, id)=> {
  const apiUrl = "fibreCore/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getFibreTube = async (tokenValue, id)=> {
  const apiUrl = "fibreTube/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getFibreTubeBundle = async (tokenValue, id)=> {
  const apiUrl = "fibreTubeBundlePost/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const updateFibreCore = async (tokenValue, id,  body) => {
  const apiUrl = "fibreCore/" + id + "/";
  const response = await updateFile(tokenValue, apiUrl, body)
  return response
}

export const updateFibreTubeBundle = async (tokenValue, id, body)=> {
  const apiUrl = "fibreTubeBundlePost/" + id + "/";
  const response = await updateItem(tokenValue, apiUrl, body)
  return response
}

export const updateFibreTube = async (tokenValue, id, body) => {
  const apiUrl = "fibreTube/" + id + "/";
  const response = await updateItem(tokenValue, apiUrl, body)
  return response
}
