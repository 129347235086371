import React from 'react'
import { 
  Box, 
  Typography 
} from '@mui/material';

export const BuildingDocuments = () => {

  return (
    <Box sx={{ marginTop:"40px"}}>
      <Typography variant="h5">Building Documents</Typography>
    </Box>
  )
}
