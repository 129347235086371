import React from 'react';
import {
  CardContent
} from '@mui/material';
import { CheckboxForm } from './CheckboxForm';
import { LocationSelect } from './LocationSelect';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { isOver7mFromAnotherEntryPoint } from '../../../models/enumTypeLists';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';

export const RiserFields = (props) => {
  const attributes = props.attributes;
  const locationList = props.locationList;

  const setLocation = (value) =>{
    props.setAttributes(attributes =>({...attributes, location: value}));
  }
  const setLocation2 = (value) =>{
    props.setAttributes(attributes =>({...attributes, location2: value}));
  }
  const setHasSpaceForNewCables = (value) =>{
    props.setAttributes(attributes =>({...attributes, has_space_for_new_cables: value}));
  }
  const setHasSpaceForTerminationEnclosure  = (value) =>{
    props.setAttributes(attributes =>({...attributes, has_space_for_termination_enclosure: value}));
  }
  const setHasHorizontalCableRuns = (value) =>{
    props.setAttributes(attributes =>({...attributes, has_horizontal_cable_runs: value}));
  }
  const setIsStripOutRequired = (value) =>{
    props.setAttributes(attributes =>({...attributes, is_strip_out_required: value}));
  }
  const setIsOver7MetersFromAnotherRiser = (value) =>{
    props.setAttributes(attributes =>({...attributes, is_over_7_meters_from_another_riser: value}));
  }
  const setRunsFromBasementToRoof = (value) =>{
    props.setAttributes(attributes =>({...attributes, runs_from_basement_to_roof: value}));
  }
  
  const setDescription = (value) =>{
    props.setAttributes(attributes =>({...attributes, description: value}));
  }

  return (
    <>
    <CardContent>
        <LocationSelect 
          required = {true}
          label={"Start location"}
          dataList={locationList}
          defaultValue={attributes.location}
          setValue={setLocation}/>
        <LocationSelect 
          required = {true}
          label={"End location"}
          dataList={locationList}
          defaultValue={attributes.location2}
          setValue={setLocation2}/>
      </CardContent>
        <TextFieldBox 
          boxTitle={"Description"}
          defaultValue={attributes.description}
          setValue={setDescription}
        />
        <CheckboxForm
          title="Has space for new cables" 
          defaultValue={attributes.has_space_for_new_cables}
          setValue={setHasSpaceForNewCables}/>
        <CheckboxForm
          title="Has space for termination enclosure" 
          defaultValue={attributes.has_space_for_termination_enclosure}
          setValue={setHasSpaceForTerminationEnclosure}/>
        <CheckboxForm
          title="Has horizontal cable runs" 
          defaultValue={attributes.has_horizontal_cable_runs}
          setValue={setHasHorizontalCableRuns}/>
        <CheckboxForm
          title="Strip out is required" 
          defaultValue={attributes.is_strip_out_required}
          setValue={setIsStripOutRequired}/>
        <DropDownBoxWithLabel
          boxTitle="Over 7m from another riser" 
          dataList={isOver7mFromAnotherEntryPoint}
          defaultValue={attributes.is_over_7_meters_from_another_riser}
          setValue={setIsOver7MetersFromAnotherRiser}/>
        <CheckboxForm 
          title="Runs from basement to roof" 
          defaultValue={attributes.runs_from_basement_to_roof}
          setValue={setRunsFromBasementToRoof}/>
    </>
  )
}
