import React from 'react';
import { useHistory } from "react-router-dom";
import { 
  Button,
  CircularProgress,
  TableBody,
  TableFooter, 
  TablePagination, 
  TableRow 
} from '@mui/material';
import theme from '../../theme';
import TableCell from '@mui/material/TableCell';
import { EnhancedTableHead } from '../table/EnhancedTableHead';
import { EnhancedTableAdmin } from './EnhancedTableAdmin';

export const AdminTable = (props) => {
  const history = useHistory();
  const surveyList = props.surveyList;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const isLoadingData = props.isLoadingData;

  const handleNewSurveyClick = (param, event) => {
    history.push(`/createNewSurvey`);
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  // head data if admin is login
  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Building Name",
    },
    {
      id: "surveyor",
      numeric: false,
      disablePadding: true,
      label: "Surveyor",
    },
    {
      id: "survey-date",
      numeric: false,
      disablePadding: true,
      label: "Survey Date"
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status"
    },
    {
      id: "menu-button",
      numeric: false,
      disablePadding: true,
      label: ""
    },

  ];

  return (
    <>
    <EnhancedTableHead headCells={headCells}></EnhancedTableHead>
    {isLoadingData ?
      <TableBody><TableRow><TableCell /><TableCell align="right" ><CircularProgress /></TableCell></TableRow></TableBody> :
      <>
        <EnhancedTableAdmin 
          rows={surveyList} 
          page={page} 
          rowsPerPage={rowsPerPage} 
          removeSurvey={props.removeSurvey} 
          changeStatusSurvey={props.changeStatusSurvey} 
        />
        <TableFooter>
          <TableRow>
            <TableCell>
              <Button variant="text" sx ={{color: theme.palette.black.main}} onClick={handleNewSurveyClick}>+Add new Survey</Button>
            </TableCell>
            <TablePagination
              sx ={{
                color: theme.palette.primary.main
              }}
              rowsPerPageOptions={[10]}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </>
    }
    </>
  )
}
