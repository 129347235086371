import React, {Fragment} from 'react';
import {
  Box, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Typography
} from '@mui/material';
import { cellStyleLocationSubtable, locationSubtableTableTitle, primarySubtableHeader } from '../../../style';
import { RowCabinets } from './cabinets/RowCabinets';


export const RowWithCabinets = (props) => {
  const cabinetList = props.cabinetList;
  const locationId = props.locationId;
  const riserId = props.riserId;
  
  return (
    <Box>
      <Typography variant="h6"  style={locationSubtableTableTitle}>Cabinets</Typography>
      <TableContainer component={Paper}>
        <Table >
          <TableHead sx={primarySubtableHeader}>
            <TableRow sx={{ borderRadius: "10px"}}>
              <TableCell sx={cellStyleLocationSubtable} />
              <TableCell align="left" sx={cellStyleLocationSubtable}>Description</TableCell>
              <TableCell align="left" sx={cellStyleLocationSubtable}>Owner</TableCell>            
              <TableCell align="left" sx={cellStyleLocationSubtable}>Height</TableCell>           
              <TableCell align="left" sx={cellStyleLocationSubtable}>Width</TableCell>
              <TableCell />
            </TableRow>
        </TableHead>
        <TableBody>
          {cabinetList.map((cabinet) => {
            return cabinet.is_deleted === true ?
            <Fragment 
              key={cabinet.id}/>
            :
            <RowCabinets 
              key={cabinet.id}
              row={cabinet}
              deleteCabinet={props.deleteCabinet}
              deleteAsset={props.deleteAsset}
              locationId={locationId}
              riserId={riserId}
              cabinetId={cabinet.id}
              loadingTable={props.loadingTable}/>
          }
          )}
        </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
