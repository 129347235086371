import React, { useState } from 'react';
import {
  TableCell, 
} from '@material-ui/core';
import theme from '../../theme';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { PhotoDialog } from '../photoDialogs/PhotoDialog';

export const SubtableDropdownLocations = (props) => {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);

  const handleClickPhoto = () => {
    setShowPhoto(true);
  }

  // style
  const styleTable={
    backgroundColor: "rgba(33, 33, 33, 0.08)",
    fontSize: "16px",
    color: theme.palette.title.main
  }
  const styleMainCell ={
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.black.main,
    letterSpacing: "0.5px",
    '& > *': { borderBottom: 'unset' }
  }
  const styleCell ={
    backgroundColor: "rgba(33, 33, 33, 0.08)",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    color: theme.palette.black.main,
  }
  const styleDataImg ={
    whiteSpace: 'normal',
    wordWrap: 'break-word', 
    width: "50px",
    align: "right",
    padding:"1px 0px !important",
    ':hover': {
      cursor: "pointer",
    }
  }


  return (
    <React.Fragment>
      <TableRow sx={styleMainCell}>
        { row.asset === null ? 
          <TableCell component="th" scope="row"></TableCell> :
          <TableCell component="th" scope="row">{row.asset}</TableCell>
        }
        { row.asset2 === null ? 
          <TableCell component="th" scope="row"></TableCell> :
          <TableCell component="th" scope="row">{row.asset2}</TableCell>
        }
        <TableCell>{row.fibre_core_set.length}</TableCell>        
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow sx={styleCell}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={styleTable}>
                    <TableCell>Core</TableCell>
                    <TableCell>Start Port</TableCell>
                    <TableCell>End Port</TableCell>
                    <TableCell>Loss(dB)</TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.fibre_core_set.map((data) =>{
                    return data.is_deleted === false ?
                    <TableRow key={data.id} sx={styleCell}>
                      {showPhoto && <PhotoDialog 
                      setShowState={setShowPhoto} 
                      title={"Photo"} photoSet={[{
                          "photo": data.photo,
                          "description" : ""
                      }]}/>
                      }
                      <TableCell>{data.number}</TableCell>
                      <TableCell>{data.start_port}</TableCell>
                      <TableCell>{data.end_port}</TableCell>
                      <TableCell>{data.loss}</TableCell>
                      <TableCell>
                        <PhotoCameraIcon sx={styleDataImg} onClick={handleClickPhoto} />
                      </TableCell>
                    </TableRow> :
                    <TableRow key={data.id}></TableRow>
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}
