import React, {useState} from 'react';
import { 
  Table, 
  Typography,
  TableContainer,
  Paper, 
  TableBody,
} from '@mui/material';
import { Header } from './Header';
import { onClickAddLocationTable } from '../../../../style';
import { TableRows } from './TableRows';
import { Upload } from '../Upload';

export const PhotoTables = (props) => {
  const addRowText = props.addRowText;
  const [openDialog, setOpenDialog] = useState(false);
  
  const photosList = props.photosList;
  const tagsList = props.tagsList;
  const setTagsList = props.setTagsList;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <caption> 
            <Typography 
              variant="caption" 
              sx={onClickAddLocationTable} onClick={() => setOpenDialog(true)}
              >{addRowText}
            </Typography>
          </caption>
        <Header />
        <TableBody>
          {photosList.map((photo) =>
            <TableRows 
              key={photo.id}
              photo={photo}
              removePhoto={props.removePhoto}
              tagsList={tagsList}
              setTagsList={setTagsList}
              selectedTagsList={props.selectedTagsList}
              setSelectedTagsList={props.setSelectedTagsList}
              editPhoto={props.editPhoto}
              createNewPhotoTag={props.createNewPhotoTag}
            />
          )}
        </TableBody>
      </Table>
      {openDialog && 
        <Upload 
          dialogTitle="Exterior photos"
          closeDialog={setOpenDialog}
          selectedPhotosList={props.selectedPhotosList}
          setSelectedPhotosList={props.setSelectedPhotosList}
          uploadPhotos={props.uploadPhotos}
          tagsList={tagsList}
          setTagsList={setTagsList}
          selectedTagsList={props.selectedTagsList}
          setSelectedTagsList={props.setSelectedTagsList}
          createNewPhotoTag={props.createNewPhotoTag}
          />
      }
    </TableContainer>
  )
}
