import React, {useEffect, useState} from 'react';
import { 
  Box, 
  CardContent, 
  Checkbox, 
  Chip, 
  FormControlLabel, 
  FormGroup, 
  ListItem, 
  Typography,
  TextField,
  Button,
} 
from '@mui/material';
import { ownerType, status } from '../../../models/enumTypeLists';
import { MakeModel } from '../asset/MakeModel';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { ConnectedSwitchSelect } from './ConnectedSwitchSelect';
import { RiserFloor } from '../riser/RiserFloor';
  

export const WirelessAccessPoint = (props) => {
  const attributes = props.attributes;
  const error = props.error;
  const riserId = props.riserId;
  const [screenShot, setScreenShot] = useState(null);
  
  useEffect(() => {
    props.loadingAssetType();
  }, [])
    
  const setMake = (value) => {
    props.setAttributes(attributes =>({...attributes, make: value}));
  }
  const setModel = (value) => {
    props.setAttributes(attributes =>({...attributes, model: value}));
  }
  const setMacAddress = (value) => {
    const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
    if(regex.test(value)){ 
      props.setError(false)
      props.setAttributes(attributes =>({...attributes, netMacAddress: value})); 
    }
    else if(value === ""){ 
      props.setError(false) 
      props.setAttributes(attributes =>({...attributes, netMacAddress: null}));
    }
    else {
      props.setError(true)
      props.setAttributes(attributes =>({...attributes, netMacAddress: value}));
    }
  }
  const setSerial = (value) => {
    props.setAttributes(attributes =>({...attributes, serial: value}));
  }
  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setOwner = (value) => {
    props.setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQRCode = (value) => {
    props.setAttributes(attributes =>({...attributes, qr_code: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, is_installed_by_zetta: value}));
  }
  const setConnectedSwitch = (value) => {
    props.setAttributes(attributes =>({...attributes, ap_connected_switch: value}));
  }
  const setTestSpeed = (value) => {
    props.setAttributes(attributes =>({...attributes, ap_speed_test_speed: value}));
  } 
  const handleScreenShot = (value) => {
    setScreenShot(value);
    props.setAttributes(attributes =>({...attributes, ap_speed_test_screenshot: value}));
  }
  const handleDeletePhoto = () => {
    props.setAttributes(attributes =>({...attributes, ap_speed_test_screenshot: ""}));
    setScreenShot(null);
  } 

  return (
    <>
      {riserId && 
        <CardContent>
          <RiserFloor 
            attributes={attributes}
            setAttributes={props.setAttributes}
          />
        </CardContent>
      }
      <MakeModel 
          setMake={setMake}
          setModel={setModel}
          attributes={attributes}
        />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'inline' }}>
          <TextField 
            error={error}
            value={attributes.net_mac_address}
            helperText={error ? 'Example: 12-34-56-78-9A-BC' : ''}
            id="outlined-mac-address" 
            label={"Mac Address"} 
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            style = {{width: 200, marginLeft: "20px"}} 
            onChange={(e) => setMacAddress(e.target.value)}
          />
          <TextField 
            id="outlined-basic-floors-lifts" 
            label={"Serial Number"} 
            value={attributes.serial}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            style = {{width: 200, marginLeft: "26px"}} 
            onChange={(e) => setSerial(e.target.value)}
          />
        </Box>
      </CardContent>
      <CardContent>
        <ConnectedSwitchSelect 
          label={"Connected Switch"} 
          defaultValue={attributes.ap_connected_switch} 
          setValue={setConnectedSwitch}
          />
        <TextFieldBox 
          boxTitle={"Test Speed (Mbps)"}
          setValue={setTestSpeed}
          defaultValue={attributes.ap_speed_test_speed}
        />
        <CardContent>
          <Typography>Speed Test ScreenShot</Typography>
          {screenShot === null & attributes.ap_speed_test_screenshot === "" ?
            <></>
          :
            <ListItem>
              <Chip
                label={screenShot?.name || attributes.ap_speed_test_screenshot.substring(attributes.ap_speed_test_screenshot.lastIndexOf('/')+1)}
                onDelete={handleDeletePhoto}
              />
            </ListItem>
          }
          
          <Button variant="outlined" component="label" sx={{marginTop: "20px"}}>
            Select file 
            <input  id="file-board-photo" type="file" hidden onChange={(e) => handleScreenShot(e.target.files[0])}/>          
          </Button>
        </CardContent>
        <DropDownBoxWithLabel 
          boxTitle={"Status"} 
          dataList={status}
          defaultValue={attributes.status} 
          setValue={setStatus}/>
        <DropDownBoxWithLabel 
          boxTitle={"Owner"} 
          dataList={ownerType}
          defaultValue={attributes.owner}
          setValue={setOwner} />
        <TextFieldBox 
          boxTitle={"QR Code"}
          setValue={setQRCode}
          defaultValue={attributes.qr_code}/>
        <FormGroup>
          <FormControlLabel 
            control={
            <Checkbox 
              checked={attributes.is_installed_by_zetta}
              onChange={(e) => setInstalledByZetta(e.target.checked)}
            />} 
            label="Installed by Zetta as part of these works" />
        </FormGroup>
      </CardContent>
    </>
  )
}