import React, { useState, useContext,useEffect } from 'react';
import { 
  CardContent, 
  Checkbox, 
  Chip, 
  FormControlLabel, 
  FormGroup, 
  ListItem, 
  Typography 
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { NumberFieldBox } from '../surveyForm.js/NumberFieldBox';
import StoreContext from '../../context';
import { addNewFibreCore, getFibreCore, updateFibreCore } from '../../service/connections';
import { useSnackbar } from 'notistack';

export const AddFibreCore = (props) => {
  const dialogTitle = props.dialogTitle;
  const fibreCoreId = props.fibreCoreId;
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const fibreTubeId = props.fibreTubeId;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const initState ={
    "number": "",
    "start_port": "",
    "end_port": "",
    "loss": "",
    "photo": "",
    "fibre_tube": fibreTubeId,
    "is_installed_by_zetta": false
  };
  const [attributes, setAttributes] = useState(initState);
  const [lossPhoto, setLossPhoto] = useState(null);
  const [error, setError] = useState(false);
  // for errors
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let icon;

  const loadingFibreCore = async () => {
    getFibreCore(tokenValue, fibreCoreId)
    .then((response) => {
      const respData = response.data.attributes;
      setLossPhoto(respData.photo)
      setAttributes(attributes =>({...attributes, number: respData.number}));
      setAttributes(attributes =>({...attributes, start_port: respData.startPort}));
      setAttributes(attributes =>({...attributes, end_port: respData.endPort}));
      setAttributes(attributes =>({...attributes, loss: respData.loss}));
      setAttributes(attributes =>({...attributes, is_installed_by_zetta: respData?.isInstalledByZetta}));
    })
  }

  useEffect(() => {
    fibreCoreId &&
      loadingFibreCore();
  }, [])
  
  const handleClose = () => {
    props.closeDialog(false);
  };

  const handleSave = () => {
    if(fibreCoreId){
      editFibreCore();
      props.closeDialog(false);
    }
    else{
      if((attributes.number) && (attributes.start_port)){
        addFibreCore();
        props.closeDialog(false);
      }
      else {
        enqueueSnackbar(
          "Please fill in the required fields.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    }
  }

  const handleDeletePhoto = () => {    
    setAttributes(attributes =>({...attributes, photo: " "}));
    setLossPhoto(null);
  }
  
  const setCoreNumber = (value) => {
    setAttributes(attributes =>({...attributes, number: value}));
  }
  const setStartPort = (value) => {
    setAttributes(attributes =>({...attributes, start_port: value}));
  }
  const setEndPort = (value) => {
    setAttributes(attributes =>({...attributes, end_port: value}));
  }
  const setLoss = (value) => {
    setAttributes(attributes =>({...attributes, loss: value}));
  }
  const handleLossPhoto = (value) => {
    setLossPhoto(value);
    setAttributes(attributes =>({...attributes, photo: value}));
  }
  const setInstalledByZetta = (value) => {
    setAttributes(attributes =>({...attributes, is_installed_by_zetta: value}));
  }



  const addFibreCore = async() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    addNewFibreCore(tokenValue, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully added new Fibre Core.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        props.loadingFibreTubeBundle()
      }
      if(!response.data){
        enqueueSnackbar(
          response?.errors[0].detail,
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  const editFibreCore = async() => {
    const formData = new FormData();
    for (const [key, value] of Object.entries(attributes)) {
      formData.append(key, value);
    }
    formData.append("id", fibreCoreId);
    updateFibreCore(tokenValue, fibreCoreId, formData)
    .then((response) => {
      if(response.data){
        enqueueSnackbar(
          "Successfully edited Fibre Core.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
         );
        props.loadingFibreTubeBundle()
      }
      if(!response.data){
        enqueueSnackbar(
          "Please select the image.",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={true}
      onClose={handleClose}
      aria-labelledby="upload-dialog-title-ep"
    >
      <DialogTitle 
        id="upload-dialog-title-ep" 
        variant="h6" 
        sx={{color: theme.palette.primary.main}}
      >
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <NumberFieldBox 
          boxTitle={"Number or Colour"}
          setValue={setCoreNumber}
          defaultValue={attributes.number}
          setError={setError}
          required={true}
          />
        <NumberFieldBox 
          boxTitle={"Start Port"}
          setValue={setStartPort}
          defaultValue={attributes.start_port}
          setError={setError}
          required={true}
          />
        <NumberFieldBox 
          boxTitle={"End Port"}
          setValue={setEndPort}
          defaultValue={attributes.end_port}
          setError={setError}
          />
        <NumberFieldBox   
          boxTitle={"Loss (dB)"}
          setValue={setLoss}
          defaultValue={attributes.loss}
          setError={setError}
        />
        <CardContent>
          <Typography>Loss Photo</Typography>
          {lossPhoto === null ?
            <></>
          :
            <ListItem>
              <Chip
                icon={icon}
                label={lossPhoto?.name || lossPhoto.substring(lossPhoto.lastIndexOf('/')+1)}
                onDelete={handleDeletePhoto}
              />
            </ListItem>
          }
          
          <Button variant="outlined" component="label" sx={{marginTop: "20px"}}>
            Select file 
            <input  id="file-board-photo" type="file" hidden onChange={(e) => handleLossPhoto(e.target.files[0])}/>          
          </Button>
        </CardContent>
        <FormGroup>
        <FormControlLabel 
          control={
          <Checkbox 
            checked={attributes.is_installed_by_zetta}
            onChange={(e) => setInstalledByZetta(e.target.checked)}
          />} 
            label="Installed by Zetta as part of these works"
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button autoFocus variant="contained" onClick={handleSave}>
          + SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
