import { addItem, deleteItem, get, updateItem, updateItemPatch, actionItemPatch } from "./service";

export const deleteSurvey = async (tokenValue, id)=> {
  const apiUrl = "survey/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}
  
export const getSurveyById = async (tokenValue, surveyId)=> {
    const apiUrl = "buildingSurvey/" + surveyId + "/";
    const response = await get(tokenValue, apiUrl)
    return response
}

export const getSurveyReportById = async (tokenValue, surveyId)=> {
    const apiUrl = "surveyReport/" + surveyId + "/";
    const response = await get(tokenValue, apiUrl)
    return response
}

export const getSurveyByPropertyId = async (tokenValue, propertyId) => {
  const apiUrl = "survey/?filter%5Bsearch%5D=" + propertyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const addNewSurvey = async (tokenValue, body) => {
  const apiUrl = "buildingSurvey/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const updateStatusSurvey = async (tokenValue, id)=> {
  const apiUrl = "survey/" + id + "/lock/";
  const response = await actionItemPatch(tokenValue, apiUrl)
  return response
}

export const updateSurveyFirstStep = async (tokenValue, id, body)=> {
  const apiUrl = "survey/" + id + "/";
  const response = await updateItemPatch(tokenValue, apiUrl, body)
  return response
}

export const getIdDuplicateSurvey = async (tokenValue, id)=> {
  const apiUrl = "survey/" + id + "/clone_survey/";
  const response = await updateItemPatch(tokenValue, apiUrl)
  return response
}

export const getSurveyByPropertyIdWithoutPagination = async (tokenValue, propertyId) => {
  const apiUrl = "surveyWithoutPagination/?filter%5Bsearch%5D=" + propertyId;
  const response = await get(tokenValue, apiUrl)
  return response
}
