import React, {useState, useEffect, useContext} from 'react';
import {
  FormControl,
  TextField
} from "@mui/material";
import { getAllNetworkCarrier } from '../../../service/networkCarrier';
import StoreContext from '../../../context';
import { getNearbyNetworksBySurveyId } from '../../../service/siteDetails';

import MenuItem from '@mui/material/MenuItem';
import theme from '../../../theme';


export const NearbyNetworkSelect = (props) => {
  const { authStore } = useContext(StoreContext);
  const tokenValue = authStore.apiToken;
  const surveyId = props.surveyId;
  const [allNearbyNetworks, setAllNearbyNetworks] = useState([]);
  const [selectedNetworksList, setSelectedNetworkList] = useState([]);
  const [rendered, setRendered] = useState(false);
  
  const handleChange = (event) => {
    if (selectedNetworksList.some(item => item.id === event.target.value[event.target.value.length - 1].id))
    {
      props.setEnableSaveButton(true)
      return
    }
    else{
      props.setValue(event.target.value)
      setSelectedNetworkList(event.target.value);
      props.setEnableSaveButton(true)
    }
  };  
  
  const removeFromList = (value) => {
    setSelectedNetworkList(selectedNetworksList.filter(item => item.id !== value.id));
    props.setValue(selectedNetworksList.filter(item => item.id !== value.id))
  }

  const loadingNearbyNetworks = async () => {
    getAllNetworkCarrier(tokenValue)
    .then((response) =>
      setAllNearbyNetworks(response?.data)
    )
  }

  const loadingNearbyNetworksBySurveyId = async () => {
    getNearbyNetworksBySurveyId(tokenValue, surveyId)
    .then((response) => {
      setSelectedNetworkList(response?.data)
      setRendered(true)
    })
  }

  useEffect(() => {
    loadingNearbyNetworksBySurveyId();
    loadingNearbyNetworks();
  }, [])

  return (
    rendered &&
      <div>
        <FormControl sx={{ m: 2, width: 400, mt: 3 }}>
          <TextField
            select
            id="nearby-networks-select-multiple"
            variant="outlined"
            label="Nearby Networks"
            InputLabelProps={{
              shrink: true
            }}
            SelectProps={{
              MenuProps: {
                sx: { maxHeight: '50%',
                      marginLeft: "5px" }
              },
              id: "select-multiple",
              defaultValue: true,
              multiple: true,
              value: selectedNetworksList,
              onChange: handleChange,
              renderValue: () => selectedNetworksList.map((nn) => nn.attributes.name + ", ")
            }}
          >
            {allNearbyNetworks.map((d) => {
              if (selectedNetworksList.some(item => item.id === d.id))
              {
                return (<MenuItem
                  key={d.id}
                  value={d}
                  divider={true}
                  onClick={() => removeFromList(d)}
                  style={{
                    backgroundColor: theme.palette.selected.main,
                  }}
                >
                  {d.attributes.name}
                </MenuItem>)
              }
              else 
              {
                return (<MenuItem
                  key={d.id}
                  value={d}
                >
                  {d.attributes.name}
                </MenuItem>)
              }
            })}
          </TextField>
        </FormControl>
      </div>
  );
}
