import React from 'react';
import {  
  TableHead, 
  TableRow 
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { headCellStyleTable, headCellStyleTableWidth, headStyleTable } from '../../../style';

export const HeadLocationTable = () => {
  
  return (
    <TableHead sx={headStyleTable}>
        <TableRow>
          <TableCell/>
          <TableCell align="left" sx={headCellStyleTable}>Floor</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>Room</TableCell>
          <TableCell align="left" sx={headCellStyleTable}>Description</TableCell>
          <TableCell sx={headCellStyleTableWidth}/>
        </TableRow>
    </TableHead>
  )
}
