import React, {useEffect} from 'react';
import { Box, CardContent, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { TextFieldBox } from '../../surveyForm.js/TextFieldBox';
import { MakeModel } from './MakeModel';
import { PositionInCabSizePorts } from './PositionInCabSizePorts';
import { DropDownBoxWithLabel } from '../../surveyForm.js/DropDownBoxWithLabel';
import { ownerType, status } from '../../../models/enumTypeLists';

export const FirewallFields = (props) => {
  const error = props.error;
  const attributes = props.attributes;

  useEffect(() => { 
    props.loadingAssetType();
  }, [])
  
  const setMake = (value) => {
    props.setAttributes(attributes =>({...attributes, make: value}));
  }
  const setModel = (value) => {
    props.setAttributes(attributes =>({...attributes, model: value}));
  }
  const setPositionInCabinet = (value) => {
    props.setAttributes(attributes =>({...attributes, rackPositionInCabinet: value}));
  }
  const setSize = (value) => {
    props.setAttributes(attributes =>({...attributes, rackSize: value}));
  }
  const setPorts = (value) => {
    props.setAttributes(attributes =>({...attributes, netPorts: value}));
  }
  const setMacAddress = (value) => {
    const regex = /^([0-9A-F]{2}[:-]){5}([0-9A-F]{2})$/;
    if(regex.test(value)){ 
      props.setError(false)
      props.setAttributes(attributes =>({...attributes, netMacAddress: value})); 
    }
    else if(value === ""){ 
      props.setError(false) 
      props.setAttributes(attributes =>({...attributes, netMacAddress: null}));
    }
    else {
      props.setError(true)
      props.setAttributes(attributes =>({...attributes, netMacAddress: value}));
    }
  }
  const setSerial = (value) => {
    props.setAttributes(attributes =>({...attributes, serial: value}));
  }
  const setStatus = (value) => {
    props.setAttributes(attributes =>({...attributes, status: value}));
  }
  const setOwner = (value) => {
    props.setAttributes(attributes =>({...attributes, owner: value}));
  }
  const setQRCode = (value) => {
    props.setAttributes(attributes =>({...attributes, qrCode: value}));
  }
  const setInstalledByZetta = (value) => {
    props.setAttributes(attributes =>({...attributes, isInstalledByZetta: value}));
  }

  return (
    <>
      <MakeModel 
        setMake={setMake}
        setModel={setModel}
        attributes={attributes}
      />
      <PositionInCabSizePorts
        setPositionInCabinet={setPositionInCabinet}
        setSize={setSize}
        setPorts={setPorts}
        attributes={attributes}
      />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'inline' }}>
          <TextField 
            error={error}
            value={attributes.netMacAddress}
            helperText={error ? 'Example: 12-34-56-78-9A-BC' : ''}
            id="outlined-mac-address" 
            label={"Mac Address"} 
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            style = {{width: 200, marginLeft: "20px"}} 
            onChange={(e) => setMacAddress(e.target.value)}
          />
          <TextField 
            id="outlined-basic-floors-lifts" 
            label={"Serial Number"} 
            value={attributes.serial}
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            style = {{width: 200, marginLeft: "26px"}} 
            onChange={(e) => setSerial(e.target.value)}
          />
        </Box>
      </CardContent>
      <CardContent>
      <DropDownBoxWithLabel 
          boxTitle={"Status"} 
          dataList={status}
          defaultValue={attributes.status}
          setValue={setStatus} />
      <DropDownBoxWithLabel 
          boxTitle={"Owner"} 
          dataList={ownerType}
          defaultValue={attributes.owner}
          setValue={setOwner}/>
      <TextFieldBox 
        boxTitle={"QR Code"}
        setValue={setQRCode}
        defaultValue={attributes.qrCode} />
      <CardContent>
        <FormGroup>
          <FormControlLabel 
            control={
            <Checkbox 
              checked={attributes.isInstalledByZetta}
              onChange={(e) => setInstalledByZetta(e.target.checked)}
            />
            } 
            label="Installed by Zetta as part of these works" 
          />
        </FormGroup>
      </CardContent>
      </CardContent>
    </>
  )
}
