export default {
  backend: "http://0.0.0.0:8000",
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "http://localhost:8000",
  photoBaseUrl:
    process.env.REACT_APP_PHOTO_BASE_URL ?? "http://localhost:8000/media",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN ?? null,
  },
};
