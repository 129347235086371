import React from 'react';
import { Typography } from '@mui/material';


export const CardTitle = (props) => {
    const cardTitle = props.cardTitle;
  return (
    <Typography
      variant="h6"
      display="block"
      align="center">
      {cardTitle}
    </Typography>
  )
}
