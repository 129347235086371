import { addFile, addFileReturnStatus, addItem, deleteItem, deletePhoto, get, updateFile, updateFileReturnStatus, updateItem } from "./service";

export const getLocationsBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "location/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getLocationsNameIdBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "locationNameId/?filter%5Bsearch%5D=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getLocationsNameId = async (tokenValue, id="")=> {
  const apiUrl = "locationNameId/" + id;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getCabinetsNameIdBySurveyId = async (tokenValue, surveyId)=> {
  const apiUrl = "cabinetNameId/?survey=" + surveyId;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getAssetNameId = async (tokenValue, surveyId, cabinetId)=> {
  var apiUrl = "asset/";
  if (surveyId) {
    apiUrl = apiUrl + "?survey=" + surveyId
  } else if (cabinetId) {
    apiUrl = apiUrl + "?cabinet=" + cabinetId
  }
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteLocation = async(tokenValue, id) => {
  const apiUrl = "location/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteCabinet = async(tokenValue, id) => {
  const apiUrl = "cabinet/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const deleteAsset = async(tokenValue, id) => {
  const apiUrl = "asset/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const addNewAsset = async (tokenValue, body) => {
  const apiUrl = "nonMountedAsset/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const addNewLocation = async (tokenValue, body) => {
  const apiUrl = "location/";
  const response = await addFileReturnStatus(tokenValue, apiUrl, body)
  return response
}

export const addNewCabinet = async (tokenValue, body) => {
  const apiUrl = "cabinet/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const addNewEntryPoint = async (tokenValue, body) => {
  const apiUrl = "entryPoint/";
  const response = await addItem(tokenValue, apiUrl, body)
  return response
}

export const deleteEntryPoint = async(tokenValue, id) => {
  const apiUrl = "entryPoint/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const getLocation = async (tokenValue, locationId)=> {
  const apiUrl = "location/" + locationId + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const updateLocation = async (tokenValue, locationId, body)=> {
  const apiUrl = "location/" + locationId + "/";
  const response = await updateFileReturnStatus(tokenValue, apiUrl, body)
  return response
}

export const getEntryPoint = async (tokenValue, id)=> {
  const apiUrl = "entryPoint/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getCabinet = async (tokenValue, id)=> {
  const apiUrl = "cabinet/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const updateCabinet = async (tokenValue, id, body)=> {
  const apiUrl = "cabinet/" + id + "/";
  const response = await updateItem(tokenValue, apiUrl, body)
  return response
}

export const getAsset = async (tokenValue, id)=> {
  const apiUrl = "nonMountedAsset/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const updateAsset = async (tokenValue, locationId, body)=> {
  const apiUrl = "nonMountedAsset/" + locationId + "/";
  const response = await updateItem(tokenValue, apiUrl, body)
  return response
}

export const getNetworkSwitch  = async (tokenValue)=> {
  const apiUrl = "networkSwitch/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const getUnmountedAsset = async (tokenValue, id) => {
  const apiUrl = "unMountedAsset/" + id + "/";
  const response = await get(tokenValue, apiUrl)
  return response
}

export const addNewUnmountedAsset = async (tokenValue, body) => {
  const apiUrl = "unMountedAsset/";
  const response = await addFile(tokenValue, apiUrl, body)
  return response
}

export const updateEntryPoint = async (tokenValue, id, body)=> {
  const apiUrl = "entryPoint/" + id + "/";
  const response = await updateItem(tokenValue, apiUrl, body)
  return response
}

export const getAssetTypeByName = async (tokenValue, name) => {
  const apiUrl = "assetType/?filter%5Bsearch%5D=" + name;
  const response = await get(tokenValue, apiUrl)
  return response
}

export const deleteUnmountedAsset = async(tokenValue, id) => {
  const apiUrl = "unMountedAsset/" + id + "/";
  const response = await deleteItem(tokenValue, apiUrl)
  return response
}

export const updateUnmountedAsset = async (tokenValue, id, body) => {
  const apiUrl = "unMountedAsset/" + id + "/";
  const response = await updateFile(tokenValue, apiUrl, body)
  return response
}

export const deleteAssetPhoto = async(tokenValue, id) => {
  const apiUrl = "assetPhoto/" + id + "/";
  const response = await deletePhoto(tokenValue, apiUrl)
  return response
}
