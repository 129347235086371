import React, { useEffect } from 'react';
import { 
  Box, 
} from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { PhotoTables } from './tableWithPhotos/PhotoTables';


export const DialogWithTable = (props) => {
  const dialogTitle = props.dialogTitle;
  const photoList = props.photoList;
  const addRowText = props.addRowText;
  const tagsList = props.tagsList;
  const selectedPhotosList = props.selectedPhotosList;
  
  const handleClose = () => {
    props.closeDialog(false);
  };
  
  useEffect(() => {
    props.getPhotoList();
    props.getTagsList();
  }, [])


  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={true}
      onClose={handleClose}
      aria-labelledby="show-table-with-photos"
    >
      <DialogTitle id="upload-photo-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <Box sx={{padding: "38px"}}>
        <PhotoTables 
          addRowText={addRowText}
          photosList={photoList}
          removePhoto={props.removePhoto}
          selectedPhotosList={selectedPhotosList}
          setSelectedPhotosList={props.setSelectedPhotosList}
          uploadPhotos={props.uploadPhotos}
          tagsList={tagsList}
          setTagsList={props.setTagsList}
          selectedTagsList={props.selectedTagsList}
          setSelectedTagsList={props.setSelectedTagsList}
          editPhoto={props.editPhoto}
          createNewPhotoTag={props.createNewPhotoTag}
          />
      </Box>
      <DialogActions>
        <Button autoFocus variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
