import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { BuildingInformation } from '../surveyForm.js/building/BuildingInformation';
import StoreContext from '../../context';
import { getAllPropertyNameId } from '../../service/property';
import { Box } from '@mui/material';
import theme from '../../theme';
import { addNewSurvey, getSurveyById, updateSurveyFirstStep } from '../../service/survey';
import BuildingAlreadyExist from '../buildingAlreadyExist/BuildingAlreadyExist';
import { useSnackbar } from 'notistack';

export const FirstStep = forwardRef((props, ref) => {
  const { authStore } = useContext(StoreContext);  
  const { surveyStore } = useContext(StoreContext);
  const [buildingsNameId, setBuildingsNameId] = useState([]);
  const surveyId = props.surveyId;
  const tokenValue = authStore.apiToken;
  // value form forms
  const [defaultPropertyValue, setDefaultPropertyValue] = useState(null);
  const [propertyId, setPropertyId] = useState(null);
  const [surveyDate, setSurveyDate] = useState(new Date());
  const [desktopSurveyComplete, setDesktopSurveyComplete] = useState("");
  const [surveyType, setSurveyType] = useState("Initial");
  // when we select building and if building already exist then open dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [surveyList, setSurveyList] = useState([]);
  // for handling error
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // disable fields
  const [disableBox, setDisableBox] = useState(true);
  
  const desktopSurveyCompleteToBool = () => {
    if (desktopSurveyComplete === "Yes") {
      return true
    }
    if (desktopSurveyComplete === "No") {
      return false
    }
    else {
      return null
    }
  }

  useImperativeHandle(ref, () =>  ({
    saveDataFunction() {
      if (surveyId) {
        props.setIsSaved(true)
        updateSurvey();
      }
      else{
        addSurvey();
        props.setEnableSaveButton(false);
      }
    }
  }));

  const updateSurvey = () => {
    const body = {
      "data": {
        "type": "survey",
        "id": surveyId,
            "attributes": {
              "type": surveyType,
              "surveyedAt": surveyDate,
              "isDesktopSurveyComplete": desktopSurveyCompleteToBool(),
          }
        }
    }
    updateSurveyFirstStep(tokenValue, surveyId, body)
    .then((response) => {
      props.setEnableSaveButton(false);
      if (response.data) {
        enqueueSnackbar(
            "Successfully updated Survey.",
            {
              variant: "success",
              autoHideDuration: 3000,
              anchorOrigin: { vertical: "top", horizontal: "center" },
            }
        );
      }
    })
  }
  
  
  const addSurvey = () => {
    const body ={
      "data": {
          "type": "survey",
          "id": null,
          "attributes": {
            "status": "draft",
            "type": surveyType,
            "surveyedAt": surveyDate,
            "isDesktopSurveyComplete": desktopSurveyCompleteToBool(),
            "property" :  propertyId
        }
      }
    }
    addNewSurvey(tokenValue, body)
    .then((response) => {
      if(response.data){
        props.setSurveyId(response.data.id)
        surveyStore.getOne('survey/' + response.data.id)
        surveyStore.reset()
        props.setIsSaved(true);
        enqueueSnackbar(
          "Successfully created Survey.",
          {
            variant: "success",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        );
      }
      if(!response.data){
        enqueueSnackbar(
          "Please select property!",
          {
            variant: "error",
            autoHideDuration: 3000,
            anchorOrigin: { vertical: "top", horizontal: "center" },
          }
        ); 
      }
    })
    .catch(() => {
      enqueueSnackbar(
        "There was an error contacting the server. Please try again later.",
        {
          variant: "error",
          autoHideDuration: 3000,
          anchorOrigin: { vertical: "top", horizontal: "center" },
        }
      );
    });
  }

  const loadingBuildings = async() =>{
    const response = await getAllPropertyNameId(tokenValue);
    setBuildingsNameId(response.data);
  }

  const getSurvey = async() => {
    setDisableBox(false)
    const response = await getSurveyById(tokenValue, surveyId);
    setSurveyDate(response.data.attributes.surveyedAt);
    setSurveyType(response.data.attributes.type);
    setPropertyId(response.data.relationships.property.data.id);
    if (response.data.attributes.isDesktopSurveyComplete === true){
      setDesktopSurveyComplete("Yes")
    }
    if (response.data.attributes.isDesktopSurveyComplete === false){
      setDesktopSurveyComplete("No")
    }
    if (response.data.attributes.isDesktopSurveyComplete === null){
      setDesktopSurveyComplete("Unknown")
    }
  }

  useEffect(() => {
    const dataFormat = surveyDate.getFullYear()
      + '-' +  ((surveyDate.getMonth() > 8) ? (surveyDate.getMonth() + 1) : ('0' + (surveyDate.getMonth() + 1))) 
      + '-' + ((surveyDate.getDate() > 9) ? surveyDate.getDate() : ('0' + surveyDate.getDate()))
      
    setSurveyDate(dataFormat)
    loadingBuildings();
    if (surveyId) {
      getSurvey();
      props.setIsSaved(true);
    }
    props.setShowSaveButton(true);
    
  }, [])

  //style
  const cardStyle = {
    border: "none",
    boxShadow: "none", 
    minWidth: 704, 
    maxWidth: 704,
    background: theme.palette.neutral.main
  }

  return (
    <Box align="center" sx={{ marginTop:"40px", marginLeft: "250px"  }} style={cardStyle}>
      <BuildingInformation 
        dataList={buildingsNameId} 
        defaultPropertyValue={defaultPropertyValue} 
        defaultSurveyDate={surveyDate}
        defaultSurveyComplete={desktopSurveyComplete}
        defaultSurveyType={surveyType}
        setSurveyType={setSurveyType}
        disableBox={disableBox}
        setDisableBox={setDisableBox}
        buildingAlreadyExistDialog={setOpenDialog}
        setDate={setSurveyDate}
        setSurveyList={setSurveyList}
        propertyId={propertyId}
        setPropertyId={setPropertyId}
        surveyId={surveyId}
        setDesktopSurveyComplete={setDesktopSurveyComplete}
        setEnableSaveButton={props.setEnableSaveButton}
        />
      {openDialog && 
        <BuildingAlreadyExist 
          surveyList={surveyList}
          setOpenDialog={setOpenDialog} 
          setDisableBox={setDisableBox}
          setPropertyId={setPropertyId}/>
      }
    </Box>
  )
})
